import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { AssetType, RequestDetailType } from '@/models/guest-app/asset'
import { Controller, useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { InputImage } from '@/components/molecules/settings/input-image'
import { createAssetDetail, updateAssetDetail } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { TextareaField } from '@/components/molecules/textarea-field'
import { CategoryType, SalesDepartmentType, SalesHourType, SalesSubjectType, TagType } from '@/models/mobile-request'
import { Select, Option } from '@/components/atoms/select'
import { InputSalesSubject } from '@/components/molecules/settings/asset/space/input-sales-subject'

type Props = {
  asset: AssetType & {
    categories: CategoryType[]
    tags: TagType[]
    salesHours: SalesHourType[]
  }
  detail: RequestDetailType | null
  onCancel: () => void
  onSave: () => void
}

export type AssetProductDetailFormValue = {
  name: string
  description: string
  imagePath: string
  price: number
  stock: number
  categoryId: number
  tagIds: Array<number>
  salesHourId: number | undefined
  salesDepartmentId: string | undefined
  salesSubjectMasterId: string | undefined
}

export const RequestProductDetailModal: React.FC<Props> = ({ asset, detail, onCancel, onSave: parentSave }) => {
  const { t, i18n } = useTranslation()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<AssetProductDetailFormValue>({
    defaultValues: {
      name: detail?.name || '',
      description: detail?.description || '',
      imagePath: detail?.imagePath || '',
      price: detail?.price || undefined,
      stock: detail?.stock || undefined,
      categoryId: detail?.category?.id || undefined,
      tagIds: detail?.tags?.map(tag => tag.id) || [],
      salesHourId: detail?.salesHourId || undefined,
      salesDepartmentId: detail?.salesSubject?.salesDepartmentId || '',
      salesSubjectMasterId: detail?.salesSubject?.salesSubjectMasterId
    },
  })
  const { doReload, setIsLoading } = useContext(AssetDetailContext)
  const { errorHandler } = useErrorHandler()
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)

  const onSave = async (value: AssetProductDetailFormValue) => {
    if (!value.salesDepartmentId) {
      if (!window.confirm(t('Department ID and course ID are not registered Customer must account for it'))) {
        return
      }
    }
    try {
      setIsLoading(true)
      if (detail) {
        await updateAssetDetail(asset.id, detail.id, asset.formatType, value)
      } else {
        await createAssetDetail(asset.id, asset.formatType, value)
      }
      doReload()
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const computedIsInclude = (
    objects: CategoryType[] | SalesDepartmentType[] | SalesSubjectType[],
    id: string | number | undefined,
  ): boolean => {
    return objects.findIndex((o: CategoryType | SalesDepartmentType | SalesSubjectType) => o.id === id) !== -1
  }

  return (
    <>
      <Modal customCss={modalStyle} style={{ visibility: isShowCropModal ? 'hidden' : 'visible' }}>
        <ModalHeader>{t('GuestAppSetting.CreateRequest')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <div className="left-panel">
              <div>
                <div css={inputTitleTextStyle}>
                  {t('Title')}
                  <div css={requireLabelTextStyle}>※</div>
                </div>
                <div css={[textareaStyle, { textarea: { height: 76 } }]}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: t('Required field has not been entered') }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <TextareaField
                        marginBottom={0}
                        placeholder={t('Title name')}
                        value={value}
                        name={name}
                        handleChangeData={onChange}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
              </div>

              <div css={inputAreaStyle}>
                <div style={{ paddingRight: 8, width: '50%' }}>
                  <div css={inputTitleTextStyle}>
                    {t('Category')}
                    <div css={requireLabelTextStyle}>※</div>
                  </div>
                  <Controller
                    name="categoryId"
                    control={control}
                    rules={{ required: t('Please select {{item}}', { item: t('Category') }) }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Select placeholder={t('Please select')} value={value} onChange={onChange} error={error?.message}>
                        {!computedIsInclude(asset.categories, value) && (
                          <Option disabled value={value}>
                            {detail?.category?.name[i18n.language]}
                          </Option>
                        )}
                        {asset.categories.map(category => (
                          <Option key={category.id} value={category.id}>
                            {category.name[i18n.language]}
                          </Option>
                        ))}
                      </Select>
                    )}
                  />
                </div>
                <div style={{ paddingLeft: 8, width: '50%' }}>
                  <div css={inputTitleTextStyle}>{t('Tag')}</div>
                  <Controller
                    name="tagIds"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select mode="multiple" showSearch={false} placeholder={t('Please select')} value={value} onChange={onChange}>
                        {asset.tags.map(tag => (
                          <Option key={tag.id} value={tag.id}>
                            {tag.name[i18n.language]}
                          </Option>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>

              <Controller
                name="imagePath"
                control={control}
                rules={{ required: t('Please select {{item}}', { item: t('Image') }) }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputImage
                    customStyle={inputAreaStyle}
                    value={value}
                    onChange={onChange}
                    onShowModal={setIsShowCropModal}
                    aspect={16 / 9}
                    error={error?.message}
                  />
                )}
              />
            </div>

            <div className="right-panel">
              <div>
                <div css={inputTitleTextStyle}>{t('Content')}</div>
                <div css={[textareaStyle, { textarea: { height: 99 } }]}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <TextareaField marginBottom={0} placeholder={t('Content')} value={value} name={name} handleChangeData={onChange} />
                    )}
                  />
                </div>
              </div>

              <div css={inputAreaStyle}>
                <div style={{ paddingRight: 8, width: '50%' }}>
                  <div css={inputTitleTextStyle}>
                    {t('Amount (tax included)')}
                    <div css={requireLabelTextStyle}>※</div>
                  </div>
                  <Controller
                    name="price"
                    control={control}
                    rules={{ required: t('Required field has not been entered') }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <InputField
                        marginBottom={0}
                        placeholder="00000"
                        type="number"
                        value={value}
                        name={name}
                        handleChangeData={onChange}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
                <div style={{ paddingLeft: 8, width: '50%' }}>
                  <div css={inputTitleTextStyle}>{t('Stock')}</div>
                  <Controller
                    name="stock"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <InputField
                        marginBottom={0}
                        placeholder="00000"
                        type="number"
                        value={value}
                        name={name}
                        handleChangeData={onChange}
                      />
                    )}
                  />
                </div>
              </div>

              <div css={inputAreaStyle}>
                <div style={{ paddingRight: 8, width: '50%' }}>
                  <div css={inputTitleTextStyle}>{t('MobileRequest.Sales hours')}</div>
                  <Controller
                    name="salesHourId"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select placeholder={t('Please select')} value={value} onChange={onChange}>
                        <Option value={''}>{t('Dont choose')}</Option>
                        {asset.salesHours.map(salesHour => (
                          <Option key={salesHour.id} value={salesHour.id}>
                            {salesHour.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </div>

              <InputSalesSubject control={control} detail={detail} setValue={setValue} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = {
  height: 622,
  width: 1200,
  minWidth: 1200,
  left: 'calc((100% - 1200px) / 2)',
}

const modalBodyStyle = css({
  padding: 32,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  minHeight: '100%',
  display: 'flex',
  '.input-col': {
    display: 'flex',
    gap: 22,
    '> div': { width: '100%', marginBottom: 24 },
  },
  '.search-box': {
    marginBottom: 24,
    position: 'relative',
    '.suffix-icon': {
      position: 'absolute',
      right: 16,
      // height + icon height(1/2)
      top: 'calc(24px + 8px)',
    },
  },
  '.left-panel': {
    width: '50%',
    borderRight: '1px solid #F2F2F2',
    paddingRight: 35.5,
  },
  '.right-panel': {
    width: '50%',
    paddingLeft: 30.5,
    display: 'flex',
    flexFlow: 'column',
  },
  textarea: {
    height: '100%',
  },
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  paddingTop: 24,
  display: 'flex',
})

const textareaStyle = css({
  textarea: {
    marginBottom: 0,
    padding: '16px',
    lineHeight: 1.5,
    letterSpacing: '1.4px',
    ':focus': {
      padding: '15px',
    },
  },
})
