import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Control, Controller, FieldValues, UseFormSetValue, useWatch } from 'react-hook-form'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import { SalesDepartmentMasterType, SalesSubjectType } from '@/models/sales-manager/sales'
import { fetchSalesDepartment, fetchSalesSubject } from '@/apis/aipass'
import { RequestDetailType, SpaceDetailType } from '@/models/guest-app/asset'
import { Cascader } from '@/components/atoms/cascader'
import { groupBy } from 'lodash'

interface FormValue extends FieldValues {
  salesDepartmentId: string | undefined
  salesSubjectMasterId: string | undefined
}
type Props<T extends FormValue> = {
  control: T extends FormValue ? Control<T, any> : never
  detail: SpaceDetailType | RequestDetailType | null
  setValue: T extends FormValue ? UseFormSetValue<T> : never
}

export const InputSalesSubject = <T extends FormValue>({ control, setValue, detail }: Props<T>) => {
  const { t } = useTranslation()
  const watchSalesDepartmentId = useWatch({ control, name: 'salesDepartmentId' })
  const [salesDepartmentMasters, setSalesDepartmentMasters] = useState<SalesDepartmentMasterType[]>([])
  const [salesSubjectMasters, setSalesSubjectMasters] = useState<SalesSubjectType[]>([])
  const [isFetchedMaster, setIsFetchedMaster] = useState(false)

  const initializeComponent = async (opt?: { noIndicator: boolean }) => {
    await Promise.all([
      fetchSalesDepartment().then(res => {
        const departments = (res || []).filter(r => r.relatedSubjectMasterCount)
        const existsMasterOfDetail = departments.find(
          master =>
            master.departmentCode === detail?.salesSubject?.salesDepartmentCode &&
            master.departmentName === detail?.salesSubject?.salesDepartmentName,
        )
        const isNeedReplaceId = existsMasterOfDetail && existsMasterOfDetail?.id !== detail?.salesSubject?.salesDepartmentId
        if (isNeedReplaceId) {
          setValue('salesDepartmentId', existsMasterOfDetail.id)
        }

        if (detail?.salesSubject && !existsMasterOfDetail) {
          departments.push({
            id: detail.salesSubject.salesDepartmentId,
            departmentCode: detail.salesSubject.salesDepartmentCode,
            departmentName: detail.salesSubject.salesDepartmentName,
            relatedSubjectMasterCount: 1,
          })
        }
        setSalesDepartmentMasters(departments)
      }),
      fetchSalesSubject().then(res => {
        const salesSubjects = res?.salesSubjects || []

        const existsMasterOfDetail = salesSubjects.find(
          subjectMaster =>
            subjectMaster.subjectCode === detail?.salesSubject?.salesSubjectMasterCode &&
            subjectMaster.subjectName === detail?.salesSubject?.salesSubjectMasterName &&
            subjectMaster.subSubjectCode === detail?.salesSubject?.salesSubSubjectCode &&
            subjectMaster.subSubjectName === detail?.salesSubject?.salesSubSubjectName,
        )

        const isNeedReplaceId = existsMasterOfDetail && existsMasterOfDetail?.id !== detail?.salesSubject?.salesSubjectMasterId
        if (isNeedReplaceId) {
          setValue('salesSubjectMasterId', existsMasterOfDetail.id)
        }

        if (detail?.salesSubject && !existsMasterOfDetail) {
          salesSubjects.push({
            id: detail?.salesSubject?.salesSubjectMasterId,
            departmentId: detail?.salesSubject?.salesDepartmentId,
            departmentCode: detail?.salesSubject?.salesDepartmentCode,
            departmentName: detail?.salesSubject?.salesDepartmentName,
            subjectCode: detail?.salesSubject?.salesSubjectMasterCode,
            subjectName: detail?.salesSubject?.salesSubjectMasterName,
            subSubjectCode: detail?.salesSubject?.salesSubSubjectCode,
            subSubjectName: detail?.salesSubject?.salesSubSubjectName,
          })
        }
        setSalesSubjectMasters(salesSubjects)
      }),
    ])
    setIsFetchedMaster(true)
  }

  const findParentSubject = (anySubjectMasterId: string) => {
    const anySubjectMaster = salesSubjectMasters.find(master => master.id === anySubjectMasterId)
    if (!anySubjectMaster) {
      return undefined
    }
    const subjectMasterGroups = salesSubjectMasters.filter(
      master =>
        master.departmentCode === anySubjectMaster.departmentCode &&
        master.subjectCode === anySubjectMaster?.subjectCode &&
        master.subjectName === anySubjectMaster?.subjectName,
    )
    const defaultSubject = subjectMasterGroups.find(master => !(master.subSubjectCode && master.subSubjectCode !== '-'))
    return defaultSubject || subjectMasterGroups[0]
  }

  const valueToCascader = (value: string | undefined) => {
    if (!value) {
      return undefined
    }
    const master = findParentSubject(value)
    return [master?.id || value, value]
  }

  useEffect(() => {
    initializeComponent()
  }, [])

  const cascadeSubjectMasterOptions = useMemo(() => {
    const subSubjectGroups = groupBy(
      salesSubjectMasters.filter(subject => subject.departmentId === watchSalesDepartmentId),
      subjectMaster => findParentSubject(subjectMaster.id)?.id,
    )
    const options = Object.keys(subSubjectGroups).map(groupKey => {
      const defaultSubjectMaster = findParentSubject(groupKey) as SalesSubjectType
      const children = subSubjectGroups[groupKey]
        .filter(subjectMaster => subjectMaster.subSubjectCode && subjectMaster.subSubjectCode !== '-')
        .map(subjectMaster => ({
          value: subjectMaster.id,
          label: `${subjectMaster.subSubjectCode}/${subjectMaster.subSubjectName}`,
        }))
      return {
        value: defaultSubjectMaster.id,
        label: `${defaultSubjectMaster.subjectCode}/${defaultSubjectMaster.subjectName}`,
        children: children.length > 0 ? children : undefined,
      }
    })
    return options
  }, [watchSalesDepartmentId, salesSubjectMasters])

  return (
    <div css={inputAreaStyle}>
      <div style={{ paddingRight: 8, width: '50%' }}>
        <div css={inputTitleTextStyle}>
          {t('Department ID')}/{t('Department name')}
        </div>
        <Controller
          name="salesDepartmentId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              value={isFetchedMaster ? value : ''}
              onChange={e => {
                setValue('salesSubjectMasterId', '')
                onChange(e)
              }}
              customStyle={css({ paddingBottom: 24 })}
            >
              <AntSelect.Option value="">{t('Dont choose')}</AntSelect.Option>
              {salesDepartmentMasters.map(salesDepartment => (
                <AntSelect.Option key={salesDepartment.id} value={salesDepartment.id}>
                  {salesDepartment.departmentCode}/{salesDepartment.departmentName}
                </AntSelect.Option>
              ))}
            </Select>
          )}
        />
      </div>
      {!!watchSalesDepartmentId && (
        <div style={{ paddingLeft: 8, width: '50%' }}>
          <div css={inputTitleTextStyle}>
            {t('Course ID')}/{t('Subject name')}
            <div css={requireLabelTextStyle}>※</div>
          </div>
          <Controller
            name="salesSubjectMasterId"
            control={control}
            rules={{ required: t('Please select subjects') }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Cascader
                placeholder={t('Please select')}
                options={cascadeSubjectMasterOptions}
                onChange={value => {
                  onChange(value[1] || value[0])
                }}
                error={error?.message}
                customStyle={css({ paddingBottom: 24 })}
                value={isFetchedMaster ? valueToCascader(value) : []}
                displayRender={label => (label.length > 1 ? `${label[0]} (${label[1]})` : label)}
                popupPlacement="bottomRight"
              />
            )}
          />
        </div>
      )}
    </div>
  )
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  display: 'flex',
})
