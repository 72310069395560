import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { RoomSp } from './room'
import { CleaningManagerContext } from '../room'
import { RoomType } from '@/constants/cleaning-manager'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

interface CleaningListSpProps {
  rooms: object
  selectedDate: dayjs.Dayjs
  staffId: string
  tabIndex: string
}

export const CleaningListSp = ({ rooms, selectedDate, staffId, tabIndex }: CleaningListSpProps) => {
  const [cleaningStaffId, setCleaningStaffId] = useState(staffId)
  const [isSorting, setIsSorting] = useState(true)

  const { t } = useTranslation()

  const { staffList } = useContext(CleaningManagerContext)

  const history = useHistory()

  const sortRoomsByCheckinAtSafe = (rooms: RoomType[]): RoomType[] => {
    setIsSorting(true)
    const todayDate = dayjs(selectedDate)
    const result = rooms.sort((a, b) => {
      const dateA = a.nextReservation?.roomCheckinAt ? dayjs(a.nextReservation.roomCheckinAt) : null
      const dateB = b.nextReservation?.roomCheckinAt ? dayjs(b.nextReservation.roomCheckinAt) : null

      const isDateASameDay = dateA?.isValid() && dateA.isSame(todayDate, 'day')
      const isDateBSameDay = dateB?.isValid() && dateB.isSame(todayDate, 'day')

      // 当日データの優先順位付け
      if (isDateASameDay && !isDateBSameDay) return -1 // Aが当日ならAを前に
      if (!isDateASameDay && isDateBSameDay) return 1 // Bが当日ならBを前に

      if (isDateASameDay && isDateBSameDay) {
        return dateA!.diff(dateB!)
      }

      return 0
    })
    setIsSorting(false)

    return result
  }

  const onChangeStaff = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const changedStaffId = e.target.value
    setCleaningStaffId(changedStaffId)

    e.preventDefault()
    history.push(`/cleaning/?tabIndex=${tabIndex}&dateOf=${selectedDate.format('YYYY-MM-DD')}&staffId=${changedStaffId ?? ''}`)
  }

  const sortedRoomList = useMemo(() => {
    const roomList = Object.values(rooms)
      .flat()
      .filter(room => !cleaningStaffId || room.cleaningStaffId === cleaningStaffId)
    return sortRoomsByCheckinAtSafe(roomList)
  }, [rooms, cleaningStaffId])

  return (
    <div css={guestRoomBlockStyle}>
      <select css={selectStyle} onChange={e => onChangeStaff(e)} value={cleaningStaffId}>
        <option value="">{t('cleaningManager.CleaningStaff')}</option>
        {staffList?.map(staff => (
          <option key={staff.id} value={staff.id}>
            {staff.name}
          </option>
        ))}
      </select>

      {!isSorting && sortedRoomList.map(room => <RoomSp key={room.guestRoomId} room={room} cleaningStaffId={cleaningStaffId} />)}
    </div>
  )
}

const guestRoomBlockStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: 24,
  overflowY: 'auto',
})

const selectStyle = css({
  width: '100%',
  appearance: 'none',
  padding: '12px 24px',
  marginBottom: 24,
  backgroundImage: `url(${require('@/static/images/arrow_down_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '90% center',
  border: '1px solid #CCCCCC',
  borderRadius: 5,
})
