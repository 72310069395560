import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

// api
import { fetchCheckinSalesSplitDetailPdf } from '@/apis/aipass'

// components
import { Button } from '@/components/atoms/button'
import { RadioGroupField } from '@/components/molecules/radio-group-field'

//libs
import { b64DecodeUnicode } from '@/libs/pdf/pdf-download'
import { useErrorHandler } from '@/hooks/use-error-handler'
type SplitReceiptPopupProps = {
  isSplitReceiptModalOpen: boolean
  onClose: () => void
  checkinId?: any
  reservationId?: string
  customer: any
  usageDetails: any[] | undefined
  discounts: any[] | undefined
}

export const SplitReceiptPopup: React.FC<SplitReceiptPopupProps> = ({
  onClose,
  checkinId,
  reservationId,
  customer,
  usageDetails = [],
  discounts = [],
}) => {
  const animationData = require('@/static/images/json/loading.json')
  const customerName = customer?.guest?.name
  const customerCompany = customer?.guest?.company
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [list, setList] = useState<any[]>([...usageDetails, ...discounts])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [receipts, setReceipts] = useState<{ guestName: string; provision: string; showPresets: boolean; sales: any[] }[]>([])
  const [outputLanguage, setOutputLanguage] = useState('ja')

  const addReceipt = () => {
    if (receipts.length >= 5) return
    setReceipts([...receipts, { guestName: customerName, provision: 'ご宿泊代として', showPresets: false, sales: [] }])
  }

  const removeReceipt = index => {
    receipts.splice(index, 1)
    setReceipts([...receipts])
  }

  const setGuestName = (index, value) => {
    receipts[index].guestName = value
    setReceipts([...receipts])
  }

  const setProvision = (index, value) => {
    receipts[index].provision = value
    setReceipts([...receipts])
  }

  const onSaveData = async () => {
    try {
      if (receipts.some(r => r.sales.length === 0)) {
        return alert(t('There are receipts that have not been selected for issuance.'))
      }

      setDisabled(true)
      setIsLoading(true)

      const res = await fetchCheckinSalesSplitDetailPdf({
        checkinId,
        reservationId,
        isEnOutput: outputLanguage === 'en' ? 1 : 0,
        splitReceipt: [
          ...receipts.map(receipt => ({
            provision: receipt.provision,
            guestName: receipt.guestName || customerName,
            salesId: receipt.sales.map(item => item.id),
          })),
        ],
      })

      if (res.data) {
        const url = window.URL.createObjectURL(res.data)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', res.fileName ? b64DecodeUnicode(res.fileName) : 'sales') //or any other extension
        document.body.appendChild(link)
        link.click()
        setIsLoading(false)
        onClose()
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      setDisabled(false)
      setIsLoading(false)
    }
  }

  const onChangeNumber = (item: any, value) => {
    for (const receipt of receipts) {
      receipt.sales = receipt.sales.filter(i => i.id !== item.id)
    }

    if (value !== 'hidden') {
      receipts[value].sales.push(item)
    }

    setReceipts([...receipts])
  }

  const getSumByReceipt = (receipt): number => {
    return receipt.sales.reduce((sum, item) => sum + (item?.amountUsed || 0), 0)
  }

  const clickPeceiptDropDown = receipt => {
    receipt.showPresets = !receipt.showPresets
    setReceipts([...receipts])
  }

  const onClickPreset = (index, value) => {
    setGuestName(index, value)
    receipts[index].showPresets = false
  }

  const getNumberValue = item => {
    const index = receipts.findIndex(receipt => receipt.sales.find(i => item.id === i.id))

    return index !== -1 ? index : 'hidden'
  }

  useEffect(() => {
    if (receipts.some(r => r.sales.length)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [receipts])

  useEffect(() => {
    const list = [...usageDetails, ...discounts].map(usage => {
      const quantity = usage.isDiscount ? 1 : usage.quantity
      return { ...usage, amountUsed: usage.salesSubjectPrice * (usage.isDiscount && usage.salesSubjectPrice ? -1 : 1) * quantity }
    })
    list.sort((a, b) => new Date(a.salesDate).getTime() - new Date(b.salesDate).getTime())

    setList(list)
    setReceipts([
      {
        guestName: customerName,
        provision: 'ご宿泊代として',
        showPresets: false,
        sales: list.filter(i => !i.isPaid && !(i.isDiscount && i.salesPaymentName && i.salesPaymentName !== '-')),
      },
    ])
  }, [usageDetails, discounts])

  return (
    <div css={modalWrapperStyle}>
      <div css={modalContentStyle}>
        <div css={modalTitleWrapperStyle}>
          <p css={modalTitleStyle}>{t('split issue')}</p>
          <RadioGroupField
            itemCss={radioItemStyle}
            value={outputLanguage}
            style={{ labelMargin: 12 }}
            items={[
              { value: 'ja', label: t('Japanese language') },
              { value: 'en', label: 'English' },
            ]}
            onChange={e => {
              setOutputLanguage(e.target.value)
            }}
          />
        </div>
        <div css={mainContentStyle}>
          <div css={{ padding: 24, width: 263, minWidth: 263, overflowY: 'auto' }}>
            {receipts.map((receipt, index) => {
              return (
                <div
                  key={index}
                  css={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    border: '1px solid #ccc',
                    color: '#272727',
                    fontSize: 12,
                    marginTop: index ? 16 : 0,
                  }}
                >
                  <div
                    css={{
                      padding: '12px 16px',
                      borderBottom: '1px solid #ccc',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontWeight: 'bold',
                      color: '#676767',
                    }}
                  >
                    <div>
                      {t('Receipt')} | {t('Number')} {index + 1}
                    </div>
                    {index > 0 && (
                      <button
                        css={{ background: 'initial', border: 'none', cursor: 'pointer', margin: '-9px -16px' }}
                        type="button"
                        onClick={() => removeReceipt(index)}
                      >
                        <img src={require('@/static/images/delete_yellow.svg')} />
                      </button>
                    )}
                  </div>

                  <div css={{ padding: '12px 16px' }}>
                    <div css={{ marginBottom: 16 }}>
                      <div css={{ marginBottom: 8, fontSize: 10 }}>{t('Your name')}</div>
                      <div css={{ position: 'relative' }}>
                        <div css={{ position: 'relative', border: '1px solid #CCCCCC', borderRadius: 16, overflow: 'hidden' }}>
                          <input
                            name="text"
                            type="text"
                            css={[nameInputStyle, { border: 'none' }]}
                            value={receipt.guestName}
                            onChange={e => setGuestName(index, e.target.value)}
                            placeholder="山田 太郎"
                          />
                          {customerCompany && (
                            <div
                              onClick={() => clickPeceiptDropDown(receipt)}
                              css={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                bottom: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderLeft: '1px solid #ccc',
                                width: 32,
                                backgroundColor: '#eee',
                              }}
                            >
                              <img src={require('@/static/images/arrow_yellow.svg')} />
                            </div>
                          )}
                        </div>
                        {receipt.showPresets && (
                          <div
                            css={{
                              position: 'absolute',
                              right: 0,
                              left: 0,
                              top: '32px',
                              backgroundColor: '#fff',
                              borderRadius: 5,
                              boxShadow: '0px 3px 10px #0000003d',
                              padding: '8px 0',
                              marginTop: 8,
                            }}
                          >
                            <div onClick={() => onClickPreset(index, customerName)} css={{ padding: '8px 16px', cursor: 'pointer' }}>
                              {customerName}
                            </div>
                            <div onClick={() => onClickPreset(index, customerCompany)} css={{ padding: '8px 16px', cursor: 'pointer' }}>
                              {customerCompany}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div css={{ marginBottom: 8, fontSize: 10 }}>{t('Proviso')}</div>
                      <input
                        name="text"
                        type="text"
                        css={provisionInputStyle}
                        value={receipt.provision}
                        onChange={e => setProvision(index, e.target.value)}
                        placeholder={t('As accommodation fee')}
                      />
                    </div>
                  </div>
                  <div
                    css={{
                      padding: '12px 16px',
                      borderTop: '1px solid #ccc',
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: '#272727',
                      fontSize: 12,
                    }}
                  >
                    <div>{t('Amount of money')}</div>
                    <div css={{ fontSize: 14, fontWeight: 'bold' }}>{getSumByReceipt(receipt).toLocaleString()}</div>
                  </div>
                </div>
              )
            })}
            {receipts.length < 5 && (
              <button
                css={{
                  padding: '12px 16px',
                  background: '#fff',
                  border: '1px dashed #ccc',
                  marginTop: 16,
                  width: '100%',
                  color: '#f2a40b',
                  fontSize: 12,
                  cursor: 'pointer',
                }}
                onClick={addReceipt}
              >
                <img
                  src={require('@/static/images/plus.svg')}
                  css={{ width: 13, height: 13, marginRight: 8, cursor: 'pointer' }}
                  alt={t('Add icon yellow')}
                />
                {t('Add receipt')}
              </button>
            )}
          </div>
          <div css={{ borderLeft: '1px solid #ccc', padding: 24, overflowY: 'auto' }}>
            <div css={tableContainer}>
              <table>
                <thead>
                  <tr>
                    <th>{t('Number')}</th>
                    <th>{t('Target reservation')}</th>
                    <th>{t('Date of use')}</th>
                    <th>{t('Reference')}</th>
                    <th>{t('Amount used')}</th>
                    <th>{t('payment')}</th>
                    <th>{t('Deposit')}</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map(item => (
                    <tr className={getNumberValue(item) !== 'hidden' ? 'highlight-row' : ''} key={item.id}>
                      <td>
                        <select
                          name="checkout_time"
                          css={selectBoxStyle}
                          defaultValue={'hidden'}
                          value={getNumberValue(item)}
                          onChange={e => onChangeNumber(item, e.target.value)}
                        >
                          <option value={'hidden'}>{t('Hidden')}</option>
                          {receipts.map((receipt, index) => (
                            <option key={index} value={index}>
                              {index + 1}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        {item.accommodationId || '-'}/{item.roomNumber || '-'}
                      </td>
                      <td>{dayjs(item.salesDate).format(t('MM-DD'))}</td>
                      <td>{item.isDiscount ? item.salesDiscountName : item.salesSubjectName || item.salesPaymentName}</td>
                      <td>{item.amountUsed?.toLocaleString()}</td>
                      <td>{item.salesPaymentName}</td>
                      <td className={!item.isDiscount && !item.isPaid ? 'orange-text' : ''}>
                        {t(item.isDiscount ? '-' : item.isPaid ? 'Paid' : 'Unpaid')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div css={buttomButtonStyle}>
            <Button width={110} height={38} buttonType={3} fontSize={14} marginRight={16} fontWeight={'normal'} onClick={() => onClose()}>
              {t('Cancel')}
            </Button>
            <Button
              width={126}
              fontWeight={'normal'}
              height={38}
              buttonType={disabled ? 5 : 1}
              fontSize={14}
              isDisabled={disabled}
              onClick={() => onSaveData()}
            >
              {t('Download')}
            </Button>
          </div>
        </div>
        {isLoading && (
          <div
            css={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(255, 255, 255, 0.8)',
            }}
          >
            <Lottie height="80px" width="80px" options={{ animationData: animationData, autoplay: true, loop: true }} />
          </div>
        )}
      </div>
    </div>
  )
}

// Modal
const modalWrapperStyle = css({
  width: '100vw',
  height: '100vh',
  background: 'rgba(39, 39, 39, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 103,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'scroll',
})
const mainContentStyle = css({
  marginTop: 60,
  height: 'calc(100vh - 240px)',
  background: '#F2F2F2 0% 0% no-repeat padding-box',
  display: 'flex',
  overflowY: 'auto',
})

const inputStyle = css({
  height: 32,
  padding: '8px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  textAlign: 'left',
  appearance: 'none',
  width: '100%',
  '::placeholder': {
    color: '#ccc',
  },
})

const nameInputStyle = css(inputStyle, {})

const provisionInputStyle = css(inputStyle, {})

const modalContentStyle = css({
  background: '#fff',
  borderRadius: 5,
  position: 'relative',
  overflow: 'hidden',
})

const modalTitleWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingInline: 32,
  position: 'absolute',
  'z-index': '10000',
  backgroundColor: 'white',
  borderRadius: '5px 5px 0 0',
})

const modalTitleStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: '60px',
  letterSpacing: '0.9px',
})

const buttomButtonStyle = css({
  display: 'flex',
  padding: '11px 32px',
  justifyContent: 'flex-end',
  backgroundColor: '#fff',
  borderRadius: '0px 0px 5px 5px',
})

const tableContainer = css({
  width: 874,
  border: '1px solid #ccc',
  borderRadius: 12,
  overflow: 'hidden',
  minHeight: '100%',
  backgroundColor: '#fff',
  table: {
    width: '100%',
    th: {
      color: '#676767',
      padding: '8px 16px',
      background: '#f5f5f5',
      borderBottom: '1px solid #ccc',
      fontSize: 12,
    },
    td: {
      color: '#272727',
      padding: '10px 16px',
      background: '#f5f5f5',
      borderBottom: '1px solid #F2F2F2',
      fontSize: 14,
      '&.orange-text': {
        color: '#f2a40b',
      },
    },
    'tr.highlight-row': {
      td: {
        background: '#fff',
      },
    },
  },
})

const selectBoxStyle = css({
  width: 82,
  height: 28,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/arrow_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  display: 'block',
  paddingLeft: 14,
  paddingRight: 5,
  marginRight: 10,
  backgroundPosition: '90% center',
  fontSize: 12,
})

const radioItemStyle = css({
  paddingLeft: 12,
  fontSize: 12,
  label: {
    color: '#676767',
  },
  img: {
    width: 18,
    height: 18,
  },
})
