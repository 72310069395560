import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import dayjs, { Dayjs } from 'dayjs'

// components
import { Button } from '@/components/atoms'
import { salesMainContainerStyle } from '@/components/pages/sales-manager/sales/_detail/sales'
import { PosCalculateSuccessModal } from '@/components/organisms/accommodation-management/pos-calculate-success-modal'
import { LoadingFull } from '@/components/molecules/loading-full'

import {
  fetchDiscount,
  fetchReservationDiscount,
  fetchSales,
  fetchSalesReservation,
  fetchSmaregiTerminals,
  fetchSmaregiTransactionDetail,
  postSmaregiTransactionsLayaways,
} from '@/apis/aipass'
import { TerminalType } from '@/models/smaregi/terminal'
import { SalesDiscountType, SalesType } from '@/models/sales'
import { TransactionSaleType, TransactionSideType, TransactionType } from '@/models/smaregi/transaction'

type PosCalculateModalProps = {
  reservationId?: string
  checkinId?: string
  transaction?: TransactionType
  onClose: (isUpdated: boolean) => void
}

type SaleItemType = {
  id: string
  isSelected: boolean
  accommodationId: string | undefined
  date: Dayjs
  subject: string
  subSubject: string | undefined
  quantity: number
  price: number
  transactionSide: TransactionSideType | undefined
}

export const PosCalculateModal: React.FC<PosCalculateModalProps> = ({ reservationId, checkinId, transaction, onClose }) => {
  const { t } = useTranslation()
  const [isDisabledSend, setIsDisabledSend] = useState<boolean>(true)
  const [terminalOptions, setTerminalOptions] = useState<Array<{ value: number; label: ReactNode; data: TerminalType }>>([])
  const [saleItemList, setSaleItemList] = useState<SaleItemType[]>([])
  const [sumSelectSalePrice, setSumSelectSalePrice] = useState<number>(0)
  const [selectTerminal, setSelectTerminal] = useState<TerminalType>()
  const [isShowSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const sendPosCalculate = () => {
    const selectedSaleIds = saleItemList.filter(item => item.isSelected).map(item => item.id)
    if (!selectTerminal) {
      return
    }

    setIsLoading(true)
    postSmaregiTransactionsLayaways({
      contractId: selectTerminal.contractId,
      storeId: selectTerminal.storeId,
      terminalId: selectTerminal.terminalId,
      saleIds: selectedSaleIds,
    })
      .then(() => {
        setShowSuccessModal(true)
      })
      .catch(() => {
        alert('Server error')
      })
      .finally(() => setIsLoading(false))
  }

  const checkedSaleItemList = (index: number) => {
    setSaleItemList(
      saleItemList.map((item, i) => {
        if (i !== index) {
          return item
        }
        item.isSelected = !item.isSelected
        return item
      }),
    )
  }

  const changedTerminal = (terminalIndex: number) => {
    setSelectTerminal(terminalOptions[terminalIndex]?.data)
  }

  const transactionSalesToSaleItem = ({
    transactionSide,
    sales,
  }: {
    transactionSide: TransactionSideType
    sales?: TransactionSaleType[]
  }): SaleItemType[] => {
    return (sales || [])
      .filter(sale => !sale.isPaid)
      .map(sale => ({
        id: sale.salesId,
        isSelected: transactionSide !== TransactionSideType.Smaregi,
        accommodationId: undefined,
        date: dayjs(sale.salesAt),
        subject: sale.saleSubjectName,
        subSubject: sale.saleSubSubjectName,
        quantity: sale.quantity,
        price: sale.salesPrice,
        transactionSide,
      }))
  }

  const salesToSaleItem = ({ sales }: { sales: SalesType[] }): SaleItemType[] => {
    return sales
      .filter(sale => !sale.isPaid && sale.id)
      .map(sale => ({
        id: sale.id || '',
        isSelected: sale.transactionSide !== TransactionSideType.Smaregi,
        accommodationId: sale.accommodationId,
        date: dayjs(sale.salesDate),
        subject: sale.salesSubjectName,
        subSubject: sale.salesSubSubjectName || undefined,
        quantity: sale.quantity,
        price: sale.salesPrice,
        transactionSide: sale.transactionSide,
      }))
  }

  const discountToSaleItem = ({ sales }: { sales: SalesDiscountType[] }): SaleItemType[] => {
    return sales
      .filter(sale => sale.salesPaymentName === '-')
      .map(sale => ({
        id: sale.id,
        isSelected: sale.transactionSide !== TransactionSideType.Smaregi,
        accommodationId: sale.accommodationId,
        date: dayjs(sale.salesDate),
        subject: sale.salesDiscountName,
        subSubject: undefined,
        quantity: 1,
        price: sale.salesSubjectPrice * -1,
        transactionSide: sale.transactionSide,
      }))
  }

  const fetchSalesByCheckinId = (checkinId: string) => {
    setIsLoading(true)
    Promise.all([fetchSales(checkinId).then(salesToSaleItem), fetchDiscount(checkinId).then(discountToSaleItem)])
      .then(fetchedSales => {
        setSaleItemList(fetchedSales.flat())
      })
      .finally(() => setIsLoading(false))
  }

  const fetchSalesByReservation = (reservationId: string) => {
    setIsLoading(true)
    Promise.all([
      fetchSalesReservation(reservationId).then(salesToSaleItem),
      fetchReservationDiscount(reservationId).then(discountToSaleItem),
    ])
      .then(fetchedSales => {
        setSaleItemList(fetchedSales.flat())
      })
      .finally(() => setIsLoading(false))
  }

  const fetchSalesByTransaction = (transaction: TransactionType) => {
    setIsLoading(true)
    fetchSmaregiTransactionDetail(transaction.id)
      .then((transaction: TransactionType) => {
        const sales = transactionSalesToSaleItem(transaction)
        setSaleItemList(sales)
      })
      .finally(() => setIsLoading(false))
  }

  const fetchSmaregiTerminal = () => {
    fetchSmaregiTerminals().then((terminals: TerminalType[]) => {
      if (terminals.length === 0) {
        alert(t('POS terminal is not registered.'))
        return
      }
      setSelectTerminal(terminals[0])
      setTerminalOptions(
        terminals.map((terminal, index) => ({
          value: index,
          label: terminal.terminalName,
          data: terminal,
        })),
      )
    })
  }

  const isSubmittedItem = (saleItem: SaleItemType) => {
    return saleItem.transactionSide === TransactionSideType.Smaregi
  }

  useEffect(() => {
    const hasSelectedItem = saleItemList.findIndex(item => item.isSelected) !== -1
    setIsDisabledSend(!hasSelectedItem || terminalOptions.length === 0)

    const sumSelectedPrice = saleItemList.reduce((carry, item) => (item.isSelected ? carry + item.price : carry), 0)
    setSumSelectSalePrice(sumSelectedPrice)
  }, [saleItemList, terminalOptions])

  useEffect(() => {
    fetchSmaregiTerminal()
    if (checkinId) {
      fetchSalesByCheckinId(checkinId)
    } else if (reservationId) {
      fetchSalesByReservation(reservationId)
    } else if (transaction) {
      fetchSalesByTransaction(transaction)
    } else {
      setIsLoading(false)
    }
  }, [])

  return (
    <>
      {isShowSuccessModal ? (
        <PosCalculateSuccessModal onClose={() => onClose(true)} />
      ) : (
        <div css={modalWrapperStyle}>
          <div css={modalContentStyle}>
            <div css={modalTitleWrapperStyle}>
              <p css={modalTitleStyle}>{t('POS Calculate')}</p>
            </div>

            <div css={contentWrapperStyle}>
              <div css={contentTerminalAreaStyle}>
                <div style={{ fontSize: 12, fontWeight: 'bold', letterSpacing: 0.6, paddingRight: 16 }}>{t('Transmitting terminal')}</div>
                <select css={selectBoxStyle} onChange={e => changedTerminal(Number(e.target.value))}>
                  {terminalOptions.map(opt => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </select>
              </div>

              <div css={contentSalesListAreaStyle}>
                <div css={contentSalesListHeaderStyle}>
                  <div css={contentSaleListCheckColStyle}></div>
                  <div css={contentSaleListReservationColStyle}>{t('Target reservation')}</div>
                  <div css={contentSaleListSaleDateColStyle}>{t('Date of use')}</div>
                  <div css={contentSaleListSubjectColStyle}>{t('Reference')}</div>
                  <div css={contentSaleListSubSubjectColStyle}>{t('Supplementary subjects')}</div>
                  <div css={contentSaleListQuantityColStyle}>{t('Quantity')}</div>
                  <div css={contentSaleListPriceColStyle}>{t('Amount used')}</div>
                </div>
                <div css={contentSalesListBodyStyle}>
                  {saleItemList.map((saleItem, idx) => (
                    <div
                      css={!isSubmittedItem(saleItem) ? contentSalesListRowStyle : contentDisabledSalesListRowStyle}
                      key={idx}
                      onClick={() => !isSubmittedItem(saleItem) && checkedSaleItemList(idx)}
                    >
                      <div css={contentSaleListCheckColStyle}>
                        <input
                          width={18}
                          alt="checkbox"
                          type="image"
                          name="cancellationReport"
                          disabled={isSubmittedItem(saleItem)}
                          src={require(`@/static/images/check-box${saleItem.isSelected ? '' : '_off'}.svg`)}
                        />
                      </div>
                      <div css={contentSaleListReservationColStyle}>{saleItem.accommodationId || '-'}</div>
                      <div css={contentSaleListSaleDateColStyle}>{saleItem.date.format(t('MM-DD'))}</div>
                      <div css={contentSaleListSubjectColStyle}>{saleItem.subject}</div>
                      <div css={contentSaleListSubSubjectColStyle}>{saleItem.subSubject || '-'}</div>
                      <div css={contentSaleListQuantityColStyle}>{saleItem.quantity}</div>
                      <div css={contentSaleListPriceColStyle}>¥{saleItem.price.toLocaleString()}</div>
                    </div>
                  ))}
                </div>
                <div css={contentSalesListFooterStyle}>
                  <span style={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 0.7 }}>{t('Amount of money')}</span>
                  <span style={{ fontSize: 20, fontWeight: 'bold', width: 140, textAlign: 'end' }}>
                    ¥{sumSelectSalePrice.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>

            <div css={footerButtonWrapperStyle}>
              <Button width={110} height={38} marginRight={16} buttonType={3} onClick={() => onClose(false)}>
                {t('Cancel')}
              </Button>
              <Button width={110} height={38} buttonType={isDisabledSend ? 5 : 1} isDisabled={isDisabledSend} onClick={sendPosCalculate}>
                {t('Submit')}
              </Button>
            </div>
          </div>
          <LoadingFull isLoading={isLoading} />
        </div>
      )}
    </>
  )
}

const modalWrapperStyle = css(salesMainContainerStyle, {
  width: '100vw',
  height: '100vh',
  background: 'rgba(39, 39, 39, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'scroll',
  zIndex: 100,
})

const modalContentStyle = css({
  width: 900,
  maxHeight: 650,
  background: '#fff',
  borderRadius: 5,
  marginBottom: 40,
})

const modalTitleWrapperStyle = css({
  width: 'inherit',
  paddingLeft: 32,
  position: 'absolute',
  backgroundColor: 'white',
  borderRadius: '5px 5px 0px 0px',
})

const modalTitleStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: '60px',
  letterSpacing: '0.9px',
})

const contentWrapperStyle = css({
  width: '100%',
  padding: '60px 32px 32px',
  background: '#f2f2f2',
  display: 'flex',
  flexFlow: 'column',
  borderRadius: '5px 5px 0 0',
  div: {
    color: '#676767',
  },
})

const contentTerminalAreaStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: '17px 0',
})

const contentSalesListAreaStyle = css({
  height: 472,
  border: '1px solid #CCCCCC',
  borderRadius: 10,
  display: 'flex',
  flexFlow: 'column',
})
const contentSalesListHeaderStyle = css({
  padding: '10px 35px 10px 24px',
  display: 'flex',
  fontSize: 12,
  letterSpacing: 1.2,
  fontWeight: 'bold',
})
const contentSalesListBodyStyle = css({
  backgroundColor: '#ffffff',
  height: '100%',
  overflow: 'hidden auto',
})
const contentSalesListFooterStyle = css({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  padding: '20px 35px',
  backgroundColor: '#ffffff',
  borderRadius: '0 0 10px 10px',
  borderTop: '1px solid #CCCCCC',
})
const contentSalesListRowStyle = css({
  cursor: 'pointer',
  padding: '14px 35px 14px 24px',
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  letterSpacing: 1.4,
  color: '#272727',
  borderBottom: '1px solid #f2f2f2',
})
const contentDisabledSalesListRowStyle = css(contentSalesListRowStyle, {
  cursor: 'auto',
  backgroundColor: '#f5f5f5',
  div: {
    color: '#A3A3A3',
  },
})

const contentSaleListCheckColStyle = css({
  width: 40,
})
const contentSaleListReservationColStyle = css({
  width: 80,
})
const contentSaleListSaleDateColStyle = css({
  width: 100,
})
const contentSaleListSubjectColStyle = css({
  flexGrow: 1,
})
const contentSaleListSubSubjectColStyle = css({
  flexGrow: 1,
  width: 200,
  maxWidth: 200,
})
const contentSaleListQuantityColStyle = css({
  width: 60,
})
const contentSaleListPriceColStyle = css({
  width: 80,
  textAlign: 'end',
})

const footerButtonWrapperStyle = css({
  height: 60,
  paddingRight: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: 'inherit',
  position: 'absolute',
  backgroundColor: 'white',
  borderRadius: '0px 0px 5px 5px',
})

const selectBoxStyle = css({
  height: 32,
  width: 278,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  display: 'block',
  paddingLeft: 14,
  paddingRight: 14,
  marginRight: 10,
  backgroundPosition: '90% center',
})
