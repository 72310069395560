import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Cascader, Select } from 'antd'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

//components
import { DatePicker } from '@/components/organisms/customer/sales-manager/date-picker'

// constants
import { deleteStyle } from '@/constants/layout'

// models
import { PaymentSettingType, SalesType, SubjectSettingType } from '@/models/sales'
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'
import { ReservationType } from '@/models/reservation'
import {
  getRoomCascadeOptionsByReservations,
  findRoomCascadeOptionByGuestRoomAssignId,
  roomCascadeOptionsLabelRender,
} from '@/utils/reservations'

type NotConfirmedUsageDetailProps = {
  index: number
  usageDetail: SalesType
  salesSubjects: SubjectSettingType[]
  salesPayments: PaymentSettingType[]
  confirmedDay: string
  isEditMode: boolean
  advanceCompany: any
  changeNotConfirmedDate: (date, index) => void
  changeNotConfirmedSalesPrice: (salesPrice: number, index) => void
  changeNotConfirmedState: (e, index) => void
  deleteUsageDetail: (index) => void
  setIsEditMode: (boolean) => void
  accountsReceivableSettings: AccountsReceivableSettingType[]
  reservations?: ReservationType[]
  reservation?: ReservationType
  salesSubjectsWithoutSameSubjectCode: SubjectSettingType[]
  currentTabReservationId?: string
}

export const NotConfirmedUsageDetail: React.FC<NotConfirmedUsageDetailProps> = ({
  index,
  usageDetail,
  salesSubjects,
  salesPayments,
  advanceCompany,
  changeNotConfirmedDate,
  confirmedDay,
  isEditMode,
  changeNotConfirmedSalesPrice,
  changeNotConfirmedState,
  deleteUsageDetail,
  setIsEditMode,
  accountsReceivableSettings,
  reservations,
  reservation,
  salesSubjectsWithoutSameSubjectCode,
  currentTabReservationId,
}) => {
  const { t } = useTranslation()
  const [filteredSubjectLists, setFilteredSubjectLists] = useState<SubjectSettingType[]>([])

  const onChangeSelectSubject = (value?: string) => {
    if (value) {
      const filteredSubjectIds = salesSubjects?.filter(salesSubject => value === salesSubject.id)
      const subjectLists = salesSubjects?.filter(salesSubject => salesSubject?.subjectCode === filteredSubjectIds[0]?.subjectCode)
      setFilteredSubjectLists(subjectLists || [])
    } else {
      const filteredSubjectIds = salesSubjects?.filter(salesSubject => usageDetail.salesSubjectId === salesSubject.id)
      const subjectLists = salesSubjects?.filter(salesSubject => salesSubject?.subjectCode === filteredSubjectIds[0]?.subjectCode)
      setFilteredSubjectLists(subjectLists || [])
    }
  }

  const clickForm = salesSubjects => {
    salesSubjects?.map(salesSubject => {
      if (usageDetail.salesSubSubjectId === salesSubject.subSubjectId) {
        if (salesSubject.subSubjectPrice) {
          changeNotConfirmedSalesPrice(salesSubject.subSubjectPrice, index)
        } else {
          changeNotConfirmedSalesPrice(salesSubject.price.price, index)
        }
      }
    })
  }

  const newAdvanceCompany = advanceCompany?.paymentCompanies?.map(item => ({
    value: item?.id,
    label: item?.name,
    disabled: !item.currentBalance,
  }))
  const salesPaymentOptions =
    salesPayments?.map(item => {
      if (Object.keys(advanceCompany?.paymentMethod || {}).length && item?.id === advanceCompany?.paymentMethod?.id) {
        return { value: item?.id, label: item?.name, children: newAdvanceCompany }
      } else {
        return { value: item?.id, label: item?.name }
      }
    }) || []

  const roomsOptions = getRoomCascadeOptionsByReservations({ reservation, reservations })

  const displayRender = label => label[label.length - 1]

  const salesSubjectIds: string[] = []
  salesSubjects?.map(salesSubject => salesSubjectIds.push(salesSubject.subSubjectCode))

  const salesPaymentIds: string[] = []
  salesPayments?.map(salesPayment => salesPaymentIds.push(salesPayment.id))

  useEffect(() => {
    onChangeSelectSubject()
    //If there is no initial value for usageDetail, substitute the initial value
    if (!usageDetail.reservationId && currentTabReservationId) {
      usageDetail.reservationId = currentTabReservationId
    }
    if (!usageDetail.salesAccountsReceivableMasterId) {
      usageDetail.salesAccountsReceivableMasterId = ''
    }
  }, [salesSubjects])

  return (
    <tr>
      <td>
        <div onClick={() => setIsEditMode(true)}>
          <DatePicker
            date={usageDetail.salesDate ? moment(usageDetail.salesDate) : null}
            setDate={date => changeNotConfirmedDate(date, index)}
            confirmedDay={confirmedDay}
          />
        </div>
      </td>
      <td>
        <select
          name="salesSubjectId"
          css={isEditMode ? editModeLargeSelectBoxStyle : largeSelectBoxStyle}
          onClick={() => setIsEditMode(true)}
          onChange={e => {
            changeNotConfirmedState(e, index)
            onChangeSelectSubject(e.target.value)
            clickForm(salesSubjects)
          }}
        >
          {/* Show only when subject is deleted */}
          <option selected={usageDetail.salesSubjectId === ''} value="">
            {t('Please select')}
          </option>
          {salesSubjectsWithoutSameSubjectCode.map(salesSubject => (
            <option key={salesSubject.id} selected={usageDetail.salesSubjectId === salesSubject.id} value={salesSubject.id}>
              {salesSubject.subjectName}
            </option>
          ))}

          {/* When options come in */}
          {usageDetail.salesSubjectId !== '' &&
            !salesSubjectsWithoutSameSubjectCode.some(
              salesSubjectsWithoutSameSubjectCodeDetail => salesSubjectsWithoutSameSubjectCodeDetail.id === usageDetail.salesSubjectId,
            ) && (
              <option value="" selected>
                {usageDetail.salesSubjectName}
              </option>
            )}
        </select>
      </td>
      <td>
        <select
          name="salesSubSubjectId"
          css={isEditMode ? editModeLargeSelectBoxStyle : largeSelectBoxStyle}
          onClick={() => setIsEditMode(true)}
          onChange={e => {
            changeNotConfirmedState(e, index)
          }}
        >
          <option value="">-</option>
          {filteredSubjectLists.length > 0 &&
            filteredSubjectLists.map(
              subSubject =>
                subSubject.subSubjectName !== '-' && (
                  <option
                    key={subSubject.subSubjectId}
                    selected={usageDetail.salesSubSubjectId === subSubject.subSubjectId}
                    value={subSubject.subSubjectId}
                  >
                    {subSubject.subSubjectName}
                  </option>
                ),
            )}

          {usageDetail.salesSubSubjectId &&
            usageDetail.salesSubSubjectName &&
            usageDetail.salesSubSubjectName !== '-' &&
            !filteredSubjectLists.some(subjectMaster => subjectMaster.subSubjectId === usageDetail.salesSubSubjectId) && (
              <option value={usageDetail.salesSubSubjectId} selected>
                {usageDetail.salesSubSubjectName}
              </option>
            )}
        </select>
      </td>
      <td>
        <Cascader
          options={roomsOptions}
          expandTrigger="hover"
          className={isEditMode ? 'cascader-customize' : 'cascader-customize not-edit-mode-cascader'}
          value={findRoomCascadeOptionByGuestRoomAssignId({
            guestRoomAssignId: usageDetail?.guestRoomAssignId,
            reservationId: usageDetail?.reservationId ? usageDetail.reservationId : currentTabReservationId,
            roomsOptions,
          })}
          displayRender={roomCascadeOptionsLabelRender}
          popupClassName="popup-cascader-customize"
          onChange={async value => {
            setIsEditMode(true)

            changeNotConfirmedState(
              {
                target: { value: value[0], name: 'reservationId' },
              },
              index,
            )
            changeNotConfirmedState(
              {
                target: { value: value[1], name: 'guestRoomAssignId' },
              },
              index,
            )
          }}
          allowClear={false}
          suffixIcon={<span></span>}
          changeOnSelect
        />
      </td>
      <td>
        <input
          name="salesSubjectPrice"
          type="number"
          css={unitPriceStyle}
          onClick={() => setIsEditMode(true)}
          value={usageDetail.salesSubjectPrice}
          onChange={e => changeNotConfirmedState(e, index)}
          placeholder="0000"
          className="unitPriceStyle"
        />
      </td>
      <td>
        <input
          name="quantity"
          type="number"
          min={0}
          css={smallSelectBoxStyle}
          onClick={() => setIsEditMode(true)}
          value={usageDetail.quantity ? Number(usageDetail.quantity).toString() : '0'}
          onChange={e => changeNotConfirmedState(e, index)}
          placeholder="0"
          className="quantityStyle"
        />
      </td>
      <td>{Number(usageDetail.salesPrice).toLocaleString()}</td>
      <td>
        <Cascader
          options={salesPaymentOptions}
          expandTrigger="hover"
          className={isEditMode ? 'cascader-customize' : 'cascader-customize not-edit-mode-cascader'}
          value={usageDetail.companyId ? [usageDetail.salesPaymentId, usageDetail.companyId] : [usageDetail.salesPaymentId]}
          displayRender={displayRender}
          popupClassName="popup-cascader-customize"
          onChange={async value => {
            setIsEditMode(true)
            const eventChangeSalesPaymentId = {
              target: { value: value[0], name: 'salesPaymentId' },
            }

            const eventChangeCompanyId = {
              target: { value: value[1] ? value[1] : '', name: 'companyId' },
            }
            await changeNotConfirmedState(eventChangeSalesPaymentId, index)
            changeNotConfirmedState(eventChangeCompanyId, index)
          }}
          allowClear={false}
          suffixIcon={<span></span>}
        />
      </td>
      <td>
        <select
          name="isPaid"
          css={isEditMode ? editModeMiddleSelectBoxStyle : middleSelectBoxStyle}
          onClick={() => setIsEditMode(true)}
          className={!usageDetail.isPaid ? 'unpaidStyle' : 'paidStyle'}
          defaultValue={!usageDetail.isPaid ? '0' : '1'}
          onChange={e => changeNotConfirmedState(e, index)}
        >
          <option selected={!usageDetail.isPaid} value="0">
            {t('Unpaid')}
          </option>
          <option selected={!!usageDetail.isPaid} value="1">
            {t('Paid')}
          </option>
        </select>
      </td>
      <td>
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={true}
          suffixIcon={isEditMode ? <img src={require('@/static/images/arrow_down_yellow.svg')} /> : <></>}
          onChange={value => {
            setIsEditMode(true)
            changeNotConfirmedState(
              {
                target: { value, name: 'salesAccountsReceivableMasterId' },
              },
              index,
            )
          }}
          value={usageDetail.salesAccountsReceivableMasterId}
        >
          <Select.Option value="">-</Select.Option>
          {accountsReceivableSettings.map((accountsReceivable, index) => (
            <Select.Option key={index} value={accountsReceivable.id}>
              {accountsReceivable.name}
            </Select.Option>
          ))}
          {usageDetail.salesAccountsReceivableMasterId !== '' &&
            !accountsReceivableSettings.some(
              accountsReceivable => accountsReceivable.id === usageDetail.salesAccountsReceivableMasterId,
            ) && (
              <option value={usageDetail.salesAccountsReceivableMasterId} selected>
                {usageDetail.salesAccountsReceivableName}
              </option>
            )}
        </Select>
      </td>
      <td css={deleteWrapperStyle}>
        {isEditMode && (
          <div css={deleteStyle} className="delete-none-0" onClick={() => deleteUsageDetail(index)}>
            <img src={require('@/static/images/delete_yellow.svg')} />
          </div>
        )}
      </td>
    </tr>
  )
}

const selectBoxStyle = css({
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  appearance: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  display: 'block',
  paddingLeft: 14,
  paddingRight: 14,
  marginRight: 10,
})

const smallSelectBoxStyle = css(selectBoxStyle, {
  width: '100%',
  backgroundPosition: '80% center',
})

const middleInputStyle = css(selectBoxStyle, {
  backgroundImage: 'none',
  '::placeholder': {
    color: '#ccc',
  },
})

const deleteWrapperStyle = css({
  minWidth: 30,
})

export const unitPriceStyle = css(middleInputStyle, {
  marginLeft: 12,
})

const middleSelectBoxStyle = css(selectBoxStyle, {
  backgroundPosition: 'calc(100% - 8px) center',
})

const largeSelectBoxStyle = css(selectBoxStyle, {
  backgroundPosition: 'calc(100% - 8px) center',
})

export const xlargeSelectBoxStyle = css(selectBoxStyle, {
  backgroundPosition: 'calc(100% - 8px) center',
})

// 編集モードのセレクトボックス
const editModoSelectBoxStyle = css(selectBoxStyle, {
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
})

const editModeMiddleSelectBoxStyle = css(middleSelectBoxStyle, editModoSelectBoxStyle)

const editModeLargeSelectBoxStyle = css(editModoSelectBoxStyle, largeSelectBoxStyle)
