import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import 'react-dates/initialize'
import { modalContainerStyle } from '@/components/pages/accommodation-management/list-layout'
import { Button, ButtonType } from '@/components/atoms/button'
import { DateRangeFixedCalender } from '@/components/molecules/date-range-fixed-calender'
import * as api from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import { AddFormFieldButton } from '@/components/molecules/add-form-field-button'
import { LoaderContextCreator } from '@/contexts/loader'
import { RoomTypeAndNumArrayItem } from '@/components/organisms/accommodation-management/room-type-and-num-array-item'
import { useAssignConfirmDialog } from '@/components/organisms/room-manager/use-assign-confirm-dialog'
import moment from 'moment'

type ContainerProps = {
  setIsModalOpen: (isModalOpen: boolean) => void
  onCreated: (reservation: { reservationId: string; reservationUuid: string }) => void
}

export type FormType = {
  beginDate: string
  endDate: string
  rooms: Array<{ guestRoomId: string; num: string | undefined; typeName: string }>
}

export const ReservationCreateWithRoomModal: React.FC<ContainerProps> = ({ setIsModalOpen, onCreated }) => {
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { AssignConfirmDialog, openConfirmDialog } = useAssignConfirmDialog()
  const { t } = useTranslation()
  const { errorHandler, isOutOfStockError } = useErrorHandler()
  const { control, handleSubmit, setValue } = useForm<FormType>({
    defaultValues: {
      beginDate: '',
      endDate: '',
      rooms: [{ guestRoomId: '', num: undefined, typeName: '' }],
    },
  })
  const roomInputArray = useFieldArray({
    control,
    name: 'rooms',
  })
  const [guestRoomInfos, setGuestRoomInfos] = useState<Array<{ guestRoomId: string; typeName: string; roomNum: number }>>()
  const [isEnabledAutAssign, setIsEnabledAutAssign] = useState<boolean>(false)
  const watchBeginDate = useWatch({ control, name: 'beginDate' })
  const watchEndDate = useWatch({ control, name: 'endDate' })

  const createReservation = async (formValue: FormType) => {
    try {
      setIsLoading(true)
      const res = await api.createReservation(formValue.beginDate, formValue.endDate)
      onCreated(res)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const createReservationAndRooms = async (formValue: FormType) => {
    try {
      const roomAssignParams = {
        checkinDate: moment(formValue.beginDate),
        checkoutDate: moment(formValue.endDate),
        editGuestRooms: formValue.rooms
          .filter(room => room.guestRoomId)
          .flatMap(room => {
            return [...Array(Number(room.num))].map(v => ({
              roomTypeId: null,
              typeNameJa: room.typeName,
              typeNameEn: null,
              roomNumber: null,
              guestRoomId: room.guestRoomId,
              assignCheckinDate: formValue.beginDate,
              assignCheckoutDate: formValue.endDate,
            }))
          }),
        reservationTel: '',
        reservationName: '',
      }

      try {
        await api.putRoomManagerPluginGuestRoomAssign({
          ...roomAssignParams,
          validationOnly: true,
        })
      } catch (e) {
        if (!isOutOfStockError(e as Error)) {
          throw e
        }
        if (!(await openConfirmDialog())) {
          return
        }
      }
      setIsLoading(true)
      const res = await api.putRoomManagerPluginGuestRoomAssign({
        ...roomAssignParams,
        excludeStockCheck: true,
      })
      if (isEnabledAutAssign) {
        await api.guestRoomAutoAssign({ reservationIds: [res.reservationId], pageType: 'reservation' })
      }
      onCreated(res)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const changeRangeDate = (beginDate: string, endDate: string) => {
    setValue('beginDate', beginDate)
    setValue('endDate', endDate)
  }

  const isValid = useMemo(() => watchBeginDate && watchEndDate, [watchBeginDate, watchEndDate])

  useEffect(() => {
    api.getAdminGuestRoomType().then((res: Array<{ specifiedRoomId: string; typeNameJa: string; quantity: number; hasRoom: boolean }>) => {
      const mappedRooms = (res || [])
        .filter(roomInfo => roomInfo.quantity && roomInfo.hasRoom)
        .map(roomInfo => ({
          guestRoomId: roomInfo.specifiedRoomId,
          typeName: roomInfo.typeNameJa,
          roomNum: roomInfo.quantity,
        }))
      setGuestRoomInfos(mappedRooms)
    })
    api.fetchAutoAssignmentSettings().then(res => {
      setIsEnabledAutAssign(res.timingOfImportReservation)
    })
  }, [])

  return (
    <div css={modalContainerStyle}>
      <div css={modalBodyContainerStyle}>
        <h3 css={modalHeaderStyle}>{t('New reservation')}</h3>
        <div css={modalMainContainerStyle}>
          <div>
            <div css={fieldTittleStyle}>
              {t('Date of stay')}
              <div>※</div>
            </div>
            <DateRangeFixedCalender beginDate={watchBeginDate} endDate={watchEndDate} changeRangeDate={changeRangeDate} />
          </div>
          <div style={{ width: '100%' }}>
            <div css={fieldTittleStyle}>{t('Guest room')}</div>
            <div className="room-field-container">
              <div className="room-field-row">
                <div css={fieldTittleStyle}>{t('Room type')}</div>
                <div css={fieldTittleStyle}>{t('No. of rooms')}</div>
              </div>
              {roomInputArray.fields.map((roomInput, index) => (
                <RoomTypeAndNumArrayItem key={index} index={index} control={control} setValue={setValue} guestRoomInfos={guestRoomInfos} />
              ))}
              <AddFormFieldButton
                label={t('Add room type')}
                onAdd={() => {
                  roomInputArray.append({ guestRoomId: '', num: undefined, typeName: '' })
                }}
              />
            </div>
          </div>
        </div>
        <div css={modalButtonSectionStyle}>
          <Button
            width={110}
            height={38}
            buttonType={3}
            marginRight={16}
            onClick={() => {
              setIsModalOpen(false)
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            width={110}
            height={40}
            buttonType={isValid ? ButtonType.Primary : ButtonType.PrimaryDisabled}
            isDisabled={!isValid}
            onClick={handleSubmit(async (formValue: FormType) => {
              const hasSelectedRoom = formValue.rooms.filter(room => room.guestRoomId).length
              if (hasSelectedRoom) {
                await createReservationAndRooms(formValue)
              } else {
                await createReservation(formValue)
              }
              setIsModalOpen(false)
            })}
          >
            {t('Create')}
          </Button>
        </div>
      </div>
      <AssignConfirmDialog />
    </div>
  )
}

// モーダル
const modalBodyContainerStyle = css({
  position: 'relative',
  top: '42%',
  left: '50%',
  width: 900,
  opacity: 1,
  background: '#FFF',
  borderRadius: 5,
  transform: 'translate(-50%, -50%)',
})

const modalHeaderStyle = css({
  height: 40,
  margin: '22px 32px',
  fontWeight: 'bold',
  fontSize: 18,
  color: '#272727',
  paddingTop: 22,
})

const modalMainContainerStyle = css({
  padding: 32,
  backgroundColor: '#F2F2F2',
  maxHeight: 406,
  display: 'flex',
  gap: 24,
  overflowY: 'auto',
  '.room-field-container': {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    padding: 24,
    '.room-field-row': {
      display: 'flex',
      gap: 16,
      '> div': {
        ':first-of-type': {
          width: 276,
        },
        ':last-of-type': {
          flexGrow: 1,
        },
      },
    },
  },
})

const fieldTittleStyle = css({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
  marginBottom: 12,
  display: 'flex',
  '> div': {
    fontSize: 8,
    paddingLeft: 4.75,
  },
})

const modalButtonSectionStyle = css({
  display: 'flex',
  padding: '11px 32px 11px 32px',
  justifyContent: 'flex-end',
  boxShadow: '0px 0px 6px #0000001A',
})
