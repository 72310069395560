import React, { useState, useEffect, useCallback } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { LanguageType } from '@/i18n'

// libs
import { hasAnyReservationPlugin } from '@/libs/plugins'
import { convertToSeparatedName } from '@/libs/convertToSeparatedName'

//components
import { SelfCheckinHeader } from '@/components/molecules/self-checkin/header'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { AccommodationForm } from './form'

//models
import { AccommodationInput, GenderType, NationalityType, OccupationType, isEmployee } from '@/models/self-checkin/accommodation-info'
import { CurrentVersionKey, fetchReservationsAndSales, LatestVersionKey } from '@/apis/aipass'
import { setHasPaymentPlugin } from '@/libs/plugins'

// utils
import { changeRequiredIdentify } from '@/utils/required-identify'
import { SearchReservationTypeWhenNonCheckIn } from '@/models/reservation'

export const AccommodationInfo: React.FC<{}> = () => {
  const { search, state } = useLocation<{
    hotelId: string
    basicInfo: any
    plugin: any
    reservationSearchCondtion: {
      inputType: string
      phoneNumber: string
      reservationId: string
      firstName: string
      lastName: string
      firstNameFurigana: string
      lastNameFurigana: string
    }
    selectedReservations: any
    accommodationInfo?: AccommodationInput
    noneSelectReservation: any
    requiredIdentify: boolean
    requiredJapaneseIdentify: boolean
    requiredForeignIdentify: boolean
    requiredAccompanyNameInput: boolean
    requiredFuriganaInput: boolean
    requiredBirthDateInput: boolean
    requiredGenderInput: boolean
    requiredTelephoneInput: boolean
    requiredEmailInput: boolean
    requiredAddressInput: boolean
    paymentInfo?: any
    paymentSetting?: any
    isGuidanceDisplay: boolean
    paxTotal?: number
    selectedReservationDetails: SearchReservationTypeWhenNonCheckIn[]
  }>()

  const history = useHistory()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [isFormValidRequiredFields, setIsFormValidRequiredFields] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [isPostCodeValid, setIsPostCodeValid] = useState(true)
  const [isLastNameValid, setIsLastNameValid] = useState(true)
  const [isFirstNameValid, setIsFirstNameValid] = useState(true)
  const [checkBox, setCheckBox] = useState(state?.requiredAccompanyNameInput ? 'false' : 'true')
  const accommodationInfoInitialData: AccommodationInput = {
    nationality: lang === LanguageType.ja ? NationalityType.Jp : NationalityType.Njp,
    firstName: '',
    lastName: '',
    firstNameFurigana: '',
    lastNameFurigana: '',
    dateOfBirth: '',
    gender: GenderType.Male,
    phoneNumber: '',
    mail: '',
    occupation: OccupationType.Employee,
    otherOccupation: '',
    postCode: '',
    company: '',
    address: '',
    paxTotal: state?.paxTotal || 1,
    accompany: [],
  }

  const [accommodationInfo, setAccommodationInfo] = useState<AccommodationInput>(accommodationInfoInitialData)
  const [listValidateProps, setListValidateProps] = useState(['firstName', 'lastName'])
  const isDisabled = (): boolean => {
    const isValid = checkValidateFormRequired()
    if (!isValid) {
      return true
    }
    return false
  }

  const checkValidateFormRequired = () => {
    for (const property in accommodationInfo) {
      const validAccompany = accommodationInfo?.accompany?.filter(v => !v.lastName.trim() || !v.firstName.trim()) ?? []
      if (
        checkBox === 'false' &&
        (!accommodationInfo?.accompany ||
          !accommodationInfo?.paxTotal ||
          accommodationInfo?.accompany?.length !== accommodationInfo?.paxTotal - 1 ||
          validAccompany?.length > 0)
      ) {
        return false
      }
      if (listValidateProps.includes(property)) {
        if (!accommodationInfo[property] || (typeof accommodationInfo[property] === 'string' && !accommodationInfo[property].trim())) {
          return false
        }
      }
    }
    if (lang === 'ja' && accommodationInfo.nationality === NationalityType.Jp && state.requiredFuriganaInput) {
      if (!accommodationInfo.firstNameFurigana.trim() || !accommodationInfo.lastNameFurigana.trim()) {
        return false
      }
    }

    return true
  }

  const checkEmailValid = value => {
    const halfByteRegex = /^[!-~]+$/
    const mailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return halfByteRegex.test(value) && mailRegex.test(value)
  }

  const checkNumberOnly = value => {
    return /^[0-9]*$/.test(value)
  }

  const checkOnlyAnphabet = value => {
    return /^[a-zA-Z\s]*$/.test(value)
  }

  const goBack = (): void => {
    if (hasAnyReservationPlugin(state.plugin)) {
      delete state.accommodationInfo
      history.push({
        pathname: `/self-checkin/select-reservation`,
        search,
        state,
      })
    } else {
      history.push({
        pathname: `/self-checkin`,
        search: `?hotelId=${state.hotelId}`,
      })
    }
  }

  const onChangeCheckBox = (e): void => {
    const element = document.getElementById('accompanyNameForm')
    if (!element) {
      return
    }
    const changeCheckbox = e.target.value === 'true' ? 'false' : 'true'
    changeCheckbox === 'true' ? (element.style.display = 'none') : (element.style.display = 'block')
    setCheckBox(changeCheckbox)
  }

  const onChangeAccommodationInfo = (name, value) => {
    if (listValidateProps.includes(name)) {
      if (typeof value === 'string' && !value.trim()) {
        setIsFormValidRequiredFields(false)
      }
    }
    if (
      (name === 'firstNameFurigana' || name === 'lastNameFurigana') &&
      lang === 'ja' &&
      accommodationInfo?.nationality === NationalityType.Jp &&
      state.requiredFuriganaInput
    ) {
      if (!value.trim()) {
        setIsFormValidRequiredFields(false)
      }
    }
    setAccommodationInfo(accomInfo => ({ ...accomInfo, [name]: value }))
  }

  const convertToNameKatakana = text => {
    return text.replace(/[\u3041-\u3096]/g, t => String.fromCharCode(t.charCodeAt(0) + 96))
  }

  const getNextPageUrl = async (): Promise<string> => {
    const isSelectJaLanguage = i18n.language === 'ja'
    if (state.noneSelectReservation) {
      if (state?.requiredIdentify) {
        return `/self-checkin/identity-verify/none-select-resvervation/${isSelectJaLanguage ? 'upload-license' : 'upload-passport'}`
      }
      return '/self-checkin/none-select-resvervation/confirm'
    }

    if (state.isGuidanceDisplay) {
      return '/self-checkin/checkin-customize'
    }

    if (setHasPaymentPlugin(state.plugin) && state.paymentSetting.length) {
      const res = await fetchReservationsAndSales(state.hotelId, state.selectedReservations)
      if (res?.totalAmount > 0) {
        return '/self-checkin/payment'
      }
    }
    delete state.paymentInfo

    if (state?.requiredIdentify) {
      return `/self-checkin/identity-verify/${isSelectJaLanguage ? 'upload-license' : 'upload-passport'}`
    }
    return '/self-checkin/confirm'
  }

  const submit = async () => {
    setIsEmailValid(true)
    setIsPhoneValid(true)
    setIsPostCodeValid(true)
    setIsFirstNameValid(true)
    setIsLastNameValid(true)
    const isValid = checkValidateFormRequired()
    setIsFormValidRequiredFields(isValid)
    if (isValid) {
      const checkEmail = accommodationInfo.mail ? checkEmailValid(accommodationInfo.mail) : true
      const checkPhone = checkNumberOnly(accommodationInfo.phoneNumber)

      const checkLastName = !(accommodationInfo.nationality === NationalityType.Jp && lang === 'ja')
        ? checkOnlyAnphabet(accommodationInfo.lastName)
        : true
      const checkFirstName = !(accommodationInfo.nationality === NationalityType.Jp && lang === 'ja')
        ? checkOnlyAnphabet(accommodationInfo.firstName)
        : true

      if (accommodationInfo?.nationality) {
        const responseRequiredIdentify = changeRequiredIdentify(
          accommodationInfo?.nationality,
          state.requiredJapaneseIdentify,
          state.requiredForeignIdentify,
        )
        state.requiredIdentify = responseRequiredIdentify
      }

      setIsEmailValid(checkEmail)
      setIsPhoneValid(checkPhone)
      setIsFirstNameValid(checkFirstName)
      setIsLastNameValid(checkLastName)

      const isValidForm = checkEmail && checkPhone && checkFirstName && checkLastName
      if (!isValidForm) {
        // set the name of the error message
        let scrollName = ''
        switch (true) {
          case !checkLastName:
            scrollName = 'lastName'
            break
          case !checkFirstName:
            scrollName = 'firstName'
            break
          case !checkPhone:
            scrollName = 'phoneNumber'
            break
          case !checkEmail:
            scrollName = 'mail'
            break
          default:
            // scroll to top
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
            break
        }
        if (scrollName) {
          // get element by name
          const scrollErrorName = document.getElementsByName(scrollName)
          // Scroll to target element
          scrollErrorName[0]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
        return
      }
      const nextPageUrl = await getNextPageUrl()
      history.push({
        pathname: nextPageUrl,
        search,
        state: {
          ...state,
          accommodationInfo,
          checkBox,
        },
      })
    }
  }

  const autoCompleteWithGuestInfo = (
    guestInfo: Partial<{
      name: string
      nameKana: string
      nationality: 'JPN' | 'NJP'
      gender: 'M' | 'F'
      postCode: string
      address: string
      mail: string
      phoneNumber: string
      dateOfBirth: string
      company: string
      occupation: string
      otherOccupation: string
    }>,
  ) => {
    const isJapanese = lang === LanguageType.ja
    const [guestFirstName, guestLastName] = convertToSeparatedName({ name: guestInfo.name || '', isJapanese })
    const guestNameKana = convertToNameKatakana(guestInfo.nameKana || '')
    const [guestFirstNameKana, guestLastNameKana] = convertToSeparatedName({ name: guestNameKana })

    const occupation = !guestInfo.occupation
      ? OccupationType.Employee
      : Object.values(OccupationType).includes(guestInfo.occupation as OccupationType)
      ? guestInfo.occupation
      : OccupationType.Other
    const otherOccupation = occupation === OccupationType.Other ? guestInfo.otherOccupation : ''
    const company = isEmployee(occupation) ? guestInfo.company : ''

    const defaultNationality = lang === LanguageType.ja ? NationalityType.Jp : NationalityType.Njp
    const autoCompletedNationality = guestInfo.nationality === 'JPN' ? NationalityType.Jp : NationalityType.Njp

    setAccommodationInfo({
      ...accommodationInfo,
      ...{
        nationality: guestInfo.nationality ? autoCompletedNationality : defaultNationality,
        firstName: guestFirstName,
        lastName: guestLastName,
        firstNameFurigana: guestFirstNameKana,
        lastNameFurigana: guestLastNameKana,
        dateOfBirth: guestInfo.dateOfBirth || '',
        gender: guestInfo.gender || GenderType.Male,
        phoneNumber: guestInfo.phoneNumber && guestInfo.phoneNumber !== '0' ? guestInfo.phoneNumber : '',
        mail: guestInfo.mail || '',
        occupation,
        otherOccupation,
        company,
        postCode: guestInfo.postCode || '',
        address: guestInfo.address || '',
      },
    })
  }

  const getReservationDetail = useCallback(async params => {
    const pickedReservation = state.selectedReservationDetails[0]

    const hasGuestInfo =
      pickedReservation.guestName ||
      pickedReservation.guestNameKana ||
      (pickedReservation.guestTel && pickedReservation.guestTel !== '0') ||
      pickedReservation.guestMailAddr ||
      pickedReservation.guestAddr

    const hasMemberInfo =
      pickedReservation.memberName ||
      pickedReservation.memberNameKana ||
      pickedReservation.memberNationality ||
      pickedReservation.memberGender ||
      pickedReservation.memberPostalCode ||
      pickedReservation.memberAddress ||
      pickedReservation.memberEmail ||
      (pickedReservation.memberTelephone && pickedReservation.memberTelephone !== '0') ||
      pickedReservation.memberBirthDate ||
      pickedReservation.memberCompany ||
      pickedReservation.memberOccupation ||
      pickedReservation.memberOtherOccupation

    if (hasMemberInfo) {
      autoCompleteWithGuestInfo({
        name: pickedReservation.memberName,
        nameKana: pickedReservation.memberNameKana,
        nationality: pickedReservation.memberNationality,
        gender: pickedReservation.memberGender,
        postCode: pickedReservation.memberPostalCode,
        address: pickedReservation.memberAddress,
        mail: pickedReservation.memberEmail,
        phoneNumber: pickedReservation.memberTelephone,
        dateOfBirth: pickedReservation.memberBirthDate,
        company: pickedReservation.memberCompany,
        occupation: pickedReservation.memberOccupation,
        otherOccupation: pickedReservation.memberOtherOccupation,
      })
    } else if (hasGuestInfo) {
      autoCompleteWithGuestInfo({
        name: pickedReservation.guestName,
        nameKana: pickedReservation.guestNameKana,
        phoneNumber: pickedReservation.guestTel,
        mail: pickedReservation.guestMailAddr,
        address: pickedReservation.guestAddr,
      })
    } else {
      autoCompleteWithGuestInfo({
        name: pickedReservation.userName,
        nameKana: pickedReservation.userNameKana,
        phoneNumber: pickedReservation.userTel,
        mail: pickedReservation.userMailAddr,
        address: pickedReservation.userAddr,
      })
    }
  }, [])

  useEffect(() => {
    if (!state) {
      return
    }
    if (state.selectedReservations && state.hotelId) {
      if (state.accommodationInfo) {
        setAccommodationInfo(state.accommodationInfo)
      } else {
        getReservationDetail({ reservationIds: state.selectedReservations, hotelId: state.hotelId })
      }
    }

    // セルフCIトップでプラグイン判定ができずnoneSelectReservationとなるケースに対応
    // 予約検索にreplaceする
    if (state.noneSelectReservation) {
      if (sessionStorage.getItem(CurrentVersionKey) !== sessionStorage.getItem(LatestVersionKey)) {
        sessionStorage.removeItem(CurrentVersionKey)
        sessionStorage.removeItem(LatestVersionKey)
        window.location.reload()
        return
      }
      if (hasAnyReservationPlugin(state.plugin)) {
        history.replace({
          pathname: `/self-checkin/search-reservation`,
          search,
          state: {
            ...state,
            noneSelectReservation: false,
          },
        })
        return
      }
    }

    if (state.noneSelectReservation && state.accommodationInfo) {
      setAccommodationInfo(state.accommodationInfo)
    }

    const addValidateProps: string[] = []
    if (state.requiredAccompanyNameInput) {
      addValidateProps.push('accompany')
    }
    if (state.requiredBirthDateInput) {
      addValidateProps.push('dateOfBirth')
    }
    if (state.requiredGenderInput) {
      addValidateProps.push('gender')
    }
    if (state.requiredTelephoneInput) {
      addValidateProps.push('phoneNumber')
    }
    if (state.requiredEmailInput) {
      addValidateProps.push('mail')
    }
    if (state.requiredAddressInput) {
      addValidateProps.push('address')
    }
    if (addValidateProps.length) {
      setListValidateProps([...listValidateProps, ...addValidateProps])
    }

    const paxTotal = Number(state.paxTotal) ?? 1
    const accompany = accommodationInfo?.accompany ?? []
    if (paxTotal > 1 && accompany?.length < paxTotal - 1) {
      const addAccompany = Array.from(new Array(paxTotal - 1)).map(() => {
        return { id: uuidv4(), lastName: '', firstName: '', name: '' }
      })
      accommodationInfo?.accompany?.push(...addAccompany)
    }
  }, [state?.hotelId, state?.selectedReservations])

  if (!state?.hotelId) {
    history.replace({ pathname: '/dashboard' })
    return <></>
  }

  return (
    <div css={containerStyle}>
      <SelfCheckinHeader goToPreviousPage={goBack} title={t('Enter check-in information')} />
      <div css={mainStyle}>
        <AccommodationForm
          accommodationInfo={accommodationInfo}
          onChange={onChangeAccommodationInfo}
          checkBox={checkBox}
          onChangeCheckBox={onChangeCheckBox}
          requiredAccompanyNameInput={state.requiredAccompanyNameInput}
          requiredFuriganaInput={state.requiredFuriganaInput}
          requiredBirthDateInput={state.requiredBirthDateInput}
          requiredGenderInput={state.requiredGenderInput}
          requiredTelephoneInput={state.requiredTelephoneInput}
          requiredEmailInput={state.requiredEmailInput}
          requiredAddressInput={state.requiredAddressInput}
          isFormValidRequiredFields={isFormValidRequiredFields}
          isEmailValid={isEmailValid}
          isPhoneValid={isPhoneValid}
          isPostCodeValid={isPostCodeValid}
          isFirstNameValid={isFirstNameValid}
          isLastNameValid={isLastNameValid}
        />
      </div>
      <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={submit} isNext={'next'} />
    </div>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
    color: '#272727',
  },
})

const mainStyle = css({
  padding: '102px 32px',
  minHeight: '100vh',
})
