import React, { useContext, useState, useEffect, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'react-dates/initialize'
import { useErrorHandler } from '@/hooks/use-error-handler'

import { AccountContext } from '@/contexts/account'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle, listContainerGrayStyle } from '@/constants/layout'
import { MAX_NUMBER_DOWNLOADS } from '@/constants/accommodation-management'

import * as api from '@/apis/aipass'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { CheckinList } from '@/components/pages/accommodation-management/_checkin/list'
import { StayList } from '@/components/pages/accommodation-management/_stay/list'
import { CheckoutList } from '@/components/pages/accommodation-management/_checkout/list'
import { ReservationList } from '@/components/pages/accommodation-management/_reservation/list'
import { Button } from '@/components/atoms/button'
import { CsvUploadModal } from '@/components/pages/accommodation-management/csv-upload-modal'
import { CSVReader } from '@/components/organisms/csv-reader'

// models
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { EntireSearchDateType } from '@/models/entire-search'
import { ReservationStatusType } from '@/models/reservation-reservation-status'

// libis
import { setHasTicketPlugin } from '@/libs/plugins'

// Advanced search (common)
type DetailedResearchType = {
  name: string
  nameKana: string
  searchReservationId: string
  roomNumber: string
  searchBeginDate: string
  searchEndDate: string
}

// Advanced Search (Reservation)
type ReservationDetailedResearchType = {
  selectGuestRoomAssign: string
  reservationStatuses: ReservationStatusType[] | undefined
}

type PageParams = {
  page: number
  tabType: TabType
} & DetailedResearchType &
  ReservationDetailedResearchType

const TabType = {
  Reservation: 'reservation',
  Checkin: 'checkin',
  Stay: 'stay',
  Checkout: 'checkout',
} as const
export type TabType = (typeof TabType)[keyof typeof TabType]

export const AccommodationManagement: React.FC = () => {
  const { t } = useTranslation()
  const { plugins, account } = useContext<any>(AccountContext)
  const location = useLocation<any>()
  const history = useHistory()
  const params: PageParams = require('query-string').parse(location.search)
  if ((params.reservationStatuses?.length || 0) > 1) {
    params.reservationStatuses = params.reservationStatuses?.map(Number) as ReservationStatusType[]
  } else if (params.reservationStatuses?.length === 1) {
    params.reservationStatuses = [Number(params.reservationStatuses)] as ReservationStatusType[]
  } else if (params.reservationStatuses?.length === 0) {
    params.reservationStatuses = undefined
  }

  // List data
  const [listTotal, setListTotal] = useState<number>(0)
  const [checkinList, setCheckinList] = useState([])
  const [reservationList, setReservationList] = useState([])
  const [hasList, setHaslist] = useState(0)

  // State management
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listIsFirstVisible, setListIsFirstVisible] = useState<boolean>(false)
  const [isNeedReload, setNeedReload] = useState<boolean>(true)
  const [pagingIsDisabled, setPagingIsDisabled] = useState<boolean>(true)
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)
  const [isStatusApproved, setIsStatusApproved] = useState<boolean>(false)
  const [isCsvUploadModalOpen, setIsCsvUploadModalOpen] = useState<boolean>(false)
  const hasTicketPlugin = setHasTicketPlugin(plugins)
  const { errorHandler } = useErrorHandler()

  const [tabType, setTabType] = useState<TabType>(params.tabType || TabType.Checkin)
  const [page, setPage] = useState<number>(params.page ? params.page : 1)
  const perPageNumber = 50

  const beginDateInitial = (params?: PageParams): string => {
    if (params?.searchBeginDate) {
      return params.searchBeginDate
    }
    if (tabType === TabType.Stay) {
      return ''
    }
    return dayjs().format('YYYY-MM-DD')
  }

  const endDateInitial = (params?: PageParams): string => {
    if (params?.searchEndDate) {
      return params.searchEndDate
    }
    if (tabType === TabType.Stay) {
      return ''
    }
    if (tabType === TabType.Checkin) {
      return dayjs().add(1, 'day').format('YYYY-MM-DD')
    }
    return dayjs().format('YYYY-MM-DD')
  }

  const detailedResearchInitial = (params?: PageParams, option?: { clear: boolean }): DetailedResearchType => ({
    name: params?.name || '',
    nameKana: params?.nameKana || '',
    searchReservationId: params?.searchReservationId || '',
    roomNumber: params?.roomNumber || '',
    searchBeginDate: option?.clear ? '' : beginDateInitial(params),
    searchEndDate: option?.clear ? '' : endDateInitial(params),
  })

  const reservationDetailedResearchInitial = (params?: PageParams): ReservationDetailedResearchType => ({
    selectGuestRoomAssign: params?.selectGuestRoomAssign || 'all',
    reservationStatuses: params?.reservationStatuses || [ReservationStatusType.NewReport, ReservationStatusType.ModificationReport],
  })

  const [detailedResearch, setDetailedResearch] = useState<DetailedResearchType>(detailedResearchInitial(params))
  const [reservationDetailedResearch, setReservationDetailedResearch] = useState<ReservationDetailedResearchType>(
    reservationDetailedResearchInitial(params),
  )

  const setQueryParams = (detailedResearch: DetailedResearchType, reservationDetailedResearch: ReservationDetailedResearchType) => {
    let queryParams = {
      lang: 'ja',
      tabType,
      page,
      ...detailedResearch,
    }

    if (tabType === TabType.Reservation) {
      queryParams = {
        ...queryParams,
        ...reservationDetailedResearch,
      }
    }

    const queryString = require('query-string')
    history.push({ search: `?${queryString.stringify(queryParams)}` })
  }

  const resetSearchParams = () => {
    setDetailedResearch(detailedResearchInitial(undefined, { clear: false }))
    setReservationDetailedResearch(reservationDetailedResearchInitial())
    setPage(1)
    setNeedReload(true)
  }

  const fetchAdminCheckinList = async () => {
    if (!account) {
      return
    }

    setIsLoading(true)
    setPagingIsDisabled(true)
    setQueryParams(detailedResearch, reservationDetailedResearch)

    if (tabType !== TabType.Checkin) {
      await fetchReservationList(detailedResearch, reservationDetailedResearch)
    } else {
      await api
        .fetchAdminCheckinList(
          Number(page),
          perPageNumber,
          detailedResearch.name,
          detailedResearch.nameKana,
          detailedResearch.searchReservationId,
          detailedResearch.roomNumber,
          detailedResearch.searchBeginDate,
          detailedResearch.searchEndDate,
        )
        .then(result => {
          const list = result?.checkinList || []
          const total = result?.total || 0

          setCheckinList([]) // To reset the table position to the top
          if (!list.length && page != 1) {
            setPage(1)
          }
          setListTotal(total)
          setHaslist(list.length)
          setCheckinList(list)
        })
    }
    setPagingIsDisabled(false)
    setIsLoading(false)
    setListIsFirstVisible(true)
    setNeedReload(false)
  }

  const fetchReservationList = async (
    detailedResearch: DetailedResearchType,
    reservationDetailedResearch: ReservationDetailedResearchType,
  ) => {
    await api
      .fetchReservation({
        page: Number(page),
        limit: perPageNumber,
        approvedStatuses: [approvedStatusByTabType],
        name: detailedResearch.name,
        nameKana: detailedResearch.nameKana,
        searchReservationId: detailedResearch.searchReservationId,
        roomNumber: detailedResearch.roomNumber,
        beginDate: detailedResearch.searchBeginDate,
        endDate: detailedResearch.searchEndDate,
        selectGuestRoomAssign: reservationDetailedResearch.selectGuestRoomAssign,
        reservationStatuses: reservationDetailedResearch.reservationStatuses || [],
        globalSearchDateType,
        telephone: '',
        note: '',
      })
      .then(result => {
        const list = result?.reservations || []
        const total = result?.total || 0

        setReservationList([]) // To reset the table position to the top
        if (!list.length && page != 1) {
          setPage(1)
        }
        setListTotal(total)
        setHaslist(list.length)
        setReservationList(list)
      })
  }

  const downloadAccommodationManagementCsv = async () => {
    if (listTotal >= MAX_NUMBER_DOWNLOADS) {
      alert(t('You cannot create more than {{num}} items Please press the download button after narrowing down the number of items', { num: MAX_NUMBER_DOWNLOADS }))
      return
    }
    setIsLoading(true)
    const data = {
      approvedStatuses: [approvedStatusByTabType],
      ...detailedResearch,
      ...reservationDetailedResearch,
      mode: 'accommodationList',
      globalSearchDateType,
    }
    await api.fetchAccommodationManagementCsv(data).then(() => {
      history.push({
        pathname: 'csv-download-history/list',
        search: location.search,
      })
      setIsLoading(false)
    })
  }

  const approvedStatusByTabType = useMemo(() => {
    switch (tabType) {
      case TabType.Checkin:
        return ApprovedStatusType.Checkin
      case TabType.Stay:
        return ApprovedStatusType.Stay
      case TabType.Checkout:
        return ApprovedStatusType.Checkout
      case TabType.Reservation:
      default:
        return ApprovedStatusType.Reservation
    }
  }, [tabType])

  const globalSearchDateType: EntireSearchDateType = useMemo(() => {
    switch (tabType) {
      case TabType.Stay:
      case TabType.Checkout:
        return EntireSearchDateType.Checkout
      case TabType.Reservation:
      case TabType.Checkin:
      default:
        return EntireSearchDateType.Checkin
    }
  }, [tabType])

  useEffect(() => {
    if (!listIsFirstVisible) {
      return
    }
    setCheckinList([])
    setReservationList([])
    resetSearchParams()
    setNeedReload(true)
  }, [tabType])

  useEffect(() => {
    isNeedReload && fetchAdminCheckinList()
  }, [isNeedReload])

  useEffect(() => {
    fetchAdminCheckinList()
  }, [page])

  const closeCsvUploadModal = () => {
    setIsCsvUploadModalOpen(false)
  }

  const onClickResearch = () => {
    fetchAdminCheckinList()
  }

  const onClearSearchParams = () => {
    setDetailedResearch(detailedResearchInitial(undefined, { clear: true }))
    setReservationDetailedResearch(reservationDetailedResearchInitial())
  }

  const onFileLoad = async (
    data: { reservationId: string; exchangeCode: string; ticketName: string }[],
    file: { name: string; size: number; type: string },
  ): Promise<void> => {
    try {
      const fileName = file.name
      const ticketData = data.map(function (value) {
        return {
          reservationId: value[1],
          exchangeCode: value[2],
          ticketName: value[3],
        }
      })
      await api.insertTicketCsv(fileName, ticketData)
    } catch (error) {
      errorHandler(error)
    }
  }

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Accommodation management')} />
        <div className="mainContainer" css={mainContainerStyle}>
          <ul css={tabStyle}>
            <li css={tabType === TabType.Reservation ? activeTabStyle : nonActiveTabStyle} onClick={() => setTabType(TabType.Reservation)}>
              <span css={tabCountTextStyle}>{t('Reserve')}</span>
            </li>
            <li css={tabType === TabType.Checkin ? activeTabStyle : nonActiveTabStyle} onClick={() => setTabType(TabType.Checkin)}>
              <span css={tabCountTextStyle}>{t('Checkin')}</span>
            </li>
            <li css={tabType === TabType.Stay ? activeTabStyle : nonActiveTabStyle} onClick={() => setTabType(TabType.Stay)}>
              <span css={tabCountTextStyle}>{t('During your stay')}</span>
            </li>
            <li css={tabType === TabType.Checkout ? activeTabStyle : nonActiveTabStyle} onClick={() => setTabType(TabType.Checkout)}>
              <span css={tabCountTextStyle}>{t('Checkout')}</span>
            </li>
          </ul>
          <div css={headerBarRightWrapperStyle}>
            <div>
              <Button
                buttonType={4}
                width={82}
                height={32}
                fontSize={12}
                marginRight={8}
                icon={require('@/static/images/magnifying_glass.svg')}
                onClick={() => history.push({ pathname: '/entire-search' })}
              >
                {t('Search')}
              </Button>
            </div>
            <div css={statusChangeButtonStyle}>
              {tabType !== 'checkout' && (
                <Button buttonType={3} width={122} height={32} fontSize={12} marginRight={8} onClick={() => setIsStatusApproved(true)}>
                  {t('Status change')}
                </Button>
              )}
              {tabType === 'checkout' && (
                <Button buttonType={11} width={122} height={32} fontSize={12} marginRight={8} onClick={() => {}}>
                  {t('Status change')}
                </Button>
              )}
            </div>
            <div css={dropDownButtonStyle}>
              <Button
                buttonType={4}
                width={100}
                height={32}
                fontSize={12}
                paddingLeft={16}
                paddingRight={16}
                icon={require('@/static/images/csv.svg')}
                onClick={() => setIsDropDownOpen(!isDropDownOpen)}
              >
                {t('CSV')}
                <img src={require('@/static/images/arrow_gray.svg')} css={dropDownIcon} />
              </Button>
              {isDropDownOpen && (
                <div css={dropDownStyle}>
                  <ul css={dropDownBodyStyle}>
                    {tabType === TabType.Reservation && (
                      <li css={dropDownContentStyle} onClick={() => setIsCsvUploadModalOpen(true)}>
                        <p css={dropDownTitleStyle}>{t('Booking import')}</p>
                      </li>
                    )}
                    {isCsvUploadModalOpen && (
                      <CsvUploadModal
                        closeCsvUploadModal={closeCsvUploadModal}
                        setIsLoading={setIsLoading}
                        setIsModalOpen={setIsCsvUploadModalOpen}
                        fetchAdminCheckinList={fetchAdminCheckinList}
                        refresh={onClickResearch}
                      />
                    )}
                    {tabType === TabType.Reservation && hasTicketPlugin && (
                      <label>
                        <li css={dropDownContentStyle}>
                          <p css={dropDownTitleStyle}>{t('Ticket import')}</p>
                          <CSVReader
                            inputStyle={{ display: 'none', cursor: 'pointer' }}
                            parserOptions={{
                              header: false,
                              skipEmptyLines: true,
                            }}
                            onFileLoaded={(data, file) => {
                              onFileLoad(data, file)
                            }}
                          />
                        </li>
                      </label>
                    )}
                    <li css={dropDownContentStyle} onClick={downloadAccommodationManagementCsv}>
                      <p css={dropDownTitleStyle}>{t('Booking export')}</p>
                    </li>
                    <li
                      css={dropDownContentStyle}
                      onClick={() => history.push({ pathname: 'csv-upload-history/list', search: location.search })}
                    >
                      <p css={dropDownTitleStyle}>{t('Import history')}</p>
                    </li>
                    <li
                      css={dropDownContentStyle}
                      onClick={() => history.push({ pathname: 'csv-download-history/list', search: location.search })}
                    >
                      <p css={dropDownTitleStyle}>{t('Csv download history')}</p>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div css={listContainerGrayStyle}>
            {tabType === TabType.Reservation && (
              <ReservationList
                reservationList={reservationList}
                page={page}
                setPage={setPage}
                refresh={onClickResearch}
                fetchAdminCheckinList={fetchAdminCheckinList}
                setIsLoading={setIsLoading}
                hasList={hasList}
                listTotal={listTotal}
                limit={perPageNumber}
                pagingIsDisabled={pagingIsDisabled}
                isStatusApproved={isStatusApproved}
                setIsStatusApproved={setIsStatusApproved}
                onClickReserch={onClickResearch}
                onClickClearReserch={onClearSearchParams}
                detailedReserch={detailedResearch}
                setDetailedReserch={setDetailedResearch}
                reservationDetailedReserch={reservationDetailedResearch}
                setReservationDetailedReserch={setReservationDetailedResearch}
              />
            )}
            {tabType === 'checkin' && (
              <CheckinList
                checkinList={checkinList}
                page={page}
                setPage={setPage}
                refresh={onClickResearch}
                hasList={hasList}
                listTotal={listTotal}
                limit={perPageNumber}
                pagingIsDisabled={pagingIsDisabled}
                isStatusApproved={isStatusApproved}
                setIsStatusApproved={setIsStatusApproved}
                onClickReserch={onClickResearch}
                onClickClearReserch={onClearSearchParams}
                detailedReserch={detailedResearch}
                setDetailedReserch={setDetailedResearch}
                setIsLoading={setIsLoading}
              />
            )}
            {tabType === 'stay' && (
              <StayList
                reservationList={reservationList}
                page={page}
                setPage={setPage}
                refresh={onClickResearch}
                hasList={hasList}
                listTotal={listTotal}
                limit={perPageNumber}
                pagingIsDisabled={pagingIsDisabled}
                isStatusApproved={isStatusApproved}
                setIsStatusApproved={setIsStatusApproved}
                onClickReserch={onClickResearch}
                onClickClearReserch={onClearSearchParams}
                detailedReserch={detailedResearch}
                setDetailedReserch={setDetailedResearch}
              />
            )}
            {tabType === 'checkout' && (
              <CheckoutList
                reservationList={reservationList}
                page={page}
                setPage={setPage}
                refresh={onClickResearch}
                listTotal={listTotal}
                limit={perPageNumber}
                pagingIsDisabled={pagingIsDisabled}
                onClickReserch={onClickResearch}
                onClickClearReserch={onClearSearchParams}
                detailedReserch={detailedResearch}
                setDetailedReserch={setDetailedResearch}
              />
            )}
          </div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const tabStyle = css({
  display: 'flex',
  justifyContent: 'left',
  fontSize: 14,
  fontWeight: 'bold',
  padding: 6,
  paddingBottom: 0,
  marginLeft: -6,
  overflow: 'hidden',
  position: 'relative',
  zIndex: 1,
})

const nonActiveTabStyle = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 160,
  height: 45,
  color: '#676767',
  backgroundColor: '#f5f5f5',
  borderRadius: '5px 5px 0 0',
  boxShadow: '0px 0px 6px #0000001A',
})

const activeTabStyle = css(nonActiveTabStyle, {
  color: '#F2A40B',
  backgroundColor: '#FFFFFF',
  borderRadius: '5px 5px 0 0',
  boxShadow: '0px 0px 6px #0000001A',
})

const tabCountTextStyle = css({
  minWidth: 102,
  marginRight: 8,
  textAlign: 'center',
})

const headerBarRightWrapperStyle = css({
  marginLeft: 'auto',
  width: 195,
  position: 'fixed',
  top: 74,
  right: 148,
  display: 'flex',
  zIndex: 10,
})

const statusChangeButtonStyle = css({
  color: '#F2A40B',
})

const dropDownButtonStyle = css({
  position: 'relative',
})

const dropDownIcon = css({
  marginLeft: 8,
})

const dropDownStyle = css({
  width: 153,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 33,
  right: 10,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownBodyStyle = css({})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 14,
  color: '#272727',
})
