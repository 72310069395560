import React, { useState, useContext, useEffect } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import S3 from 'aws-sdk/clients/s3'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

// contexts
import { AccountContext } from '@/contexts/account'

// constants
import {
  wholeContainerStyle,
  mainColumnStyle,
  mainContainerStyle,
  checkinDetailContainerStyle,
  leftContainerStyle,
  rightContainerStyle,
} from '@/constants/layout'

import * as api from '@/apis/aipass'

// libs
import { PluginCode, checkActivePlugin, setHasRepeatPlugin, setHasSalesManagerPlugin } from '@/libs/plugins'
import { useHandleDriversLicenseAuthRedirect } from '@/libs/drivers-license-auth-redirect'
import { env } from '@/libs/env'
import { csvDownload } from '@/libs/csv-download'
import { b64DecodeUnicode } from '@/libs/pdf/pdf-download'
import { convertToSeparatedName } from '@/libs/convertToSeparatedName'
import { useErrorHandler } from '@/hooks/use-error-handler'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { PersonInfo } from '@/components/organisms/customer/person-info'
import { Sales } from '@/components/organisms/customer/sales-manager/sales'
import { AddGuestInfoModal } from '@/components/organisms/accommodation-management/add-guest-info-modal'
import { Memo } from '@/components/organisms/customer/memo'
import { Request } from '@/components/organisms/customer/request'
import { RoomInfoTab } from '@/components/organisms/room-info-tab'
import { CustomerLink } from '@/components/organisms/accommodation-management/customer-link'
import { SearchCustomerLinkButton } from '@/components/organisms/accommodation-management/search-customer-link-button'
import { SuggestCustomer } from '@/components/organisms/accommodation-management/suggest-customer'
import { SearchCustomerLinkModal } from '@/components/organisms/search-customer-link-modal'
import { ReservationList } from '@/components/organisms/customer/_reservation-list/reservation-list'
import { PlanInfoDetail } from '@/components/organisms/customer/_plan-info/plan-info-detail'
import { Review } from '@/components/pages/accommodation-management/checkout/_detail/review'
import { DetailEditInfoForCheckin } from '@/components/organisms/reservation/detail-edit-info-for-checkin'
import { GuestRoom } from '@/components/organisms/customer/guest-room'
import { ReservationDetailMemo } from '@/components/organisms/reservation/reservation-detail-memo'
import { ReceiptPopup } from '@/components/organisms/reservation/receipt-popup'
import { SplitReceiptPopup } from '@/components/organisms/reservation/receipt-split-popup'

// models
import { CustomerType } from '@/models/customer-manager/customer'
import { GuestInput, GuestInputErrors } from '@/models/guest-input'
import { ReservationType } from '@/models/reservation'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { EntireSearchContentType } from '@/models/entire-search'
import { ListSpaceForReservation } from '@/components/organisms/customer/list-space-for-reservation'

const accessKeyId = env('AWS_ACCESS_KEY_ID')
const secretAccessKey = env('AWS_SECRET_ACCESS_KEY')
const bucketName = env('AWS_BUCKET_NAME')
const region = env('AWS_REGION')
const bucket = new S3({
  accessKeyId,
  secretAccessKey,
  region,
})

export const CheckoutDetail: React.FC = () => {
  const guestInputInitialData: GuestInput = {
    isJapanese: 'isJapanese',
    nationality: '',
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    birthDate: '',
    gender: 'F',
    address: '',
    telephone: '',
    email: '',
    occupation: '',
    company: '',
    otherOccupation: '',
    deliveryAgreementStatus: 'unconfirmed',
  }
  const guestInputErrorsInitialData: GuestInputErrors = {
    nationality: null,
    firstName: null,
    lastName: null,
    firstNameKana: null,
    lastNameKana: null,
    birthDate: null,
    address: null,
    telephone: null,
    email: null,
    occupation: null,
    company: null,
    otherOccupation: null,
  }
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { plugins } = useContext<any>(AccountContext)
  const location = useLocation()
  const history = useHistory()
  const params = useParams<any>()
  const query = require('query-string').parse(location.search)
  const { state } = useLocation<{
    entireSearchData: EntireSearchContentType
    fromAccommodationManagement: boolean
  }>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [customer, setCustomer] = useState<any>()
  const [deleteIdentifications, setDeleteIdentifications] = useState<any>([])
  const [isAddGuestInfoModalOpen, setIsAddGuestInfoModalOpen] = useState<boolean>(false)
  const [guestInput, setGuestInput] = useState<GuestInput>(guestInputInitialData)
  const [guestInputErrors, setGuestInputErrors] = useState<GuestInputErrors>(guestInputErrorsInitialData)
  const [driversLicenseFrontImageUrl, setDriversLicenseFrontImageUrl] = useState<string>('')
  const [driversLicenseBackImageUrl, setDriversLicenseBackImageUrl] = useState<string>('')
  const [representativePassportImageUrl, setRepresentativePassportImageUrl] = useState<string>('')
  const [reservations, setReservations] = useState<ReservationType[]>([])
  const [frontSupported, setFrontSupported] = useState(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isEditReservationStatus, setIsEditReservationStatus] = useState<boolean>(false)
  const [isEditGuestRoom, setIsEditGuestRoom] = useState<boolean>(false)
  const [marketSegmentChange, setMarketSegmentChange] = useState<boolean>(false)
  const [selectReservationTab, setSelectReservationTab] = useState(0)
  const [checkinUrl, setCheckinUrl] = useState<any>()
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState<boolean>(false)
  const [isSplitReceiptModalOpen, setIsSplitReceiptModalOpen] = useState<boolean>(false)
  const [isUnlinkReservationCheckin, setIsUnlinkReservationCheckin] = useState<boolean>(false)
  const [isSendingChangeApprovedStatus, setIsSendingChangeApprovedStatus] = useState<boolean>(false)
  const [isSendingBulkChangeApprovedStatus, setIsSendingBulkChangeApprovedStatus] = useState<boolean>(false)
  const [usageDetails, setUsageDetails] = useState<any[]>()
  const [discounts, setDiscounts] = useState<any[]>()

  // Customer management related (no repeat management plugin/plugin)
  // Display of customer search modal (before linking)
  const [isOpenSearchCustomerLinkModal, setIsOpenSearchCustomerLinkModal] = useState<boolean>(false)
  // Linked customer (after linking)
  const [customerlink, setCustomerLink] = useState<CustomerType>()

  // Customer management related (with repeat management plug-in / plug-in)
  // Selected customer ID (before pegging)
  const [hotelCuicinUserId, setHotelCuicinUserId] = useState<string>('')
  // Assisted customer list (before pegging)
  const [customers, setCustomers] = useState<CustomerType[]>([])
  // Editing tags (after binding)
  const [isEditTag, setIsEditTag] = useState<boolean>(false)
  // Tag value (after binding)
  const [tags, setTags] = useState<string[]>([])

  // Switch tab
  const [currentTab, setCurrentTab] = useState<'reservationInfo' | 'request'>(query.detailTabType ?? 'reservationInfo')

  // Whether it is a sales management plugin
  const hasSalesManagerPlugin = setHasSalesManagerPlugin(plugins)

  // Whether it is a repeat management plugin
  const hasRepeatPlugin = setHasRepeatPlugin(plugins)
  const hasSpaceManagementPlugin = checkActivePlugin(plugins, PluginCode.SpaceManagement)

  const [showInput, setShowInput] = useState<boolean>(false)

  const _fetchAdminCheckin = async (fetchType: 'firstFetch' | null = null) => {
    const { id } = params
    if (id === 'redirect') return
    setIsLoading(true)
    await api
      .fetchAdminCheckin(id)
      .then(res => {
        if (!res) return

        // For the first acquisition, set the initial value of the reservation selection tab
        if (fetchType === 'firstFetch') {
          _setInitialSelectReservationTab(res.reservations)
        }
        setCustomer(res.checkin)
        setReservations(res.reservations)
        setCustomerLink(res.customer)
        setTags(res?.customer?.tagInfo.tags.map(tag => tag.id))
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Setting the initial value of the reservation selection tab
  const _setInitialSelectReservationTab = (_reservations: ReservationType[]) => {
    const reservationId = query.reservationId
    const selectReservationIndex = _reservations.findIndex(v => v.reservationId === reservationId)

    // If there is a reservation that matches the reservationId specified in the query, set it to the selected reservation
    if (selectReservationIndex !== -1) {
      setSelectReservationTab(selectReservationIndex)
    }
  }

  // Single status change
  const changeApprovedStatus = async (reservationId, approvedStatus) => {
    try {
      if (isSendingChangeApprovedStatus) {
        return
      } else {
        setIsSendingChangeApprovedStatus(true)
      }

      setIsLoading(true)

      await api.putAdminReservationApprovedStatus({ reservationIds: [reservationId], approvedStatus: approvedStatus })

      _fetchAdminCheckin()
      setIsSendingChangeApprovedStatus(false)
    } catch (error) {
      _fetchAdminCheckin()
      setIsSendingChangeApprovedStatus(false)
      setIsLoading(false)
      errorHandler(error)
    }
  }

  // Bulk change
  const bulkChangeApprovedStatus = approvedStatus => {
    if (isSendingBulkChangeApprovedStatus) {
      return
    } else {
      setIsSendingBulkChangeApprovedStatus(true)
    }
    const formattedCheckedReservationIds: string[] = []

    Object.keys(reservations).forEach(function (key) {
      if (reservations[key] !== '') {
        formattedCheckedReservationIds.push(reservations[key].reservationId)
      }
    })
    updateApprovedStatus(formattedCheckedReservationIds, approvedStatus)
    setIsSendingBulkChangeApprovedStatus(false)
  }

  const updateApprovedStatus = async (reservationIds, approvedStatus) => {
    try {
      setIsLoading(true)
      if (approvedStatus === ApprovedStatusType.Checkin) {
        // change to check in
        await api.putAdminReservationApprovedStatus({ reservationIds: reservationIds, approvedStatus: ApprovedStatusType.Checkin })
        _fetchAdminCheckin()
      } else if (approvedStatus === ApprovedStatusType.Stay) {
        // change during stay
        await api.putAdminReservationApprovedStatus({ reservationIds: reservationIds, approvedStatus: ApprovedStatusType.Stay })
        _fetchAdminCheckin()
      } else if (approvedStatus === ApprovedStatusType.Checkout) {
        // change to checkout
        await api.putAdminReservationApprovedStatus({ reservationIds: reservationIds, approvedStatus: ApprovedStatusType.Checkout })
        _fetchAdminCheckin()
      }
    } catch (error) {
      setIsLoading(false)
      errorHandler(error)
    }
  }

  const onClickDeleteReservationCheckin = async (checkinId, reservationId) => {
    setIsLoading(true)
    let errorMessageExists = false
    await api.deleteAdminReservationCheckin(checkinId, reservationId).then(res => {
      if (res?.errorMessage) {
        errorMessageExists = true
        alert(res.errorMessage)
        return
      }
      _fetchAdminCheckin()
      setSelectReservationTab(0)
      setIsUnlinkReservationCheckin(!isUnlinkReservationCheckin)
    })

    if (errorMessageExists) {
      setIsLoading(false)
      return
    }

    // Clear Advance Receipt Flag
    api.deleteSalesAdvancePayment(reservationId).catch(() => {
      console.log(t('Communication failed'))
    })
  }

  // Acquisition of customer list (customer management/repeat management plug-in available/telephone number assist)
  const _fetchCustomerSearch = async () => {
    const guestTel = customer?.guest.telephone === '0' ? '' : customer?.guest.telephone
    await api
      .fetchCustomerSearch('', '', guestTel, '', 1, 100)
      .then(res => {
        setCustomers(res?.hotelCuicinUsers)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  // Customer linking (customer management/repeat management plug-in available/phone number assist)
  const onSaveCustomerLinkBySuggestion = async (hotelCuicinUserId: string) => {
    setIsLoading(true)
    await api
      .createCustomerLink(customer.id, '', hotelCuicinUserId)
      .then(() => {
        _fetchAdminCheckin()
        setIsOpenSearchCustomerLinkModal(false)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Customer linking (customer management/modal search)
  const onSaveCustomerLink = async () => {
    setIsLoading(true)
    await api
      .createCustomerLink(customer.id, '', hotelCuicinUserId)
      .then(() => {
        _fetchAdminCheckin()
        setHotelCuicinUserId('')
        setIsOpenSearchCustomerLinkModal(false)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Customer unlinking (customer management)
  const deleteCustomerLink = async () => {
    setIsLoading(true)
    await api
      .deleteCustomerLink(customer.id, '')
      .then(() => {
        setCustomerLink(undefined)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onClickAddGuestInfo = (): void => {
    const driversLicenseFront = customer.identification.filter(
      identification => !!identification.isDriversLicense && identification.name === '免許証表面',
    )[0]
    const driversLicenseBack = customer.identification.filter(
      identification => !!identification.isDriversLicense && identification.name === '免許証裏面',
    )[0]
    const representativePassport = customer.identification.filter(
      identification => !!identification.isDriversLicense && identification.name === '代表者パスポート',
    )[0]
    setDriversLicenseImageUrl(driversLicenseFront?.imageKey, 'front')
    setDriversLicenseImageUrl(driversLicenseBack?.imageKey, 'back')
    setDriversLicenseImageUrl(representativePassport?.imageKey, 'passport')
    setIsAddGuestInfoModalOpen(true)
  }

  const setDriversLicenseImageUrl = (imageKey: string, type: 'front' | 'back' | 'passport') => {
    const param = {
      Bucket: bucketName,
      Key: imageKey,
      Expires: 10,
    }
    bucket.getSignedUrl('getObject', param, (err, data) => {
      if (type === 'front') {
        setDriversLicenseFrontImageUrl(data)
      }
      if (type === 'back') {
        setDriversLicenseBackImageUrl(data)
      }
      if (type === 'passport') {
        setRepresentativePassportImageUrl(data)
      }
    })
  }

  const _updateGuestInfo = async () => {
    setIsLoading(true)
    const {
      isJapanese,
      nationality,
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      birthDate,
      gender,
      address,
      telephone,
      email,
      occupation,
      company,
      otherOccupation,
      deliveryAgreementStatus,
    } = guestInput
    // No change below
    const { totalMember, picture, credential } = customer
    const checkin = {
      name: lastName || firstName ? `${lastName} ${firstName}` : '',
      nameKana: lastNameKana || firstNameKana ? `${lastNameKana} ${firstNameKana}` : '',
      birthDate: birthDate ? dayjs(birthDate).format('YYYY-MM-DD') : null,
      nationality: isJapanese === 'isJapanese' ? 'JPN' : nationality,
      gender,
      postalCode: null,
      address,
      telephone: telephone ? telephone : '0',
      email: email ? email : null,
      occupation,
      company,
      otherOccupation,
      totalMember,
      picture,
      credential,
      deliveryAgreementStatus,
    }

    if (customerlink?.id) {
      await api.hotelCuicinUserCopy({ checkinId: customer.checkinId, checkin })
    }
    await api.updateGuestInfo(customer.checkinId, checkin)

    _fetchAdminCheckin()

    setIsAddGuestInfoModalOpen(false)
    setIsLoading(false)
  }

  const _deleteImageS3 = async () => {
    deleteIdentifications.map(deleteImage => {
      const param: S3.Types.GetObjectRequest = {
        // GetObject setting
        Bucket: bucketName,
        Key: deleteImage.key,
      }
      // GetObject execution
      bucket.getObject(param, (err, data) => {
        if (data) {
          const param: S3.Types.DeleteObjectRequest = {
            // DeleteObject setting
            Bucket: bucketName,
            Key: deleteImage.key,
          }

          // DeleteObject execution
          bucket.deleteObject(param, (err, data) => {
            if (data) {
              console.log('deleteObject id:' + deleteImage.id + ', key:' + deleteImage.key + ' success')
            }
            if (err) {
              console.log('deleteObject id:' + deleteImage.id + ', key:' + deleteImage.key + ' failed')
            }
          })
        }
        if (err) {
          console.log('getObject Not Found')
        }
      })
    })
    return deleteIdentifications
  }

  const _clearDeleteIdentifications = async () => {
    deleteIdentifications.splice(0)
    setDeleteIdentifications(deleteIdentifications)
  }

  const _deleteImage = async () => {
    if (deleteIdentifications.length === 0) {
      return
    }
    setIsLoading(true)

    await _deleteImageS3()
      .then((res: any) => {
        api.deleteCheckinIdentification(res)
      })
      .finally(() => {
        _clearDeleteIdentifications()
      })

    setIsLoading(false)
  }

  const onClickDeleteImage = (e): void => {
    const imageId = e.target.dataset.imageid ?? null
    const id = e.target.dataset.identificationid ?? null
    const key = e.target.dataset.imagekey ?? null

    // delete button hidden
    e.target.style.visibility = 'hidden'

    // delete image display none
    const image = document.getElementById(imageId)
    if (image) {
      image.style.display = 'none'
    }
    if (id && key) {
      deleteIdentifications.push({ id: id, key: key })
      setDeleteIdentifications(deleteIdentifications)
    }
  }

  const onClickAddGuestInfoModalCancel = (): void => {
    setIsAddGuestInfoModalOpen(false)
    setGuestInputErrors(guestInputErrorsInitialData)
    _fetchAdminCheckin()
    _clearDeleteIdentifications()
  }

  const onClickAddGuestInfoModalSave = (): void => {
    // Validation for phone number (half-width numbers only)
    if (/[^0-9]/g.test(guestInput.telephone)) {
      window.alert(t('Please enter the phone number in half-width numbers'))
      return
    }
    _updateGuestInfo()
    _deleteImage()
  }

  const onSelect = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    setGuestInput(guestInput => ({ ...guestInput, [name]: value }))
    if (name === 'occupation' && value === 'その他') {
      setShowInput(true)
      return
    } else if (name === 'occupation' && value !== 'その他') {
      setShowInput(false)
      return
    }

    if (value !== '') {
      setGuestInputErrors(guestInputErrors => ({ ...guestInputErrors, [name]: null }))
      return
    }
  }

  const onChangeGuestInfo = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    setGuestInput(guestInput => ({ ...guestInput, [name]: value }))

    if (value !== '') {
      setGuestInputErrors(guestInputErrors => ({ ...guestInputErrors, [name]: null }))
      return
    }
  }

  const onChangeBirthDate = (value: string): void => {
    setGuestInput(guestInput => ({ ...guestInput, birthDate: value }))

    if (value !== '') {
      setGuestInputErrors(guestInputErrors => ({ ...guestInputErrors, birthDate: null }))
    }
  }

  // front support
  const changeFrontSupported = async () => {
    try {
      setFrontSupported(!frontSupported)
      const data = {
        checkinId: [customer.id],
        frontSupported: Number(!frontSupported),
      }
      await api.updateAdminCheckin(data)
    } catch (error) {
      setFrontSupported(frontSupported)
      errorHandler(error)
    }
  }

  const onUpdateFetchAdminCheckin = () => {
    _fetchAdminCheckin()
  }

  const _addReservationsTab = () => {
    const addedReservations = [...reservations]
    // reservationCheckinLink=true shows the click part of the reservation and the link instead of the normal reservation information
    // Temporarily ignore all properties other than reservationCheckinLink because new reservation data is created
    // @ts-ignore
    addedReservations.push({ reservationCheckinLink: true })
    setReservations(addedReservations)
    setSelectReservationTab(addedReservations.length - 1)
  }

  const _activeTabStyle = approvedStatus => {
    if (approvedStatus === ApprovedStatusType.Checkin) return tabCheckinActiveStyle
    if (approvedStatus === ApprovedStatusType.Stay) return tabStayActiveStyle
    if (approvedStatus === ApprovedStatusType.Checkout) return tabCheckoutActiveStyle
    if (approvedStatus === ApprovedStatusType.Reservation) return tabReservationActiveStyle

    return tabActiveStyle
  }

  const _tabNonActiveStyle = approvedStatus => {
    if (approvedStatus === ApprovedStatusType.Checkin) return tabCheckinNonActiveStyle
    if (approvedStatus === ApprovedStatusType.Stay) return tabStayNonActiveStyle
    if (approvedStatus === ApprovedStatusType.Checkout) return tabCheckoutNonActiveStyle
    if (approvedStatus === ApprovedStatusType.Reservation) return tabReservationNonActiveStyle

    return tabNonActiveStyle
  }

  const _tabTextStyle = approvedStatus => {
    if (approvedStatus === ApprovedStatusType.Checkin) return tabTextStyle
    if (approvedStatus === ApprovedStatusType.Stay) return tabTextStyle
    if (approvedStatus === ApprovedStatusType.Checkout) return tabTextStyle
    if (approvedStatus === ApprovedStatusType.Reservation) return tabTextStyle

    return tabTextStyle
  }

  // When returning to the list, pass a flag so that the date narrowing is not removed
  const goBack = () => {
    if (state?.fromAccommodationManagement) {
      history.push({ pathname: '/accommodation-management', search: checkinUrl, state: { ...state, isGoBack: true } })
    } else {
      history.goBack()
    }
  }

  const onClickCancelTag = () => {
    setIsEditTag(false)
    setTags(customerlink?.tagInfo?.tags?.map(tagInfo => tagInfo.id) || [])
  }

  const onCloseReceiptModal = () => {
    setIsReceiptModalOpen(false)
  }

  const onCloseSplitReceiptModal = () => {
    setIsSplitReceiptModalOpen(false)
  }

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.addEventListener('popstate', goBack)

    return () => window.removeEventListener('popstate', goBack)
  }, [checkinUrl, state])

  useEffect(() => {
    if (customer) {
      const [customerFirstName, customerLastName] = convertToSeparatedName({ name: customer?.name })
      const [customerFirstNameKana, customerLastNameKana] = convertToSeparatedName({ name: customer?.nameKana })

      setGuestInput({
        isJapanese: customer.isJapanese ? customer.isJapanese : '',
        nationality: customer.nationality,
        lastName: customerLastName || '',
        firstName: customerFirstName || '',
        lastNameKana: customerLastNameKana || '',
        firstNameKana: customerFirstNameKana || '',
        birthDate: customer.birthDate ? customer.birthDate : '',
        gender: customer.gender,
        address: customer.address ? customer.address : '',
        telephone: customer.telephone ? customer.telephone : '',
        email: customer.email ? customer.email : '',
        occupation: customer.occupation ? customer.occupation : '',
        company: customer.company ? customer.company : '',
        otherOccupation: customer.otherOccupation ? customer.otherOccupation : '',
        deliveryAgreementStatus: customer.deliveryAgreementStatus ?? 'unconfirmed',
      })
      api.updateAdminCheckinList({ checkinId: customer.id, mode: 'checkinRead' })
      customer?.guest.telephone !== '0' && _fetchCustomerSearch()
    }
    if (customer?.occupation === 'その他') {
      setShowInput(true)
    }
  }, [customer])

  useEffect(() => {
    _fetchAdminCheckin('firstFetch')
  }, [params.id])

  useEffect(() => {
    if (reservations) {
      setIsLoading(true)
      _fetchAdminCheckin()
      setIsLoading(false)
    }
  }, [isEdit, isEditGuestRoom])

  useEffect(() => {
    if (isEditReservationStatus) {
      _fetchAdminCheckin()
    }
  }, [isEditReservationStatus])

  useEffect(() => {
    if (params) {
      if (location.search.includes('tabType=checkout')) {
        setCheckinUrl(location.search)
        return
      }
      if (location.pathname.includes('checkout') && !location.search.includes('tabType=')) {
        setCheckinUrl(location.search + '&tabType=checkout')
        return
      }
      // When transitioning from general search to accommodation details
      setCheckinUrl(location.search)
    }
  }, [params])

  // Redirect processing from license display authentication
  useHandleDriversLicenseAuthRedirect()
  const currentTabReservationId = reservations?.[selectReservationTab]?.reservationId

  useEffect(() => {
    if (reservations && marketSegmentChange) {
      _fetchAdminCheckin()
    }
  }, [marketSegmentChange])

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header isBack title={t('Accommodation management')} goBack={goBack} />
        <div className="mainContainer" css={mainContainerStyle}>
          {!!customer && (
            <div>
              <div css={checkinDetailContainerStyle}>
                {/* Guest information */}
                <div css={leftContainerStyle}>
                  <PersonInfo checkin={customer} onClickAddGuestInfo={onClickAddGuestInfo} />
                </div>
                {/* Related customers */}
                <div
                  css={[
                    rightContainerStyle,
                    {
                      height: 'auto',
                      minHeight: 'auto',
                      boxShadow: 'none',
                      borderRadius: 0,
                      flex: '1 1 auto',
                      display: 'flex',
                      flexDirection: 'column',
                    },
                  ]}
                >
                  {/* No repeat management plugin */}
                  {!hasRepeatPlugin &&
                    (customerlink ? (
                      <CustomerLink
                        history={history}
                        customer={customerlink}
                        deleteCustomerLink={deleteCustomerLink}
                        isEdit={isEditTag}
                        currentTags={tags}
                        onCancel={onClickCancelTag}
                      />
                    ) : (
                      <SearchCustomerLinkButton setIsOpenModal={setIsOpenSearchCustomerLinkModal} />
                    ))}
                  {/* Repeat management plugin available */}
                  {hasRepeatPlugin &&
                    (customerlink ? (
                      <CustomerLink
                        history={history}
                        customer={customerlink}
                        deleteCustomerLink={deleteCustomerLink}
                        isEdit={isEditTag}
                        currentTags={tags}
                        onCancel={onClickCancelTag}
                      />
                    ) : (
                      <SuggestCustomer
                        customers={customers}
                        setIsOpenModal={setIsOpenSearchCustomerLinkModal}
                        onSaveCustomerLink={onSaveCustomerLinkBySuggestion}
                      />
                    ))}
                </div>
              </div>

              <div css={checkinDetailBottomContainerStyle}>
                <div css={roomInfoWrapperStyle}>
                  <RoomInfoTab currentTab={currentTab} setCurrentTab={setCurrentTab} />

                  {/* Accommodation information tab */}
                  {currentTab === 'reservationInfo' && (
                    <div css={roomInfoContainerStyle}>
                      {/* Reservation list */}
                      <div css={reservationListWrapper}>
                        <ReservationList
                          reservations={reservations}
                          changeApprovedStatus={changeApprovedStatus}
                          bulkChangeApprovedStatus={bulkChangeApprovedStatus}
                          onClickDeleteReservationCheckin={onClickDeleteReservationCheckin}
                        />
                      </div>
                      <div css={individualReservationStyle}>
                        <div css={topSection}>
                          <div css={reservationInfoContainerStyle}>
                            {reservations && reservations.length > 0 && (
                              <ul css={tabStyle}>
                                {reservations.map((_reservation, index) => {
                                  return (
                                    <li
                                      key={index}
                                      css={
                                        index === selectReservationTab
                                          ? _activeTabStyle(_reservation.approvedStatus)
                                          : _tabNonActiveStyle(_reservation.approvedStatus)
                                      }
                                      onClick={() => {
                                        setSelectReservationTab(index)
                                      }}
                                    >
                                      <p css={_tabTextStyle(_reservation.approvedStatus)}>
                                        {reservations && reservations[index].accommodationId
                                          ? reservations[index].accommodationId
                                          : t('Reserve')}
                                      </p>
                                    </li>
                                  )
                                })}
                                <li className="plusButton" onClick={() => _addReservationsTab()}>
                                  <p css={plusButtonStyle}>
                                    <img alt={t('Link')} src={require('@/static/images/link_add_normal.svg')} />
                                  </p>
                                </li>
                              </ul>
                            )}
                            {!reservations?.length && (
                              <ul css={tabStyle}>
                                <li css={tabActiveStyle}>
                                  <p css={tabTextStyle}>{t('Reserve')}</p>
                                </li>
                                <li className="plusButton" onClick={() => _addReservationsTab()}>
                                  <p css={plusButtonStyle}>
                                    <img alt={t('Link')} src={require('@/static/images/link_add_normal.svg')} />
                                  </p>
                                </li>
                              </ul>
                            )}
                            {reservations &&
                            reservations[selectReservationTab]?.reservationId &&
                            reservations[selectReservationTab].approvedStatus === ApprovedStatusType.Reservation ? (
                              <>
                                <div css={approvedStatusReservationStyle}>{t('Reserve')}</div>
                              </>
                            ) : (
                              ''
                            )}
                            {reservations &&
                            reservations[selectReservationTab]?.reservationId &&
                            reservations[selectReservationTab].approvedStatus === ApprovedStatusType.Checkin ? (
                              <>
                                <div css={approvedStatusCheckinWrapperStyle}>
                                  <div css={approvedStatusCheckinStyle}>{t('Checkin')}</div>
                                  <div css={approvedStatusDateStyle}>
                                    {reservations[selectReservationTab]?.approvedStatusUpdatedAt
                                      ? `${t('Status update')}: ` +
                                        dayjs(reservations[selectReservationTab].approvedStatusUpdatedAt).format(t('YYYY-MM-DD HH:mm'))
                                      : `${t('Status update')}: ` + '-'}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                            {reservations &&
                            reservations[selectReservationTab]?.reservationId &&
                            reservations[selectReservationTab].approvedStatus === ApprovedStatusType.Stay ? (
                              <>
                                <div css={approvedStatusStayWrapperStyle}>
                                  <div css={approvedStatusStayLeftContentStyle}>
                                    <div css={approvedStatusStayStyle}>{t('During your stay')}</div>
                                    <div css={hasFrontSupportedButtonStyle}>
                                      <label>
                                        <input
                                          alt="checkbox"
                                          type="image"
                                          name="frontSupported"
                                          src={require(`@/static/images/check-box${frontSupported ? '' : '_off'}.svg`)}
                                          onClick={() => changeFrontSupported()}
                                        />
                                        <p>{t('Front desk available')}</p>
                                      </label>
                                    </div>
                                  </div>
                                  <div css={approvedStatusDateStyle}>
                                    {reservations[selectReservationTab]?.approvedStatusUpdatedAt
                                      ? `${t('Status update')}: ` +
                                        dayjs(reservations[selectReservationTab].approvedStatusUpdatedAt).format(t('YYYY-MM-DD HH:mm'))
                                      : `${t('Status update')}: ` + '-'}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                            {reservations &&
                            reservations[selectReservationTab]?.reservationId &&
                            reservations[selectReservationTab].approvedStatus === ApprovedStatusType.Checkout ? (
                              <>
                                <div css={approvedStatusCheckinWrapperStyle}>
                                  <div css={approvedStatusCheckoutStyle}>{t('Checkout')}</div>
                                  <div css={approvedStatusDateStyle}>
                                    {reservations[selectReservationTab]?.approvedStatusUpdatedAt
                                      ? `${t('Status update')}: ` +
                                        dayjs(reservations[selectReservationTab].approvedStatusUpdatedAt).format(t('YYYY-MM-DD HH:mm'))
                                      : `${t('Status update')}: ` + '-'}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )}

                            <div css={middleContainerStyle}>
                              {/* Booking details */}
                              <div css={reservationPlanContainerStyle}>
                                <PlanInfoDetail
                                  reservation={reservations[selectReservationTab]}
                                  onChangeReservation={onUpdateFetchAdminCheckin}
                                  // originalSetIsLoading={setIsLoading}
                                  isEdit={isEdit}
                                  setIsEdit={setIsEdit}
                                  setIsEditReservationStatus={setIsEditReservationStatus}
                                  setMarketSegmentChange={setMarketSegmentChange}
                                />
                              </div>
                              {isEdit && (
                                <DetailEditInfoForCheckin
                                  reservation={reservations[selectReservationTab]}
                                  isEdit={isEdit}
                                  setIsEdit={setIsEdit}
                                />
                              )}

                              {/* Reservation details memo */}
                              {reservations && reservations[selectReservationTab]?.reservationId && (
                                <div css={reservationDetailMemoContainerStyle}>
                                  <ReservationDetailMemo
                                    reservationId={reservations[selectReservationTab]?.reservationId}
                                    checkoutDate={reservations[selectReservationTab]?.checkoutDate}
                                    customer={customerlink}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* guest room */}
                        {reservations && reservations[selectReservationTab]?.reservationId && (
                          <div css={guestRoomListStyle}>
                            <GuestRoom
                              reservation={reservations[selectReservationTab]}
                              setIsLoading={setIsLoading}
                              isEditGuestRoom={isEditGuestRoom}
                              setIsEditGuestRoom={setIsEditGuestRoom}
                            />
                          </div>
                        )}
                        {hasSpaceManagementPlugin && (
                          <ListSpaceForReservation
                            reservationId={currentTabReservationId}
                            customStyle={css({ marginTop: 0, marginBottom: 16 })}
                          />
                        )}
                        {/* evaluation */}
                        {reservations && reservations[selectReservationTab]?.reservationId && (
                          <div css={reviewListStyle}>
                            <Review reservation={reservations[selectReservationTab]} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* request tab */}
                  {currentTab === 'request' && (
                    <div css={reservationRequestWrapperStyle}>
                      <Request checkinId={params.id} currentTab={currentTab} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {isOpenSearchCustomerLinkModal && (
            <SearchCustomerLinkModal
              hotelCuicinUserId={hotelCuicinUserId}
              setHotelCuicinUserId={setHotelCuicinUserId}
              setIsOpenSearchCustomerLinkModal={setIsOpenSearchCustomerLinkModal}
              onSaveCustomerLink={onSaveCustomerLink}
            />
          )}
          {isAddGuestInfoModalOpen && (
            <AddGuestInfoModal
              guestInput={guestInput}
              showInput={showInput}
              guestInputErrors={guestInputErrors}
              driversLicenseFrontImageUrl={driversLicenseFrontImageUrl}
              driversLicenseBackImageUrl={driversLicenseBackImageUrl}
              representativePassportImageUrl={representativePassportImageUrl}
              onChangeGuestInfo={onChangeGuestInfo}
              onSelect={onSelect}
              onChangeBirthDate={onChangeBirthDate}
              onClickModalCancel={onClickAddGuestInfoModalCancel}
              onClickModalSave={onClickAddGuestInfoModalSave}
              onClickDeleteImage={onClickDeleteImage}
              customer={customer}
            />
          )}
          {/* Check-in Details_Usage Details */}
          {hasSalesManagerPlugin && (
            <Sales
              currentTabReservationId={currentTabReservationId}
              customerName={customer?.guest?.name}
              customerLink={customerlink}
              customerId={customer?.id}
              checkinId={params.id}
              reservations={reservations}
              isEditReservationStatus={isEditReservationStatus}
              setIsReceiptModalOpen={setIsReceiptModalOpen}
              setIsSplitReceiptModalOpen={setIsSplitReceiptModalOpen}
              isUnlinkReservationCheckin={isUnlinkReservationCheckin}
              onChangeDiscounts={v => setDiscounts(v)}
              onChangeUsageDetails={v => setUsageDetails(v)}
              onUpdateSales={onUpdateFetchAdminCheckin}
            />
          )}
          {isReceiptModalOpen && <ReceiptPopup isReceiptModalOpen={isReceiptModalOpen} onClose={onCloseReceiptModal} customer={customer} />}
          {isSplitReceiptModalOpen && (
            <SplitReceiptPopup
              usageDetails={usageDetails}
              discounts={discounts}
              isSplitReceiptModalOpen={isSplitReceiptModalOpen}
              onClose={onCloseSplitReceiptModal}
              checkinId={params.id}
              customer={customer}
            />
          )}
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const checkinDetailBottomContainerStyle = css(checkinDetailContainerStyle, {
  paddingBottom: 24,
  boxShadow: 'none',
  backgroundColor: 'initial',
  overflow: 'initial',
})

// Accommodation information
const roomInfoWrapperStyle = css({
  width: '100%',
  marginTop: 20,
})

const roomInfoContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRadius: '0 5px 5px 5px',
  //   overflow: 'hidden',
  boxShadow: '0px 0px 6px #0000001A',
})

const middleContainerStyle = css({
  display: 'flex',
  width: '100%',
  padding: '0 32px',
})

// Reservation list
const reservationListWrapper = css({
  backgroundColor: '#fff',
  padding: 32,
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: '0 5px 0 0',
})

const individualReservationStyle = css({
  backgroundColor: '#fff',
})

const topSection = css({
  width: '100%',
})

// Booking details
const reservationInfoContainerStyle = css({
  width: '100%',
  minWidth: 695,
  height: 'auto',
  backgroundColor: '#FFF',
  paddingBottom: 17,
})

// Tab
const tabStyle = css({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  backgroundColor: '#f5f5f5',
  height: 36,
  fontSize: 12,
  borderBottom: '1px solid #f2f2f2',
  cursor: 'pointer',
  fontWeight: 'bold',
})

const tabActiveStyle = css({
  width: 108,
  height: 34,
  backgroundColor: '#fff',
  position: 'relative',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
  textAlign: 'center',
  paddingTop: 10,
  borderTop: '3px solid #676767',
  '&::after': {
    position: 'absolute',
    left: 0,
    height: 6,
    bottom: -2,
    width: '100%',
    content: '""',
    backgroundColor: '#fff',
    zIndex: 3,
  },
})

const tabReservationActiveStyle = css(tabActiveStyle, {
  color: '#F2A40B',
  borderTop: '3px solid #F2A40B',
})

const tabCheckinActiveStyle = css(tabActiveStyle, {
  color: '#F47110',
  borderTop: '3px solid #F47110',
})

const tabStayActiveStyle = css(tabActiveStyle, {
  color: '#3E85CC',
  borderTop: '3px solid #3E85CC',
})

const tabCheckoutActiveStyle = css(tabActiveStyle, {
  color: '#7DC931',
  borderTop: '3px solid #7DC931',
})

const tabNonActiveStyle = css({
  width: 108,
  height: 34,
  backgroundColor: '#F5F5F5',
  position: 'relative',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
  textAlign: 'center',
  paddingTop: 10,
  borderTop: '3px solid #676767',
  '&::after': {
    position: 'absolute',
    left: 0,
    height: 6,
    width: '100%',
    content: '""',
    backgroundColor: '#F5F5F5',
    zIndex: 3,
  },
})

const tabReservationNonActiveStyle = css(tabNonActiveStyle, {
  color: '#F2A40B',
  borderTop: '3px solid #F2A40B',
})

const tabCheckinNonActiveStyle = css(tabNonActiveStyle, {
  color: '#F47110',
  borderTop: '3px solid #F47110',
})

const tabStayNonActiveStyle = css(tabNonActiveStyle, {
  color: '#3E85CC',
  borderTop: '3px solid #3E85CC',
})

const tabCheckoutNonActiveStyle = css(tabNonActiveStyle, {
  color: '#7DC931',
  borderTop: '3px solid #7DC931',
})

const tabTextStyle = css({
  margin: '0 auto',
})

const plusButtonStyle = css({
  marginLeft: 21,
})

const approvedStatusReservationStyle = css({
  width: 116,
  fontSize: 12,
  fontWeight: 'bold',
  color: '#fff',
  borderRadius: 5,
  textAlign: 'center',
  padding: '6px 45px',
  backgroundColor: '#F2A40B',
  margin: '24px 32px',
})

const approvedStatusCheckinWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const approvedStatusCheckinStyle = css({
  width: 116,
  fontSize: 12,
  fontWeight: 'bold',
  color: '#fff',
  borderRadius: 5,
  textAlign: 'center',
  padding: '6px 18px',
  backgroundColor: '#F47110',
  margin: '24px 32px',
})

const approvedStatusDateStyle = css({
  fontSize: 12,
  color: '#272727',
  margin: '34px 32px 25px',
})

const approvedStatusStayWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const approvedStatusStayLeftContentStyle = css({
  display: 'flex',
})

const approvedStatusStayStyle = css({
  width: 116,
  fontSize: 12,
  fontWeight: 'bold',
  color: '#fff',
  borderRadius: 5,
  textAlign: 'center',
  padding: '6px 36px',
  backgroundColor: '#3E85CC',
  marginBottom: 16,
  margin: '24px 24px 24px 32px',
})

const approvedStatusCheckoutStyle = css({
  width: 116,
  fontSize: 12,
  fontWeight: 'bold',
  color: '#fff',
  borderRadius: 5,
  textAlign: 'center',
  padding: '6px 10px',
  backgroundColor: '#7DC931',
  marginBottom: 16,
  margin: '24px 32px',
})

const reservationPlanContainerStyle = css({
  width: '70%',
  minWidth: 695,
  borderRadius: 10,
  //   overflow: 'hidden',
  height: 'fit-content',
})

const hasFrontSupportedButtonStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 35,
  p: {
    fontSize: 12,
    color: '#676767',
    margin: '-15px 0 0 30px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
})

// Reservation details memo
const reservationDetailMemoContainerStyle = css({
  marginLeft: 16,
  width: '30%',
  minWidth: 316,
})

// guest room
const guestRoomListStyle = css({
  padding: '0 34px 19px 32px',
})

// evaluation
const reviewListStyle = css({
  padding: '0 32px 32px 32px',
})

// Check-in memo
const checkinMemoWrapperStyle = css({
  background: '#FFFFFF',
  minHeight: 479,
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
})

// request
const reservationRequestWrapperStyle = css({
  background: '#FFFFFF',
  minHeight: 332,
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  padding: 32,
})
