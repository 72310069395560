import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'

// components
import { SelfCheckinHeader } from '@/components/molecules'
import { NoneReservationTab as SelfCheckinNonReservationTab } from '@/components/pages/self-checkin/identity-verify/none-reservation/none-reservation-tab'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { Loading } from '@/components/molecules/self-checkin/loading'

// models
import { SelfCheckinBasicInfoPluginType } from '@/models/self-checkin/results'
import { setHasCustomCheckinPlugin } from '@/libs/plugins'

type ContainerProps = {
  title: string
}
export const NoneReservationUploadPassport: React.FC<ContainerProps> = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { search, state } = useLocation<{
    plugin: string
    basicInfo: SelfCheckinBasicInfoPluginType
    representativePassportImage: string
    companionImageArray: {
      id: number
      companionPassportImage: string
    }[]
    companionNumber: number
    hotelId: string | null
    paymentInfo: any
    selectedReservations: any
    singleSelectedResesvation: any
    noneSelectReservation: any
  }>()
  const lang = i18n.language

  const [representativePassportImage, setRepresentativePassportImage] = useState<string>()
  const [companionImageArray, setCompanionImageArray] = useState<
    {
      id: number
      companionPassportImage: string
    }[]
  >([])
  const [companionNumber, setCompanionNumber] = useState<number>(1)
  const [buttonName] = useState<'submit' | 'next' | 'edit' | 'checkin'>('next')
  const [isLoading] = useState<boolean>(false)
  const selectNumbers: number[] = []
  for (let i = 0; i <= 50; i++) {
    selectNumbers.push(i)
  }

  console.log(state)

  useEffect(() => {
    if (!state) return

    if (state.companionNumber || state.companionNumber === 0) {
      setCompanionNumber(state.companionNumber)
    }

    if (state.companionImageArray) {
      setCompanionImageArray(state.companionImageArray)
    }

    if (state.representativePassportImage) {
      setRepresentativePassportImage(state.representativePassportImage)
    }
  }, [state])

  const uploadImage = (imageType: 'representativePassport' | 'companionPassport', index): void => {
    history.push({
      pathname: '/self-checkin/identity-verify/none-select-resvervation/camera',
      search,
      state: { ...state, imageType, from: 'upload-passport', companionImageArray, companionNumber: companionNumber, index },
    })
  }

  const changeNumberOfPeople = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setCompanionNumber(Number(value))
  }

  const isDisabled = (): boolean => {
    if (representativePassportImage && companionNumber === 0) {
      return false
    }

    if (representativePassportImage && companionImageArray.length) {
      for (let index = 0; index < companionNumber; index++) {
        const image = companionImageArray.find(img => img.id === index)
        if (image) continue

        return true
      }

      return false
    }

    return true
  }

  const goBack = () => {
    if (state.noneSelectReservation && !setHasCustomCheckinPlugin(state.plugin)) {
      history.push({
        pathname: '/self-checkin/accommodation-info',
        search,
        state: {
          ...state,
          from: 'none-reservation/upload-license',
          companionNumber,
        },
      })
    } else if (state.noneSelectReservation) {
      history.push({
        pathname: '/self-checkin/checkin-customize',
        search,
        state: {
          ...state,
          from: 'none-reservation/upload-license',
          companionNumber,
        },
      })
    }
  }

  const submit = () => {
    history.push({
      pathname: '/self-checkin/none-select-resvervation/confirm',
      search,
      state: {
        ...state,
        from: 'upload-passport',
        companionNumber,
      },
    })
  }

  if (!state?.hotelId) {
    history.replace({ pathname: '/dashboard' })
    return <></>
  }

  return (
    <>
      <Loading isLoading={isLoading} />
      <div css={containerStyle}>
        <SelfCheckinHeader goToPreviousPage={goBack} title={t('Identification')} />
        <div css={settingMainContainerStyle}>
          <div css={mainStyle}>
            <div css={settingHeaderContainerStyle}>
              <SelfCheckinNonReservationTab currentTab="passport" />
            </div>
            <div css={settingListContainerStyle}>
              <div css={descriptionStyle}>{t('Please upload your passport')}</div>
              <div css={uploadWrapperStyle}>
                <div css={imageTitleStyle}>{t('Representative')}</div>
                <div css={uploadItemStyle} onClick={() => uploadImage('representativePassport', null)}>
                  <div css={representativePassportImage ? withBackgroundStyle : undefined}>
                    <img
                      css={identificationImageStyle}
                      src={representativePassportImage ? representativePassportImage : require('@/static/images/passport.svg')}
                      alt={t('Check-in with Identification')}
                    />
                  </div>
                  <div className={representativePassportImage ? 'uploaded' : ''} css={uploadItemTextStyle}>
                    <div>
                      <img
                        src={
                          representativePassportImage
                            ? require('@/static/images/camera_icon_white.svg')
                            : require('@/static/images/camera_icon_orange.svg')
                        }
                        alt={t('Take a Photo')}
                      />
                      <p>{t('Take a Photo')}</p>
                    </div>
                  </div>
                </div>
                <div css={imageTitleStyle}>{t('Companion')}</div>
                <div css={numberOfPeopleWrapperStyle}>
                  <select name="companion" css={largeSelectBoxStyle} value={companionNumber} onChange={e => changeNumberOfPeople(e)}>
                    {selectNumbers.map((i, index) => (
                      <option value={i} key={index}>
                        {i}
                      </option>
                    ))}
                  </select>
                  {!(lang === 'en' && companionNumber > 1) && <div css={numberOfPeopleStyle}>{t('Person')}</div>}
                  {lang === 'en' && companionNumber > 1 && <div css={numberOfPeopleStyle}>{t('Persons')}</div>}
                </div>
                <div css={companionNumber % 3 === 2 ? leftCompanionPassportImageWrapperStyle : companionPassportImageWrapperStyle}>
                  {new Array(companionNumber).fill(null).map((_, i) => {
                    const image = companionImageArray.find(img => img.id == i)
                    return (
                      <div key={i} style={{ marginBottom: '16px' }}>
                        <div css={uploadItemStyle} onClick={() => uploadImage('companionPassport', i)} key={`companion${i}`}>
                          <div css={companionImageArray.length && image ? withBackgroundStyle : undefined}>
                            <img
                              css={identificationImageStyle}
                              src={
                                companionImageArray.length && image ? image.companionPassportImage : require('@/static/images/passport.svg')
                              }
                              alt={t('Check-in with Identification')}
                            />
                          </div>
                          <div className={companionImageArray.length && image ? 'uploaded' : ''} css={uploadItemTextStyle}>
                            <div>
                              <img
                                src={
                                  companionImageArray.length && image
                                    ? require('@/static/images/camera_icon_white.svg')
                                    : require('@/static/images/camera_icon_orange.svg')
                                }
                                alt={t('Take a Photo')}
                              />
                              <p>{t('Take a Photo')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={submit} isNext={buttonName} />
      </div>
    </>
  )
}

const descriptionStyle = css({
  paddingTop: 50,
  color: '#272727',
  fontWeight: 'bold',
  fontSize: 20,
  lineHeight: '24px',
  letterSpacing: '3.4px',
  textAlign: 'center',
  '@media(max-width: 1080px)': {
    fontSize: 20,
    lineHeight: '35px',
    letterSpacing: '3px',
  },
})

const imageTitleStyle = css({
  fontSize: 18,
  marginBottom: 16,
  marginTop: 40,
  fontWeight: 'bold',
  color: '#272727',
  '@media(max-width: 1080px)': {
    fontSize: 18,
    marginBottom: 14,
    marginTop: 38,
  },
})

const uploadWrapperStyle = css({
  margin: '0 auto',
  padding: '0 60px',
  display: 'block',
})

const uploadItemStyle = css({
  width: 246,
  height: 172,
  cursor: 'pointer',
  color: '#f2a40b',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: '2.7px',
  lineHeight: '27px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '3px',
  '@media(max-width: 880px)': {
    width: 224,
    margin: '0 4px',
  },
  '@media(max-width: 800px)': {
    width: 210,
    margin: '0 3px',
  },
})

const withBackgroundStyle = css({
  position: 'relative',
  '&::after': {
    content: '""',
    height: 172,
    width: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    top: 0,
    left: 0,
    position: 'absolute',
    borderRadius: 10,
  },
  '@media(max-width: 1080px)': {
    '&::after': {
      height: 172,
    },
  },
})

const identificationImageStyle = css({
  width: 246,
  height: 172,
  borderRadius: 8,
  '@media(max-width: 880px)': {
    width: 224,
  },
  '@media(max-width: 800px)': {
    width: 210,
  },
})

const uploadItemTextStyle = css({
  width: 246,
  height: 69,
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  paddingBottom: 14,
  p: {
    height: 20,
    fontSize: 14,
  },
  img: {
    width: 21,
    height: 18,
  },
  '&.uploaded': {
    color: '#fff',
  },
})

const selectBoxStyle = css({
  '-webkit-appearance': 'none',
  fontSize: 22,
  border: '1px solid #CCCCCC',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 20,
  display: 'block',
  paddingLeft: 40,
  paddingRight: 5,
  marginRight: 20,
  backgroundColor: '#fff',
  color: '#272727',
})

const largeSelectBoxStyle = css(selectBoxStyle, {
  width: '25%',
  height: 60,
  borderRadius: 40,
  backgroundPosition: '86% center',
})

const containerStyle = css({
  width: '100%',
  minHeight: '100vh',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
  },
})

const settingMainContainerStyle = css({
  height: 'calc(100% - 180px)',
  margin: '0 auto',
  maxWidth: 873,
})

const settingListContainerStyle = css({
  borderRadius: '0 5px 5px 5px',
  background: '#FFF',
  paddingBottom: 50,
  boxShadow: '0px 0px 6px #0000001a',
})

const settingHeaderContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const mainStyle = css({
  padding: '120px 0',
  '@media(max-width: 1080px)': {
    padding: '96px 0',
  },
})

const companionPassportImageWrapperStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'top',
  justifyContent: 'start',
})

const leftCompanionPassportImageWrapperStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'top',
  justifyContent: 'start',
  '&::after': {
    content: '"" !important',
    display: 'block',
    width: 282,
    height: 197,
    left: 0,
    top: 0,
  },
  '@media(max-width: 1080px)': {
    '&::after': {
      content: '"" !important',
      display: 'block',
      width: 246,
      height: 172,
    },
  },
})

const numberOfPeopleWrapperStyle = css({
  display: 'flex',
  marginBottom: 40,
  alignItems: 'center',
})

const numberOfPeopleStyle = css({
  fontSize: 22,
  fontWeight: 'bold',
  color: '#272727',
})
