import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import 'dayjs/locale/ja'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle } from '@/constants/layout'

// components
import { Button } from '@/components/atoms/button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { LoadingFull } from '@/components/molecules/loading-full'
import { EntireSearchPanel } from '@/components/pages/accommodation-management/entire-search/entire-search-panel'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { List } from './result/list'
import { EntireSearchContentType, EntireSearchContentInitial, EntireSearchDateType } from '@/models/entire-search'
import { ReservationStatusType } from '@/models/reservation-reservation-status'
import { MAX_NUMBER_DOWNLOADS } from '@/constants/accommodation-management'
import { getSearchedConditions } from '../search-condition'

//apis
import { fetchAccommodationManagementCsv, fetchReservation } from '@/apis/aipass'
import dayjs from 'dayjs'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { reloadWrapperStyle } from '../list-layout'
import { ReloadButton } from '@/components/atoms/reload-button'
import { useSearchedConditions } from '@/hooks/use-search-condition'

export const EntireSearch = () => {
  const LIMIT = 50
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDropDownSearchPanelOpen, setIsDropDownSearchPanelOpen] = useState<boolean>(true)
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)
  const [page, setPage] = useState<string>('1')
  const [hasNext, setHasNext] = useState(false)
  const [hasList, setHaslist] = useState(0)
  const [listTotal, setListTotal] = useState<number>(0)
  const location = useLocation<any>()
  const [reservations, setReservations] = useState<any>([])

  const [entireSearchContent, setEntireSearchContent] = useState<EntireSearchContentType>(EntireSearchContentInitial)
  const [entireSearchReservationStatuses, setEntireSearchReservationStatuses] = useState<ReservationStatusType[]>([
    ReservationStatusType.NewReport,
    ReservationStatusType.ModificationReport,
  ])

  const searchedConditions = getSearchedConditions()
  const { SearchedConditionValues } = useSearchedConditions({ searchedConditions })

  const goBack = () => {
    history.push({
      pathname: '/accommodation-management',
      search: `?tabType=checkin`,
    })
  }

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.addEventListener('popstate', goBack)

    return () => window.removeEventListener('popstate', goBack)
  }, [])

  const setQueryParams = page => {
    const queryString = require('query-string')
    const queryParams = {
      ...queryString.parse(location.search),
      lang: 'ja',
      page,
      limit: LIMIT,
      mode: 'entireSearchList',
      reservationStatuses: entireSearchReservationStatuses,
      name: entireSearchContent.name,
      nameKana: entireSearchContent.nameKana,
      searchReservationId: entireSearchContent.searchReservationId,
      roomNumber: entireSearchContent.roomNumber,
      searchBeginDate: entireSearchContent.beginDate,
      searchEndDate: entireSearchContent.endDate,
      selectGuestRoomAssign: entireSearchContent.selectGuestRoomAssign,
      approvedStatuses: entireSearchContent.approvedStatuses,
      globalSearchDateType: entireSearchContent.globalSearchDateType,
      telephone: entireSearchContent.telephone,
      note: entireSearchContent.note,
    }

    history.push({ pathname: '/entire-search', search: `?${queryString.stringify(queryParams)}` })
  }

  const fetchResult = async params => {
    setIsLoading(true)
    try {
      const res = await fetchReservation({
        page: params.page,
        limit: LIMIT,
        approvedStatuses: params.approvedStatuses ? [params.approvedStatuses].flat() : [],
        name: params.name,
        nameKana: params.nameKana,
        searchReservationId: params.searchReservationId,
        roomNumber: params.roomNumber,
        beginDate: params.searchBeginDate,
        endDate: params.searchEndDate,
        selectGuestRoomAssign: params.selectGuestRoomAssign,
        reservationStatuses: params.reservationStatuses ? [params.reservationStatuses].flat() : [],
        globalSearchDateType: params.globalSearchDateType,
        telephone: params.telephone,
        note: params.note,
      })
      setReservations([])
      setReservations(res.reservations)
      setListTotal(res.total)
      setPage(params.page)
      setHasNext(res.hasNext)
      setHaslist(res.reservations.length)
      setIsDropDownSearchPanelOpen(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getFetchParams = () => {
    const params = require('query-string').parse(location.search)

    if (Object.keys(params).length) {
      const paramEntireSearchReservationStatuses = params.reservationStatuses
        ? [params.reservationStatuses].flat().map(i => parseInt(i) as ReservationStatusType)
        : []
      setEntireSearchReservationStatuses(paramEntireSearchReservationStatuses)
      setEntireSearchContent({
        name: params.name,
        nameKana: params.nameKana,
        searchReservationId: params.searchReservationId,
        roomNumber: params.roomNumber,
        beginDate: params.searchBeginDate,
        endDate: params.searchEndDate,
        globalSearchDateType: parseInt(params.globalSearchDateType) as EntireSearchDateType,
        selectGuestRoomAssign: params.selectGuestRoomAssign,
        approvedStatuses: params.approvedStatuses ? [params.approvedStatuses].flat().map(i => parseInt(i) as ApprovedStatusType) : [],
        telephone: params.telephone,
        note: params.note,
        reservationStatuses: paramEntireSearchReservationStatuses,
      })

      return params
    }
  }

  const reFetchResult = () => {
    const params = getFetchParams()
    if (params) {
      fetchResult(params)
    }
  }

  useEffect(() => {
    const params = getFetchParams()
    if (params) {
      fetchResult(params)
    }
  }, [location.search])

  const clearEntireSearchPanel = () => {
    setEntireSearchContent(EntireSearchContentInitial)
    setEntireSearchReservationStatuses([ReservationStatusType.NewReport, ReservationStatusType.ModificationReport])
  }

  // csv download of general search modal
  const downloadEntireSearchCsv = async () => {
    // Maximum number of downloads
    if (listTotal >= MAX_NUMBER_DOWNLOADS) {
      alert(t('You cannot create more than {{num}} items Please press the download button after narrowing down the number of items', { num: MAX_NUMBER_DOWNLOADS }))
      return
    }
    setIsLoading(true)
    const queryCSVParams = {
      approvedStatuses: entireSearchContent.approvedStatuses,
      name: entireSearchContent.name,
      nameKana: entireSearchContent.nameKana,
      searchReservationId: entireSearchContent.searchReservationId,
      roomNumber: entireSearchContent.roomNumber,
      searchBeginDate: entireSearchContent.beginDate,
      searchEndDate: entireSearchContent.endDate,
      selectGuestRoomAssign: entireSearchContent.selectGuestRoomAssign,
      reservationStatuses: entireSearchReservationStatuses,
      mode: 'entireSearchList',
      globalSearchDateType: entireSearchContent.globalSearchDateType,
      telephone: entireSearchContent.telephone,
      note: entireSearchContent.note,
    }

    await fetchAccommodationManagementCsv(queryCSVParams).then(() => {
      history.push({
        pathname: 'csv-download-history/list',
        search: location.search,
        state: {
          fromEntireSearch: true,
        },
      })
      setIsLoading(false)
    })
  }
  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header isBack title={t('Accommodation management')} goBack={goBack} />
        <div className="mainContainer" css={mainContainerStyle}>
          <div css={searchEntireContainerStyle}>
            <div css={searchEntireHeaderStyle}>
              <div css={leftHeaderStyle}>
                <div css={titleStyle}>{t('Full search')}</div>
                <Button
                  buttonType={4}
                  width={i18n.language === 'en' ? 118 : 82}
                  height={32}
                  fontSize={12}
                  marginLeft={24}
                  onClick={() => setIsDropDownSearchPanelOpen(!isDropDownSearchPanelOpen)}
                >
                  {t('Detailed search')}
                </Button>
                <div css={reloadWrapperStyle}>
                  <ReloadButton onClick={() => reFetchResult()} />
                </div>
                <SearchedConditionValues />
                {isDropDownSearchPanelOpen && (
                  <EntireSearchPanel
                    search={() => {
                      setQueryParams(1)
                      setIsDropDownSearchPanelOpen(!isDropDownSearchPanelOpen)
                    }}
                    clearEntireSearchPanel={clearEntireSearchPanel}
                    entireSearchContent={entireSearchContent}
                    setEntireSearchContent={setEntireSearchContent}
                    entireSearchReservationStatuses={entireSearchReservationStatuses}
                    setEntireSearchReservationStatuses={setEntireSearchReservationStatuses}
                  />
                )}
              </div>
              <div css={rightHeaderStyle}>
                <Button
                  buttonType={4}
                  width={100}
                  height={32}
                  fontSize={12}
                  paddingLeft={16}
                  paddingRight={16}
                  marginRight={8}
                  icon={require('@/static/images/csv.svg')}
                  onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                >
                  {t('CSV')}
                  <img src={require('@/static/images/arrow_gray.svg')} css={dropDownIcon} />
                </Button>
                {isDropDownOpen && (
                  <div css={dropDownStyle}>
                    <ul>
                      <li css={dropDownContentStyle} onClick={downloadEntireSearchCsv}>
                        <p css={dropDownTitleStyle}>{t('Booking export')}</p>
                      </li>
                      <li
                        css={dropDownContentStyle}
                        onClick={() =>
                          history.push({
                            pathname: 'csv-download-history/list',
                            search: location.search,
                            state: {
                              fromEntireSearch: true,
                            },
                          })
                        }
                      >
                        <p css={dropDownTitleStyle}>{t('Csv download history')}</p>
                      </li>
                    </ul>
                  </div>
                )}

                <AccommodationPaging
                  page={parseInt(page)}
                  setPage={nextPage => {
                    setQueryParams(nextPage)
                  }}
                  total={listTotal}
                  limit={LIMIT}
                />
              </div>
            </div>
          </div>
          <List reservations={reservations} />
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const searchEntireContainerStyle = css({ borderRadius: '5px', boxShadow: '0px 0px 6px #0000001a', position: 'relative' })

const searchEntireHeaderStyle = css({
  backgroundColor: '#fff',
  padding: '9px 32px 10px 32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
})

const leftHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
}

const rightHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
}

const dropDownIcon = css({
  marginLeft: 8,
})
const titleStyle = css({
  backgroundColor: '#fff',
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#272727',
})
const dropDownStyle = css({
  width: 153,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 42,
  right: 130,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})
const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 14,
  color: '#272727',
})
