import { Button } from '@/components/atoms'
import { RHFInputField } from '@/components/molecules/RHFInput-field'
import { Select, Option } from '@/components/atoms/select'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { listHeaderGrayStyle } from '@/constants/layout'
import { gendersWithTranslate } from '@/libs/genders'
import { SearchCustomerListParams } from '@/models/customer-manager/customer'
import { css } from '@emotion/core'
import { ReactComponent as RemoveIcon } from '@/static/images/customer-manager/icon_remove.svg'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CustomerTagMasterType, tagColors } from '@/models/customer-manager/customer-tag'
import { DateField } from '@/components/molecules/date-field'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { Label } from '@/components/molecules/label'
import { HotelBasicInfo } from '@/models/hotel/basic-info'

interface SearchListModalProps {
  useFormReturn: UseFormReturn<SearchCustomerListParams>
  doSearchCustomers: () => Promise<void>
  basicInfo?: HotelBasicInfo
  customerTagMasters: CustomerTagMasterType[]
}

export const SearchListModal: React.FC<SearchListModalProps> = ({ useFormReturn, doSearchCustomers, basicInfo, customerTagMasters }) => {
  const [checkinDateType, setCheckinDateType] = useState('checkin')

  const { t } = useTranslation()
  const history = useHistory()

  const { register, control, reset, watch, setValue, getValues } = useFormReturn
  const fromCheckinDate = watch('fromCheckinDate')
  const toCheckinDate = watch('toCheckinDate')

  const onClear = () => {
    reset({
      name: '',
      nameKana: '',
      nationality: null,
      gender: null,
      fromAge: null,
      toAge: null,
      birthMonth: null,
      phoneNumber: '',
      searchEmail: '',
      fromStayCount: null,
      toStayCount: null,
      searchHotelId: '',
      customerTagIds: [],
      fromTotalSales: null,
      toTotalSales: null,
      fromReviewPoint: null,
      toReviewPoint: null,
    }, { keepValues: false })

    history.replace({
      pathname: '',
      search: '',
    })
  }

  const selectedTagStyle = useMemo(() => {
    const style = Object.keys(tagColors).reduce((acc, colorName) => {
      const tagStyle = {
        background: `${tagColors[colorName].bgColor} !important`,
        color: `${tagColors[colorName].fontColor} !important`,
        svg: {
          fill: `${tagColors[colorName].fontColor} !important`,
        },
        '.custom-select .ant-select-item-option-active:not(.ant-select-item-option-disabled)': {
          color: '#bae7ff !important',
        },
      }
      acc[`.ant-select-selection__choice[title=${colorName}]`] = tagStyle
      return acc
    }, {})

    return css({
      ...style,
      '.ant-select-selection__choice': {
        fontSize: 12,
      },
      width: 813,
      height: 32,
      paddingBottom: 0,
    })
  }, [])

  return (
    <div css={modalWrapperStyle}>
      <div css={modalInputWrapperStyle}>
        <RHFInputField
          customInputStyle={inputStyle}
          name="name"
          control={control}
          label={t('Full name')}
          placeholder={t('John Doe')}
          fieldWidth="304px"
        />
        <RHFInputField name="nameKana" control={control} label={t('Furigana')} placeholder="ヤマダ タロウ" fieldWidth="304px" />
        <div>
          <Label label={t('Nationality')} />
          <select {...register('nationality')} css={[selectBoxStyle, { width: 304 }]}>
            <option value={''}>{t('All')}</option>
            <option value={'JPN'}>{t('Japanese')}</option>
            <option value={'NJP'}>{t('Non-Japanese')}</option>
          </select>
        </div>
        <div>
          <Label label={t('Gender')} />
          <select {...register('gender')} css={[selectBoxStyle, { width: '157px' }]}>
            <option value={''}>{t('All')}</option>
            {gendersWithTranslate.map(gender => (
              <option value={gender.value}>{t(gender.label)}</option>
            ))}
          </select>
        </div>

        <div>
          <Label label={t('Age years')} />
          <div css={rangeInputStyle}>
            <RHFInputField
              customInputStyle={inputStyle}
              name="fromAge"
              width="48px"
              placeholder="20"
              type="number"
              control={control}
              marginBottom={0}
            />
            <span css={wavyLineStyle}>~</span>
            <RHFInputField
              customInputStyle={inputStyle}
              name="toAge"
              width="48px"
              placeholder="30"
              type="number"
              control={control}
              marginBottom={0}
            />
          </div>
        </div>
      </div>

      <div css={modalInputWrapperStyle}>
        <div>
          <Label label={t('Birthday month')} />
          <select {...register('birthMonth')} css={[selectBoxStyle, { width: 304 }]}>
            <option value="">{t('Please select')}</option>
            {[...Array(12)].map((_, i) => (
              <option value={i + 1}>{`${i + 1}${t('Month')}`}</option>
            ))}
          </select>
        </div>

        <div>
          <Label label={t('Phone number')} />
          <RHFInputField customInputStyle={inputStyle} name="phoneNumber" control={control} placeholder="09012345678" fieldWidth="304px" />
        </div>

        <div>
          <Label label={t('Email address')} />
          <RHFInputField
            customInputStyle={inputStyle}
            name="searchEmail"
            control={control}
            placeholder="yamada@aipass.jp"
            fieldWidth="304px"
          />
        </div>
      </div>

      <div css={modalInputWrapperStyle}>
        <div>
          <Label label={t('Name of facility')} />
          <select {...register('searchHotelId')} css={[selectBoxStyle, { width: 304 }]}>
            <option value="">{t('All')}</option>
            {basicInfo && <option value={basicInfo.hotelId}>{basicInfo.hotelName}</option>}
          </select>
        </div>

        <div>
          <Label label={t('Number of nights stayed')} />
          <div css={rangeInputStyle}>
            <RHFInputField
              control={control}
              customInputStyle={inputStyle}
              name="fromStayCount"
              width="48px"
              placeholder="1"
              type="number"
              marginBottom={0}
            />
            <span css={wavyLineStyle}>~</span>
            <RHFInputField
              customInputStyle={inputStyle}
              name="toStayCount"
              width="48px"
              placeholder="10"
              type="number"
              control={control}
              marginBottom={0}
            />
          </div>
        </div>

        <div>
          <Label label={t('CustomerTag')} />
          <Controller
            control={control}
            name="customerTagIds"
            render={({ field }) => (
              <Select
                className="tag-select"
                value={field.value}
                customStyle={selectedTagStyle}
                menuItemSelectedIcon={<img src={require('@/static/images/customer-manager/icon_check.svg')} />}
                mode="multiple"
                onChange={field.onChange}
                removeIcon={<RemoveIcon />}
              >
                {customerTagMasters.map(customerTagMaster => (
                  <Option
                    key={customerTagMaster.id}
                    title={customerTagMaster.tag.colorName}
                    value={customerTagMaster.id}
                    className={customerTagMaster.isCurrentHotelTag ? '' : 'hidden'}
                  >
                    {customerTagMaster.tag.name}
                  </Option>
                ))}
              </Select>
            )}
          />
        </div>
      </div>

      <div css={modalInputWrapperStyle}>
        <div>
          <RadioGroupField
            itemCss={radioItemStyle}
            groupCss={radioGroupStyle}
            value={checkinDateType}
            items={[
              { value: 'checkin', label: t('Check-in date') },
              { value: 'lastNight', label: t('Last night of stay') },
            ]}
            onChange={e => {
              setCheckinDateType(e.target.value)
            }}
          />
          <div css={rangeInputStyle}>
            <DateField
              customCss={datePickerStyle}
              value={fromCheckinDate}
              placeholder={t('Full date')}
              onChange={value => setValue('fromCheckinDate', value?.format('YYYY-MM-DD'))}
            />
            <span css={wavyLineStyle}>~</span>
            <DateField
              customCss={datePickerStyle}
              value={toCheckinDate}
              placeholder={t('Full date')}
              onChange={value => setValue('toCheckinDate', value?.format('YYYY-MM-DD'))}
            />
          </div>
        </div>

        <div>
          <Label label={t('Total amount used')} />
          <div css={rangeInputStyle}>
            <RHFInputField
              customInputStyle={inputStyle}
              name="fromTotalSales"
              placeholder="10000"
              type="number"
              control={control}
              width="92px"
              marginBottom={0}
            />
            <span css={wavyLineStyle}>~</span>
            <RHFInputField
              customInputStyle={inputStyle}
              name="toTotalSales"
              placeholder="500000"
              type="number"
              control={control}
              width="92px"
              marginBottom={0}
            />
          </div>
        </div>

        <div>
          <Label label={t('Evaluation')} />
          <div css={rangeInputStyle}>
            <RHFInputField
              customInputStyle={inputStyle}
              name="fromReviewPoint"
              width="48px"
              placeholder="1"
              type="number"
              control={control}
              marginBottom={0}
            />
            <span css={wavyLineStyle}>~</span>
            <RHFInputField
              customInputStyle={inputStyle}
              name="toReviewPoint"
              width="48px"
              placeholder="10"
              type="number"
              control={control}
              marginBottom={0}
            />
          </div>
        </div>
      </div>
      <div css={searchButtonStyle}>
        <Button buttonType={3} width={92} height={32} fontSize={12} marginRight={16} onClick={onClear}>
          {t('Clear')}
        </Button>
        <Button buttonType={1} width={92} height={32} fontSize={12} onClick={doSearchCustomers}>
          {t('Search')}
        </Button>
      </div>
    </div>
  )
}

const modalWrapperStyle = css(listHeaderGrayStyle, {
  width: 'calc(100% - 48px)',
  height: 'auto',
  background: '#fff',
  position: 'absolute',
  top: 90,
  border: '1px solid #F2F2F2',
  boxShadow: '0px 3px 4px #0000001A',
  zIndex: 100,
  display: 'flex',
  flexDirection: 'column',
})

const modalInputWrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
  gap: 24,
  padding: 24,
  border: '1px solid #F2F2F2',
})

const searchButtonStyle = css({
  display: 'flex',
  padding: '16px 32px',
})

const inputStyle = css({
  padding: '8px 15px',
})

const selectBoxStyle = css({
  height: 32,
  fontSize: 14,
  borderRadius: 19,
  padding: '0 24px 0 16px',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '90% center',
  backgroundSize: 10,
  borderColor: '#CCCCCC',
})

const rangeInputStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 32,
})

const wavyLineStyle = css({
  paddingInline: 7,
})

const radioGroupStyle = css({
  marginTop: -2,
  marginBottom: 8,
})

const radioItemStyle = css({
  paddingLeft: 12,
  fontSize: 12,
  label: {
    color: '#676767',
  },
  img: {
    width: 18,
    height: 18,
    marginRight: 12,
  },
})

const datePickerStyle = css({
  'div.SingleDatePicker .SingleDatePickerInput div.SingleDatePicker_picker div.DayPicker': {
    top: '0 !important',
    transform: 'translateY(-50%)',
    left: '180px !important',
  },
})
