import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

// contexts
import { AccountContext } from '@/contexts/account'

// libs
import { PluginCode, checkActivePlugin, setHasRepeatPlugin, setHasSalesManagerPlugin } from '@/libs/plugins'

// constants
import {
  wholeContainerStyle,
  mainColumnStyle,
  mainContainerStyle,
  rightContainerStyle,
  checkinDetailContainerStyle,
  leftContainerStyle,
} from '@/constants/layout'

// apis
import * as api from '@/apis/aipass'

// components
import { Button } from '@/components/atoms/button'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { LoadingFull } from '@/components/molecules/loading-full'
import { Sales } from '@/components/organisms/reservation/sales'
import { ReservationUserEditModal } from '@/components/pages/accommodation-management/reservation/reservation-user-edit/reservation-user-edit-modal'
import { ReservationUserEditForm } from '@/components/pages/accommodation-management/reservation/reservation-user-edit/reservation-user-edit-form'
import { SuggestCustomer } from '@/components/organisms/accommodation-management/suggest-customer'
import { CustomerLink } from '@/components/organisms/accommodation-management/customer-link'
import { SearchCustomerLinkButton } from '@/components/organisms/accommodation-management/search-customer-link-button'
import { SearchCustomerLinkModal } from '@/components/organisms/search-customer-link-modal'
import { DetailInfo } from '@/components/organisms/reservation/detail-info'
import { DetailEditInfo } from '@/components/organisms/reservation/detail-edit-info'
import { GuestRoom } from '@/components/organisms/customer/guest-room'
import { ReservationList } from '@/components/pages/accommodation-management/reservation/reservation-list/reservation-list'
import { ReservationDetailMemo } from '@/components/organisms/reservation/reservation-detail-memo'
import { useErrorHandler } from '@/hooks/use-error-handler'

// models
import { CustomerType } from '@/models/customer-manager/customer'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { EntireSearchContentType } from '@/models/entire-search'
import { Checkbox } from '@/components/atoms/checkbox'
import { useSameTabsReload } from '@/hooks/use-same-tabs-reload'
import { ListSpaceForReservation } from '@/components/organisms/customer/list-space-for-reservation'
import { ReceiptPopup } from '@/components/organisms/reservation/receipt-popup'
import { SplitReceiptPopup } from '@/components/organisms/reservation/receipt-split-popup'
import { CustomerTagType } from '@/models/customer-manager/customer-tag'

export const ReservationDetail: React.FC = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { account } = useContext<any>(AccountContext)
  const location = useLocation<any>()
  const history = useHistory()
  const params = require('query-string').parse(location.search)
  const { state } = useLocation<{
    entireSearchData: EntireSearchContentType
    fromAccommodationManagement: boolean
  }>()

  const [reservation, setReservation] = useState<any>()
  const [isReservationUserEditModalOpen, setIsReservationUserEditModalOpen] = useState<boolean>(false)
  const [isReservationUserEditModalLoading, setIsReservationUserEditModalLoading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reservationId, setReservationId] = useState<string>('')
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isEditReservationStatus, setIsEditReservationStatus] = useState<boolean>(false)
  const [isEditGuestRoom, setIsEditGuestRoom] = useState<boolean>(false)
  const [reservationUrl, setReservationUrl] = useState<any>()
  const { plugins } = useContext<any>(AccountContext)
  const hasSalesManagerPlugin = setHasSalesManagerPlugin(plugins)
  const [hasConfirmedSales, setHasConfirmedSales] = useState<boolean>(false)
  const [hasConfirmedDiscount, setHasConfirmedDiscount] = useState<boolean>(false)
  const [marketSegmentChange, setMarketSegmentChange] = useState<boolean>(false)
  const [setCustomer] = useState<any>()
  const [isSendingReservationCheckinLink, setIsSendingReservationCheckinLink] = useState<boolean>(false)
  const [reflectCustomerDataToUser, setReflectCustomerDataToUser] = useState<boolean>(false)
  const [reflectCustomerDataToGuest, setReflectCustomerDataToGuest] = useState<boolean>(false)
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState<boolean>(false)
  const [isSplitReceiptModalOpen, setIsSplitReceiptModalOpen] = useState<boolean>(false)
  const [usageDetails, setUsageDetails] = useState<any[]>()
  const [discounts, setDiscounts] = useState<any[]>()

  const [currentTab, setCurrentTab] = useState<'reservationInfo'>('reservationInfo')

  // Customer management related (no repeat management plugin/plugin)
  // Display of customer search modal (before linking)
  const [isOpenSearchCustomerLinkModal, setIsOpenSearchCustomerLinkModal] = useState<boolean>(false)
  // Linked customer (after linking)
  const [customerlink, setCustomerLink] = useState<CustomerType>()

  // Customer management related (with repeat management plug-in / plug-in)
  // Selected customer ID (before pegging)
  const [hotelCuicinUserId, setHotelCuicinUserId] = useState<string>('')
  // Assisted customer list (before pegging)
  const [customers, setCustomers] = useState<CustomerType[]>([])
  // Editing tags (after binding)
  const [isEditTag, setIsEditTag] = useState<boolean>(false)
  // Tag value (after binding)
  const [tags, setTags] = useState<string[]>([])

  // Whether it is a repeat management plugin
  const hasRepeatPlugin = setHasRepeatPlugin(plugins)
  const hasSpaceManagementPlugin = checkActivePlugin(plugins, PluginCode.SpaceManagement)

  const { reloadSameTabs } = useSameTabsReload({ key: reservationId })

  const _fetchAdminCheckin = async () => {
    const { id } = params
    if (id === 'redirect') return
    setIsLoading(true)
    await api
      .fetchAdminCheckin(id)
      .then(res => {
        setCustomer(res?.checkin)
        setCustomerLink(res?.customer)
        setTags(res?.customer?.tagInfo.tags.map(tag => tag.id))
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (account && account?.hotel && params) {
      setIsLoading(true)
      fetchReservation()
      // URL branching
      // Reservation List→Reservation Details→Return to Reservation List
      if (location.search.includes('tabType=reservation')) {
        setReservationUrl(location.search)
        // setIsLoading(false)
      } else {
        setReservationUrl(`?tabType=reservation`)
        // setIsLoading(false)
      }
    }
  }, [account])

  // When returning to the list, pass a flag so that the date narrowing is not removed
  const goBack = () => {
    if (state?.fromAccommodationManagement) {
      history.push({
        pathname: '/accommodation-management',
        search: reservationUrl,
        state: { ...state, state: location.state, isGoBack: true },
      })
    } else {
      history.goBack()
    }
  }

  const _updateAdminReservationRead = async () => {
    try {
      await api.updateAdminReservationRead(reservation.reservationId)
    } catch (error) {
      errorHandler(error)
    }
  }

  // window.addEventListener('popstate', goBack)

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.addEventListener('popstate', goBack)

    return () => window.removeEventListener('popstate', goBack)
  }, [reservationUrl, state])

  useEffect(() => {
    fetchReservation()
  }, [])

  useEffect(() => {
    if (reservation) {
      _updateAdminReservationRead()

      if (reservation?.guestTel === '0' && reservation?.userTel === '0') {
        return
      } else {
        _fetchCustomerSearch()
      }
    }
  }, [reservation])

  useEffect(() => {
    if (reservation) fetchReservation()
  }, [isEdit, isEditGuestRoom])

  useEffect(() => {
    if (reservation && isEditReservationStatus) {
      fetchReservation()
    }
  }, [isEditReservationStatus])

  useEffect(() => {
    if (reservation && marketSegmentChange) {
      fetchReservation()
    }
  }, [marketSegmentChange])
  // Get reservation details
  const fetchReservation = async () => {
    try {
      setIsLoading(true)
      // @ts-ignore
      const { reservationId } = params
      setReservationId(reservationId)
      await api.fetchReservationDetail({ reservationId }).then(res => {
        if (!res) return console.warn('fetchReservation return empry response')

        const reservation = res.reservations

        if (reservation.approvedStatus === 1) {
          history.push(`/checkin/${reservation.checkinId}`)
        } else if (reservation.approvedStatus === 2) {
          history.push(`/stay/${reservation.checkinId}`)
        } else if (reservation.approvedStatus === 3) {
          history.push(`/checkout/${reservation.checkinId}`)
        }

        setReservation(reservation)
      })

      // Related customer acquisition
      await api.fetchReservationCustomer(reservationId).then(res => {
        setCustomerLink(res)
        setTags(res.tagInfo?.tags.map(tag => tag.id))
      })
    } catch (error) {
      console.log(t('Communication failed'))
    } finally {
      setIsLoading(false)
    }
  }

  // Acquisition of customer list (customer management/repeat management plug-in available/telephone number assist)
  const _fetchCustomerSearch = async () => {
    const guestTel = reservation?.guestTel === '0' ? '' : reservation?.guestTel
    const userTel = reservation?.userTel === '0' ? '' : reservation?.userTel
    await api
      .fetchCustomerSearch('', '', guestTel, userTel, 1, 100)
      .then(res => {
        setCustomers(res?.hotelCuicinUsers)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  // Customer linking (customer management/repeat management plug-in available/phone number assist)
  const onSaveCustomerLinkBySuggestion = async (hotelCuicinUserId: string) => {
    setIsLoading(true)
    await api
      .createCustomerLink('', reservation.reservationId, hotelCuicinUserId)
      .then(() => {
        fetchReservation()
        setIsOpenSearchCustomerLinkModal(false)
        reloadSameTabs()
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Customer linking (customer management/modal search)
  const onSaveCustomerLink = async () => {
    setIsLoading(true)
    await api
      .createCustomerLink('', reservation.reservationId, hotelCuicinUserId)
      .then(() => {
        fetchReservation()
        setHotelCuicinUserId('')
        setIsOpenSearchCustomerLinkModal(false)
        reloadSameTabs()
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Customer unlinking (customer management)
  const deleteCustomerLink = async () => {
    setIsLoading(true)
    await api
      .deleteCustomerLink('', reservation.reservationId)
      .then(() => {
        setCustomerLink(undefined)
        reloadSameTabs()
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleClickReservationUserEdittButton = () => {
    setIsReservationUserEditModalOpen(true)
  }
  const handleClickReservationUserEditSaveButton = async (form: ReservationUserEditForm) => {
    setIsReservationUserEditModalLoading(true)
    try {
      await api.putAdminReservationUser(reservationId, {
        reservationUser: form.getReservationUser(),
        reservationGuest: form.getReservationGuest(),
      })
      const reservation = await api.fetchReservationDetail({ reservationId })

      // Related customer acquisition
      await api.fetchReservationCustomer(reservationId).then(res => {
        setCustomerLink(res)
        setTags(res.tagInfo?.tags.map(tag => tag.id))
      })

      setReservation(reservation?.reservations)
      setIsReservationUserEditModalOpen(false)
      reloadSameTabs()
    } finally {
      setIsReservationUserEditModalLoading(false)
    }
  }
  const handleClickReservationUserEditCancelButton = () => {
    setIsReservationUserEditModalOpen(false)
  }

  const _convertEmptyToNull = value => {
    return value ? value : null
  }

  const _convertNullToEmpty = value => {
    return value ? value : ''
  }

  const reservationCheckinLink = async reservation => {
    if (isSendingReservationCheckinLink) {
      return
    } else {
      setIsSendingReservationCheckinLink(true)
    }

    // warning
    if (
      (hasConfirmedSales || hasConfirmedDiscount) &&
      !window.confirm(t('Includes items with confirmed dates Please pay attention to sales management'))
    )
      return

    setIsLoading(true)

    const userCheckinTime = reservation.checkinDate
      ? dayjs(`${reservation.checkinDate} ${reservation.checkinTime || '00:00'}`).unix()
      : null
    const checkoutTime = reservation.checkoutDate
      ? dayjs(`${reservation.checkoutDate} ${reservation.checkoutTime || '00:00'}`).unix()
      : null

    const checkinVariables = {
      hotelId: reservation.hotelId,
      approvedStatus: ApprovedStatusType.Checkin,
      userCheckinTime,
      checkoutTime,
      guest: {
        // Show booker info if guest name is empty
        name: _convertNullToEmpty(reservation.guestName) || _convertNullToEmpty(reservation.name),
        nameKana: _convertNullToEmpty(reservation.guestNameKana) || _convertNullToEmpty(reservation.nameKana),
        birthDate: null,
        nationality: null,
        gender: null,
        postalCode: null,
        address: reservation.guestName ? reservation.guestAddr : reservation.userAddr,
        telephone: reservation.guestName ? reservation.guestTel || '0' : reservation.tel || '0',
        email: reservation.guestName ? _convertEmptyToNull(reservation.guestMailAddr) : _convertEmptyToNull(reservation.mailAddr),
        occupation: null,
        otherOccupation: null,
        totalMember: reservation.paxTotal,
        picture: null,
        credential: null,
        company: null,
      },
      accompany: [],
      previousPlaceToStay: '自宅',
      nextPlaceToStay: '自宅',
      reservationId: reservation.reservationId,
    }

    /*
      Create a unique ID for check-in information
      ※ Overview
      When processing with a payment plug-in, data registration of usage details is required, but a check-in ID is required at that time.
      Create on the front, not on the backend
      TODO: I want to change this with a design change
    */
    const uuid = require('node-uuid')
    const checkinUuid = uuid.v4()
    // Create check-in information
    await api
      .updateSelfCheckin(checkinUuid, checkinVariables, reservationId)
      .then(res => {
        // Link check-in and booking
        if (!res) return console.warn('updateSelfCheckin returned empty result')
        api
          .insertAdminReservationCheckin(res.checkinId, reservation.reservationId)
          .then(() => {
            reloadSameTabs()
            setIsLoading(false)
            const reservationIds = reservation.reservationId.split(',')
            // Give Advance Receipt Flag
            api.updateSalesAdvancePayment(reservationIds)
            history.push({ pathname: `/checkin/${res.checkinId}` })
          })
          .finally(() => {
            setIsSendingReservationCheckinLink(false)
            setIsLoading(false)
          })
      })
      .catch(error => {
        setIsLoading(false)
        errorHandler(error)
      })
  }

  const resetCopyCustomerData = () => {
    setReflectCustomerDataToUser(false)
    setReflectCustomerDataToGuest(false)
  }

  const copyCustomerData = async () => {
    try {
      setIsLoading(true)

      await api.userGuestCopy({
        isReservationUserCopy: reflectCustomerDataToUser,
        isReservationGuestCopy: reflectCustomerDataToGuest,
        hotelCuicinUserId: customerlink?.id,
        reservationId: reservationId,
      })
      await fetchReservation()

      resetCopyCustomerData()
      reloadSameTabs()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onClickCancelTag = () => {
    setIsEditTag(false)
    setTags(customerlink?.tagInfo?.tags?.map(tagInfo => tagInfo.id) || [])
  }

  const onCloseReceiptModal = () => {
    setIsReceiptModalOpen(false)
  }

  const onCloseSplitReceiptModal = () => {
    setIsSplitReceiptModalOpen(false)
  }

  useEffect(() => {
    if (params) {
      if (location.search.includes('tabType=reservation')) {
        setReservationUrl(location.search)
        return
      }
      if (location.pathname.includes('reservation') && !location.search.includes('tabType=')) {
        setReservationUrl(location.search + '&tabType=reservation')
        return
      }
    }
  }, [params])

  const { reservationId: currentTabReservationId } = params

  const showEditHeader = reflectCustomerDataToUser || reflectCustomerDataToGuest

  const getUserNameKana = () => {
    if (reflectCustomerDataToUser) return customerlink?.nameKana || customerlink?.name_kana || '-'

    return reservation && reservation.userNameKana ? reservation.userNameKana : '-'
  }

  const getUserName = () => {
    if (reflectCustomerDataToUser) return customerlink?.name || '-'

    return reservation && reservation.userName ? reservation.userName : '-'
  }

  const getUserTel = () => {
    if (reflectCustomerDataToUser) return customerlink?.telephone || '-'

    return reservation && reservation.userTel ? reservation.userTel : '-'
  }

  const getUserEmail = () => {
    if (reflectCustomerDataToUser) return customerlink?.email || '-'

    return reservation && reservation.userMailAddr ? reservation.userMailAddr : '-'
  }

  const getUserAddr = () => {
    if (reflectCustomerDataToUser) return customerlink?.address || '-'

    return reservation && reservation.userAddr ? reservation.userAddr : '-'
  }

  const getGuestNameKana = () => {
    if (reflectCustomerDataToGuest) return customerlink?.nameKana || customerlink?.name_kana || '-'

    return reservation && reservation.guestNameKana ? reservation.guestNameKana : '-'
  }

  const getGuestName = () => {
    if (reflectCustomerDataToGuest) return customerlink?.name || '-'

    return reservation && reservation.guestName ? reservation.guestName : '-'
  }

  const getGuestTel = () => {
    if (reflectCustomerDataToGuest) return customerlink?.telephone || '-'

    return reservation && reservation.guestTel ? reservation.guestTel : '-'
  }

  const getGuestEmail = () => {
    if (reflectCustomerDataToGuest) return customerlink?.email || '-'

    return reservation && reservation.guestMailAddr ? reservation.guestMailAddr : '-'
  }

  const getGuestAddr = () => {
    if (reflectCustomerDataToGuest) return customerlink?.address || '-'

    return reservation && reservation.guestAddr ? reservation.guestAddr : '-'
  }

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header isBack title={t('Accommodation management')} goBack={goBack} />
        <div className="mainContainer" css={mainContainerStyle}>
          <div css={checkinDetailContainerStyle}>
            <div css={leftContainerStyle}>
              <div
                css={{
                  padding: '0 32px',
                  borderBottom: '1px solid #f5f5f5',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: 48,
                }}
              >
                <div css={{ fontSize: 16, fontWeight: 'bold', color: '#272727' }}>{t('Guest information')}</div>
                <div css={{ display: 'flex' }}>
                  {showEditHeader ? (
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                      <Button buttonType={3} width={100} height={30} fontSize={12} marginRight={16} onClick={resetCopyCustomerData}>
                        {t('Cancel')}
                      </Button>
                      <Button buttonType={1} width={100} height={30} fontSize={12} onClick={copyCustomerData}>
                        {t('Save')}
                      </Button>
                    </div>
                  ) : (
                    <div css={[editableIcon, { marginLeft: 16 }]} onClick={handleClickReservationUserEdittButton}></div>
                  )}
                </div>
              </div>
              <div css={{ height: '100%' }}>
                <div css={[leftTopSectionStyle, { height: '100%' }]}>
                  <div css={[detailCustomerContainerStyle, { borderRight: '1px solid #F2F2F2' }]}>
                    <div css={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <div css={reservationDetailLeftTitleStyle}>{t('Reservation name')}</div>
                      <Checkbox
                        value={reflectCustomerDataToUser}
                        onChange={setReflectCustomerDataToUser}
                        label={t('Reflect customer data')}
                        disabled={!customerlink}
                      />
                    </div>
                    <div css={{ display: 'flex' }}>
                      <div css={reservationDetailLeftSectionStyle}>
                        <div css={reservationDetailLeftNameKanaStyle}>{getUserNameKana()}</div>
                        <div css={reservationDetailLeftNameStyle}>{getUserName()}</div>
                      </div>
                      <ul css={guestItemContainerStyle}>
                        <li css={guestItemListContainerStyle}>
                          <img alt="tell" src={require('@/static/images/tell.svg')} />
                          <div css={guestItemStyle}>{getUserTel()}</div>
                        </li>
                        <li css={guestItemListContainerStyle}>
                          <img alt="mail" src={require('@/static/images/mail.svg')} />
                          <div css={guestItemStyle}>{getUserEmail()}</div>
                        </li>
                        <li css={guestItemListLastContainerStyle}>
                          <img alt="access" src={require('@/static/images/access.svg')} />
                          <div css={guestItemStyle}>{getUserAddr()}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div css={[detailCustomerContainerStyle]}>
                    <div css={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <div css={reservationDetailLeftTitleStyle}>{t('Guest name')}</div>
                      <Checkbox
                        value={reflectCustomerDataToGuest}
                        onChange={setReflectCustomerDataToGuest}
                        label={t('Reflect customer data')}
                        disabled={!customerlink}
                      />
                    </div>
                    <div css={{ display: 'flex' }}>
                      <div css={reservationDetailLeftSectionStyle}>
                        <div css={reservationDetailLeftguestNameKanaStyle}>{getGuestNameKana()}</div>
                        <div css={reservationDetailLeftguestNameStyle}>{getGuestName()}</div>
                      </div>
                      {/* {isListOpen && ( */}
                      <ul css={guestItemContainerStyle}>
                        <li css={guestItemListContainerStyle}>
                          <img alt="tell" src={require('@/static/images/tell.svg')} />
                          <div css={guestItemStyle}>{getGuestTel()}</div>
                        </li>
                        <li css={guestItemListContainerStyle}>
                          <img alt="mail" src={require('@/static/images/mail.svg')} />
                          <div css={guestItemStyle}>{getGuestEmail()}</div>
                        </li>
                        <li css={guestItemListLastContainerStyle}>
                          <img alt="access" src={require('@/static/images/access.svg')} />
                          <div css={guestItemStyle}>{getGuestAddr()}</div>
                        </li>
                      </ul>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Related customers */}
            <div
              css={[
                rightContainerStyle,
                {
                  height: 'auto',
                  minHeight: 'auto',
                  boxShadow: 'none',
                  borderRadius: 0,
                  flex: '1 1 auto',
                  display: 'flex',
                  flexDirection: 'column',
                },
              ]}
            >
              {/* No repeat management plugin */}
              {!hasRepeatPlugin &&
                (customerlink && !isLoading ? (
                  <CustomerLink
                    history={history}
                    customer={customerlink}
                    currentTags={tags}
                    deleteCustomerLink={deleteCustomerLink}
                    isEdit={isEditTag}
                    onCancel={onClickCancelTag}
                  />
                ) : (
                  <SearchCustomerLinkButton setIsOpenModal={setIsOpenSearchCustomerLinkModal} />
                ))}
              {/* Repeat management plugin available */}
              {hasRepeatPlugin &&
                (customerlink ? (
                  <CustomerLink
                    history={history}
                    customer={customerlink}
                    currentTags={tags}
                    deleteCustomerLink={deleteCustomerLink}
                    isEdit={isEditTag}
                    onCancel={onClickCancelTag}
                  />
                ) : (
                  <SuggestCustomer
                    customers={customers}
                    setIsOpenModal={setIsOpenSearchCustomerLinkModal}
                    onSaveCustomerLink={onSaveCustomerLinkBySuggestion}
                  />
                ))}
            </div>
          </div>
          {isOpenSearchCustomerLinkModal && (
            <SearchCustomerLinkModal
              hotelCuicinUserId={hotelCuicinUserId}
              setHotelCuicinUserId={setHotelCuicinUserId}
              setIsOpenSearchCustomerLinkModal={setIsOpenSearchCustomerLinkModal}
              onSaveCustomerLink={onSaveCustomerLink}
            />
          )}
          <div css={roomInfoContainerStyle}>
            <div css={reservationListWrapper}>
              <div css={reservationListTitleWrapper}>{t('Accommodation management')}</div>
              {/* Reservation list */}
              <div css={reservationListDetailWrapper}>
                <ReservationList reservation={reservation} reservationCheckinLink={reservationCheckinLink} />
              </div>
            </div>
            {currentTab === 'reservationInfo' && (
              <>
                <div css={reservationInfoContainerStyle}>
                  <div css={tabContainerStyle}>
                    <div css={tabReservationActiveStyle} onClick={() => setCurrentTab('reservationInfo')}>
                      {reservation && reservation.accommodationId ? reservation.accommodationId : '-'}
                    </div>
                  </div>
                  <div css={approvedStatusCheckinWrapperStyle}>
                    <div css={approvedStatusReservationStyle}>{t('Reserve')}</div>
                    <div css={approvedStatusDateStyle}>
                      {reservation && reservation.approvedStatusUpdatedAt
                        ? `${t('Status update')}: ` +
                          dayjs(reservation && reservation.approvedStatusUpdatedAt).format(t('YYYY-MM-DD HH:mm'))
                        : `${t('Status update')}: ` + '-'}
                    </div>
                  </div>
                  <div css={reservationInfoTopContainerStyle}>
                    {/* Booking details */}
                    <div css={reservationPlanContainerStyle}>
                      <DetailInfo
                        reservation={reservation}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        setIsLoading={setIsLoading}
                        setMarketSegmentChange={setMarketSegmentChange}
                        setIsEditReservationStatus={setIsEditReservationStatus}
                        onChangeInfo={() => {
                          fetchReservation()
                          reloadSameTabs()
                        }}
                        onChangeMarketSegment={reloadSameTabs}
                      />
                      {isEdit && <DetailEditInfo reservation={reservation} isEdit={isEdit} setIsEdit={setIsEdit} />}
                    </div>
                    {/* Reservation details memo */}
                    <div css={reservationMemoContainerStyle}>
                      <ReservationDetailMemo
                        reservationId={reservation?.reservationId}
                        checkoutDate={reservation?.checkoutDate}
                        onSavedData={reloadSameTabs}
                        customer={customerlink}
                      />
                    </div>
                  </div>
                  <div css={reservationInfoBottomContainerStyle}>
                    {/* guest room */}
                    <div css={roomGuestContainerStyle}>
                      <GuestRoom
                        reservation={reservation}
                        setIsLoading={setIsLoading}
                        isEditGuestRoom={isEditGuestRoom}
                        setIsEditGuestRoom={setIsEditGuestRoom}
                        onSavedData={reloadSameTabs}
                      />
                    </div>
                  </div>
                  {hasSpaceManagementPlugin && (
                    <ListSpaceForReservation
                      reservationId={currentTabReservationId}
                      customStyle={css({ marginTop: 16, marginBottom: 0 })}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {isReservationUserEditModalOpen && (
            <ReservationUserEditModal
              formValue={{
                userName: reservation?.userName,
                userNameKana: reservation?.userNameKana,
                userTel: reservation?.userTel,
                userMailAddr: reservation?.userMailAddr,
                userAddr: reservation?.userAddr,
                guestName: reservation?.guestName,
                guestNameKana: reservation?.guestNameKana,
                guestTel: reservation?.guestTel,
                guestMailAddr: reservation?.guestMailAddr,
                guestAddr: reservation?.guestAddr,
              }}
              isLoading={isReservationUserEditModalLoading}
              onClickSave={handleClickReservationUserEditSaveButton}
              onClickCancel={handleClickReservationUserEditCancelButton}
            />
          )}
          <div>
            {/* Check-in Details_Usage Details */}
            {hasSalesManagerPlugin && currentTabReservationId && (
              <Sales
                isEdit={isEdit}
                isEditReservationStatus={isEditReservationStatus}
                setHasConfirmedSales={setHasConfirmedSales}
                setHasConfirmedDiscount={setHasConfirmedDiscount}
                currentTabReservationId={currentTabReservationId}
                reservationId={reservationId}
                customerId={reservationId}
                customerLink={customerlink}
                customerName={reservation?.guest?.name}
                setIsReceiptModalOpen={setIsReceiptModalOpen}
                setIsSplitReceiptModalOpen={setIsSplitReceiptModalOpen}
                onChangeDiscounts={v => setDiscounts(v)}
                onChangeUsageDetails={v => setUsageDetails(v)}
                reservation={reservation}
                onUpdateSales={() => {
                  reloadSameTabs()
                  fetchReservation()
                }}
              />
            )}
            {isReceiptModalOpen && (
              <ReceiptPopup
                isReceiptModalOpen={isReceiptModalOpen}
                onClose={onCloseReceiptModal}
                customer={{ guest: { name: reservation?.guestName } }}
                reservationId={reservationId}
              />
            )}
            {isSplitReceiptModalOpen && (
              <SplitReceiptPopup
                usageDetails={usageDetails}
                discounts={discounts}
                isSplitReceiptModalOpen={isSplitReceiptModalOpen}
                onClose={onCloseSplitReceiptModal}
                reservationId={reservationId}
                customer={{ guest: { name: reservation?.guestName } }}
              />
            )}
          </div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

// Reservation/guest information
export const leftTopSectionStyle = css({
  display: 'flex',
  alignItems: 'stretch',

  //   overflow: 'hidden',
  //   backgroundColor: '#FFF',
  //   boxShadow: '0px 0px 6px #0000001A',
  //   borderRadius: 5,
  //   width: '100%',
  //   padding: '16px 16px 29px 32px',
})

const detailCustomerContainerStyle = css({
  padding: '24px 32px',
  minWidth: 390,
  width: '50%',
  position: 'relative',
})

// const reservationDetailLeftSectionsStyle = css({
//   borderRight: '1px solid #F2F2F2',
//   display: 'flex',
//   minWidth: 390,
//   width: '50%',
// })

const reservationDetailLeftSectionStyle = css({
  minWidth: 96,
  marginTop: 9,
})

// const reservationDetailRightSectionsStyle = css({
//   display: 'flex',
//   minWidth: 390,
//   width: '50%',
//   position: 'relative',
// })

const reservationDetailLeftTitleStyle = css({
  color: '#F2A40B',
  letterSpacing: 0.7,
  fontSize: 14,
  fontWeight: 'bold',
})

const reservationDetailLeftNameKanaStyle = css({
  color: '#676767',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: 0.9,
  marginBottom: 8,
  span: {
    marginLeft: 8,
  },
})

const reservationDetailLeftNameStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: 0.9,
  span: {
    marginLeft: 8,
  },
})

const guestItemContainerStyle = css({
  margin: '18px 0 0 30px',
})

const reservationDetailLeftguestNameKanaStyle = css(reservationDetailLeftNameKanaStyle, {})

const reservationDetailLeftguestNameStyle = css(reservationDetailLeftNameStyle, {
  marginBottom: 0,
})

const guestItemListContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 12,
  lineHeight: 1.4,
  img: {
    width: 18,
    marginRight: 18,
  },
})

const guestItemListLastContainerStyle = css(guestItemListContainerStyle, {
  marginBottom: 0,
})

const guestItemStyle = css({
  fontSize: 14,
  letterSpacing: 0.7,
  color: '#272727',
  lineHeight: 1.4,
})

// edit button
const editableIcon = css({
  textAlign: 'right',
  display: 'block',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: '#FFF',
  borderRadius: '50px',
  width: '30px',
  height: '30px',
  float: 'right',
  backgroundImage: `url(${require('@/static/images/edit.svg')})`,
  border: 'solid 1px #cccccc',
})

// 予約一覧
const roomInfoContainerStyle = css({
  margin: '24px 0',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRadius: 5,
  boxShadow: '0px 0px 6px #0000001a',
})

const reservationListWrapper = css({
  backgroundColor: '#fff',
  borderRadius: '5px 5px 0 0',
})

const reservationListTitleWrapper = css({
  backgroundColor: '#fff',
  fontSize: 16,
  fontWeight: 'bold',
  marginTop: 17,
  paddingLeft: 32,
  paddingBottom: '17px',
  borderBottom: '1px solid #F2F2F2',
  color: '#272727',
})

const reservationListDetailWrapper = css({
  backgroundColor: '#fff',
  padding: '26.5px 32px 32px',
})

// Booking details
const reservationInfoContainerStyle = css({
  width: '100%',
  minWidth: 695,
  minHeight: 679,
  backgroundColor: '#FFF',
  paddingBottom: 31,
  borderRadius: 5,
})

const approvedStatusCheckinWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const approvedStatusReservationStyle = css({
  width: 116,
  fontSize: 12,
  fontWeight: 'bold',
  color: '#fff',
  borderRadius: 5,
  textAlign: 'center',
  padding: '6px 18px',
  backgroundColor: '#F2A40B',
  margin: '24px 32px',
})

const approvedStatusDateStyle = css({
  fontSize: 12,
  color: '#272727',
  margin: '34px 32px 25px',
})

const reservationInfoTopContainerStyle = css({
  display: 'flex',
  margin: '0 32px',
})

const reservationInfoBottomContainerStyle = css({
  width: '100%',
})

export const reservationPlanContainerStyle = css({
  width: '70%',
  display: 'flex',
  overflow: 'hidden',
  backgroundColor: '#FFF',
  borderRadius: 10,
  border: '1px solid #CCCCCC',
  height: 'fit-content',
})

// Reservation details memo
export const reservationMemoContainerStyle = css({
  marginLeft: 16,
  width: '30%',
})

// guest room
export const roomGuestContainerStyle = css({
  padding: '17px 34px 0 32px',
})

// tab
const centerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const tabContainerStyle = css({
  height: 45,
  display: 'flex',
  backgroundColor: '#F5F5F5',
})

const tabReservationActiveStyle = css(centerStyle, {
  width: 118,
  height: 41,
  background: '#fff',
  position: 'relative',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#F2A40B',
  borderTop: '3px solid #F2A40B',
  '&::after': {
    position: 'absolute',
    bottom: -4,
    left: 0,
    height: 6,
    width: '100%',
    content: '""',
    background: '#fff',
    zIndex: 3,
  },
})
